import _ from 'lodash';
import React, { ReactText } from 'react';
import { OrderedMap } from 'immutable';
import { WppListItem, WppProgressIndicator, WppTypography, InfiniteScroll } from 'buildingBlocks';

import { OPTIONS_STYLES } from './styles';

export type ItemDisplayProps = {
  text: string
  onClick: (item: { [key: string]: any }) => void
  item: any
  disabled?: boolean
  checked?: boolean
};

const { container, listItems, header, progressContainer, listItem, noOptionsFound } = OPTIONS_STYLES;

export const BasicHeader: React.FC<{ label: string }> = ({ label }: { label: string }): React.ReactElement => (
  <WppTypography tag="p" type="s-strong">
    {label}
  </WppTypography>
);

export const ItemDisplay = ({ text, checked, onClick, item, disabled }: ItemDisplayProps) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <WppListItem
    multiple
    onWppChangeListItem={() => onClick(item)}
    disabled={disabled}
    checked={checked}
    style={listItem}
  >
    <span slot="label">{text}</span>
  </WppListItem>
);

type OptionsListProps = {
  items: Array<any>
  keyFn: (x: unknown) => ReactText
  displayFn: (x: unknown) => string
  selected: OrderedMap<unknown, unknown>
  pluralize: (n: number) => string
  onSelected: (value: any) => void
  fetchData : () => void
  isSelected?: boolean
  renderTopHeader?: () => JSX.Element
  customHeader?: JSX.Element
  defaultValue?: Array<any>
  disabled?: boolean
};

const WppOptionsList = ({
  items,
  onSelected,
  selected,
  keyFn,
  displayFn,
  pluralize,
  isSelected,
  renderTopHeader,
  customHeader,
  defaultValue,
  disabled,
  fetchData,
}: OptionsListProps) => {
  const label = isSelected ? `${items.length} ${pluralize(items.length)} Selected` : `Select ${pluralize(2)}`;

  // Check if the the options list needs to be disabled
  const disabledItemInDefaultValues = (item: any) => (disabled && defaultValue && _.includes(_.map(defaultValue, 'id'), item.id));
  const onClick = (item: any) => {
    if (!disabled || (defaultValue && !disabledItemInDefaultValues(item))) {
      onSelected(item);
    }
  };

  return (
    <div style={container}>
      {customHeader || <BasicHeader label={label} />}
      <div style={header}>{renderTopHeader && renderTopHeader()}</div>
      <div style={{ ...listItems, overflow: disabled ? 'hidden' : 'auto' }} id="scrollableOptionsList">
        <InfiniteScroll
          dataLength={items.length}
          next={fetchData}
          hasMore={!_.isEqual(items.length, 0)}
          loader={(
            <WppProgressIndicator
              style={progressContainer}
              variant="circle"
              width={25}
            />
          )}
          endMessage={(
            <WppTypography style={noOptionsFound} tag="p" type="s-body">No options found</WppTypography>
          )}
          scrollableTarget="scrollableOptionsList"
        >
          {_.map(items, (item) => {
            const text = displayFn(item);
            const checked = selected.has(keyFn(item));
            return (
              <ItemDisplay
                key={keyFn(item)}
                onClick={onClick}
                checked={checked}
                text={text}
                item={item}
                disabled={defaultValue ? disabledItemInDefaultValues(item) : disabled}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default WppOptionsList;
