import type { JSX } from '@wppopen/components-library/components';
import { WppInlineMessage, WppInput } from '@wppopen/components-library-react';
import { StyleReactProps } from '@wppopen/components-library-react/lib/react-component-lib/interfaces';
import _ from 'lodash';
import React, { CSSProperties, useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import style from './styles';

const { container, input, labelContainer, smallInputLabelBorderRadius, labelHover, labelFocus, labelError } = style;

interface WppInputWithlabelProps extends JSX.WppInput, Omit<React.HTMLAttributes<HTMLWppInputElement>, 'style'>, StyleReactProps, React.RefAttributes<HTMLWppInputElement> {
  label: string
  field?: ControllerRenderProps<any, any>
  fieldState?: ControllerFieldState
  skipIsDirtyCheck?: boolean
  labelWidth?: CSSProperties
}

const WppInputWithLabel = ({ label, field, fieldState, skipIsDirtyCheck, ...rest }: WppInputWithlabelProps) => {
  const [hover, setHover] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const smallInputSize = _.get(rest, 'size') === 's';
  const dirty = skipIsDirtyCheck ? fieldState?.error : (fieldState?.isDirty && fieldState?.error);
  const showError = (!_.isNil(field) && !_.isNil(fieldState) && dirty) && fieldState?.error.message;

  const labelStyle = {
    ...labelContainer,
    ...(smallInputSize && smallInputLabelBorderRadius),
    ...(hover && labelHover),
    ...(focus && labelFocus),
    ...(showError && labelError),
    ...(rest.labelWidth && rest.labelWidth),
  };

  return (
    <div>
      <div style={container}>
        <WppInput
          style={input}
          onWppFocus={() => setFocus(true)}
          onWppBlur={() => setFocus(false)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={`custom-wpp-input-with-label${showError ? ' validated-input-error' : ''}`}
          {...rest}
        />
        <div
          style={labelStyle}
          className={showError ? 'validated-input-error' : ''}
        >
          {label}
        </div>
      </div>
      {showError && (
        <WppInlineMessage
          className="validated-error-msg"
          size="s"
          message={fieldState.error.message}
          type="error"
        />
      )}
    </div>
  );
};

export default WppInputWithLabel;
