/**
*
* wrapper for semantic Checkbox
*
*/

import React from 'react';
import { Checkbox as SemanticCheckBox, CheckboxProps } from 'semantic-ui-react';

const Checkbox = (props: CheckboxProps) => <SemanticCheckBox {...props} />;

export default Checkbox;
