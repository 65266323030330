import React, { useState, useEffect, useCallback } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { BAR_HEIGHT } from 'charts/HeliosDataViz/constants';

export const DEFAULT_MARGINS = {
  top: 4,
  right: 0,
  bottom: 4,
  left: 0,
};

export type ColorLegendData = {
  colorRange
  domain: Array<number>
  format?: (d: number) => string
};

type ContainerProps = {
  id: string
  data: ColorLegendData
  width: number
  type: 'continuous' | 'discrete'
  barWidth: number
  margins?: {
    top: number
    left: number
    bottom: number
    right: number
  }
};

const D3Container = ({
  id,
  data,
  width,
  type,
  barWidth,
  margins,
}: ContainerProps) => {
  const [drawLayer, setDrawLayer] = useState(null);

  const drawChart = useCallback((myDrawLayer) => {
    const canvas = myDrawLayer.selectAll('canvas')
      .data([null])
      .join('canvas')
      .attr('width', barWidth)
      .attr('height', BAR_HEIGHT)
      .style('margin', `${margins.top}px ${margins.right}px ${margins.bottom}px ${margins.left}px`)
      .node();

    const ctx = canvas.getContext('2d');

    if (type === 'continuous') {
      // fill rect with color gradient
      const grd = ctx.createLinearGradient(0, 0, barWidth, 0);
      _.forEach(data.colorRange, (d, i) => grd.addColorStop((+i / (data.colorRange.length - 1)), d));
      ctx.fillStyle = grd;
      ctx.fillRect(0, 0, barWidth, BAR_HEIGHT);
    } else {
      // fill rect with color blocks
      _.forEach(data.colorRange, (color, i: number) => {
        ctx.fillStyle = color;
        ctx.fillRect(i * (barWidth / data.colorRange.length), 0, barWidth, BAR_HEIGHT);
      });
    }
  }, [data, margins, type, barWidth]);

  useMount(() => {
    const ch = d3.select(`#${id}`).style('width', `${width}px`);

    const myDrawLayer = ch.append('div')
      .attr('class', 'drawLayer')
      .style('width', `${width}px`)
      .style('height', `${BAR_HEIGHT + margins.bottom + margins.top}px`)
      .style('position', 'relative');

    setDrawLayer(myDrawLayer);
    drawChart(myDrawLayer);
  });

  useEffect(() => {
    if (drawLayer) {
      drawChart(drawLayer);
    }
  }, [data, drawLayer, drawChart]);

  return (<div id={id} />);
};

export enum Orientation {
  top = 'top',
  bottom = 'bottom',
}

export enum GoalPriority {
  primary = 'Primary Goal',
  secondary = 'Secondary Goal',
  none = 'none',
}

export type ColorLegendProps = Omit<ContainerProps, 'barWidth'> & {
  lowerIsBetter: boolean
  label: string
  showMeanValue?: boolean
  meanValue?: number
  showGoalValue?: boolean
  goalValue?: number
  orientation?: Orientation
  priority?: GoalPriority
  hasRevenueType?: boolean
  showLabel?: boolean
};

type LabelProps = {
  label: string
  priority: GoalPriority
  hasRevenueType: boolean
};
const getPriorityLabel = (hasRevenueType: boolean, priority: GoalPriority) => {
  if (hasRevenueType) {
    return 'Revenue Type';
  }
  return (priority === GoalPriority.none) ? '' : priority;
};
const Label = ({ label, priority, hasRevenueType }: LabelProps) => (
  <div className="legend-color-label">
    <span>{label}</span>
    <span className="priority">{getPriorityLabel(hasRevenueType, priority)}</span>
  </div>
);

const ValueLabels = ({ valueLabels, data, orientation, showMeanValue }: {
  valueLabels: Array<number>
  data: ColorLegendData
  orientation: Orientation
  showMeanValue: boolean
}) => (
  <div className="val-labels-container">
    {_.map(valueLabels, (v, i) => {
      const formattedVal = _.isNil(v) ? '--' : data.format(v);
      const showMeanText = (showMeanValue && i === 1);
      const includeMean = _.size(valueLabels) === 3;
      return (
        <div
          style={{ ...(includeMean && i === 1 && { textAlign: 'center' }), ...(includeMean && i === 2 && { textAlign: 'end' }) }}
          key={`${v}${i}`}
          className={`val-label ${showMeanText ? 'mean' : ''} ${orientation === Orientation.bottom ? 'center' : ''}`}
        >
          <span>{formattedVal}</span>
          {showMeanText && <span>Mean</span>}
        </div>
      );
    })}
  </div>
);

type GoalLineProps = {
  goalValue: number
  meanValue: number
  xPosScale: d3.ScaleLinear<number, number>
  hasRevenueType: boolean
  showMeanValue: boolean
};

const GoalLine = (props: GoalLineProps) => {
  const { goalValue, meanValue, xPosScale, hasRevenueType, showMeanValue } = props;
  const maxX = _.last(xPosScale.range());
  const scaledGoalVal = xPosScale(goalValue);
  const scaledMeanVal = xPosScale(meanValue);

  const styleObj = (scaledGoalVal <= maxX)
    ? { left: `${_.max([scaledGoalVal, -8])}px` }
    : { right: `${_.min([scaledGoalVal, -8])}px` };

  const goalTextPos = (scaledGoalVal <= scaledMeanVal) ? 'to-right' : 'to-left';

  return (
    <div className={`goal-value-line${showMeanValue ? ' showMean' : ''}`} style={styleObj}>
      <span className={goalTextPos}>{hasRevenueType ? 'Revenue Value' : 'Goal'}</span>
    </div>
  );
};

const ColorLegend = (props: ColorLegendProps) => {
  const {
    id,
    width,
    data,
    label,
    showMeanValue,
    meanValue,
    showGoalValue,
    goalValue,
    type,
    lowerIsBetter,
    orientation = Orientation.bottom,
    priority = GoalPriority.primary,
    margins = DEFAULT_MARGINS,
    hasRevenueType = false,
    showLabel = true,
  } = props;

  // data will come in from low to high
  // we need to transform is so that it's displayed from bad to good
  const dataTransformedBadToGood = {
    ...data,
    domain: lowerIsBetter ? _.reverse(data.domain) : data.domain,
    colorRange: lowerIsBetter ? _.reverse(data.colorRange) : data.colorRange,
  };

  const worst = _.first(dataTransformedBadToGood.domain);
  const best = _.last(dataTransformedBadToGood.domain);
  const valueLabels = meanValue
    ? [worst, meanValue, best]
    : [worst, best];

  const BAR_WIDTH = width - (margins.left + margins.right);

  const xPosStep = BAR_WIDTH / _.size(dataTransformedBadToGood.domain);
  const xPosScale = d3.scaleLinear()
    .domain(dataTransformedBadToGood.domain)
    .range(_.range(0, BAR_WIDTH + xPosStep, xPosStep));

  return (
    <div className="legend-color" style={{ width }}>
      {showLabel && (
        <Label
          label={label}
          priority={priority}
          hasRevenueType={hasRevenueType}
        />
      )}
      {orientation === Orientation.top && (
        <ValueLabels
          valueLabels={valueLabels}
          data={dataTransformedBadToGood}
          orientation={orientation}
          showMeanValue={showMeanValue}
        />
      )}
      <D3Container
        id={id}
        data={dataTransformedBadToGood}
        width={width}
        type={type}
        barWidth={BAR_WIDTH}
        margins={margins}
      />
      {orientation !== Orientation.top && (
        <ValueLabels
          valueLabels={valueLabels}
          data={dataTransformedBadToGood}
          orientation={orientation}
          showMeanValue={showMeanValue}
        />
      )}
      {
        showGoalValue && (
          <GoalLine
            goalValue={goalValue}
            meanValue={meanValue}
            xPosScale={xPosScale}
            hasRevenueType={hasRevenueType}
            showMeanValue={showMeanValue}
          />
        )
      }
    </div>
  );
};

export default ColorLegend;
