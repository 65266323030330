import React from 'react';
import * as d3 from 'd3';
import * as _ from 'lodash';
import Slider from 'charts/Components/Slider';
import { BAR_HEIGHT } from 'charts/HeliosDataViz/constants';
import { SLIDER_MARGINS, ZOOM_SLIDER_STYLES } from './constants';
import { ZoomSliderProps } from './types';

export const ZoomSlider = ({ domainValues, width, zoomValue, onChange }: ZoomSliderProps) => {
  const BAR_WIDTH = width - (SLIDER_MARGINS.left + SLIDER_MARGINS.right);

  const xScale = d3.scaleLinear()
    .domain(domainValues)
    .range(_.map(domainValues, (_d, i) => (BAR_WIDTH / (domainValues.length - 1)) * i))
    .clamp(true);

  const format = d3.format('.1f');

  const valueLabel = (d) => `Zoom ${format(d)}`;

  return (
    <div
      className="zoom-slider"
      style={ZOOM_SLIDER_STYLES}
    >
      <Slider
        id="zoom-slider"
        defaultValue={zoomValue}
        dimensions={{
          width,
          height: BAR_HEIGHT + SLIDER_MARGINS.top + SLIDER_MARGINS.bottom,
          barWidth: BAR_WIDTH,
          barHeight: BAR_HEIGHT,
        }}
        margins={SLIDER_MARGINS}
        xScale={xScale}
        valueLabel={valueLabel}
        onChange={onChange}
        isCompact
      />
    </div>
  );
};

export default ZoomSlider;
