import { CSSProperties } from 'react';

const dimmerContainer: CSSProperties = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  inset: 0,
  zIndex: 10,
};

export const dimmerPage: CSSProperties = {
  ...dimmerContainer,
  position: 'fixed',
};

export const dimmerElement: CSSProperties = {
  ...dimmerContainer,
  position: 'absolute',
};

export const dimmer: CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: 'var(--wpp-grey-color-100)',
  opacity: 0.5,
};

export const childrenContainer: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  zIndex: 11,
  top: 0,
  width: '100%',
  height: '100%',
  flexDirection: 'column',
};
