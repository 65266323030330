import React from 'react';
import { KPIsByVariantWithVariant } from 'containers/ABInsights/types';
import Arc from '../Arc';
import { BudgetTypeOptions } from '../ABReportViz';

type Props = {
  kpisByVariantWithVariant: KPIsByVariantWithVariant
  budgetTypeOptions: BudgetTypeOptions
};

export default ({ kpisByVariantWithVariant, budgetTypeOptions }: Props) => (
  <div className="delivery">
    <p className="label">Delivery</p>
    <Arc
      label={budgetTypeOptions.impressions.text}
      data={kpisByVariantWithVariant}
      accessor="impressions"
    />
    <Arc
      label={budgetTypeOptions.advRevenue.text}
      data={kpisByVariantWithVariant}
      accessor="advRevenue"
    />
  </div>
);
