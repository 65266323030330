import React from 'react';
import Selector from 'charts/Components/Selector';
import { METRIC_ALIASES } from 'charts/HeliosDataViz/Helios/constants';
import { RankListChart } from './RankListChart';

type Props = {
  width: number,
  data: any,
  ranklistIndex: number,
  onChange: Function,
  kpi: string,
  setShowNode: Function,
};

export const RankList = ({ width, data, onChange, ranklistIndex, kpi, setShowNode }: Props) => (
  <div className="helios-sidepanel toplist">
    <Selector
      id="list-selector"
      options={data.options}
      selectedIndex={ranklistIndex}
      onChange={onChange}
      valueLabel={(d) => d.label}
      svgClick={{ isSvgClick: false, resetSvgClick: () => { } }}
      icon="chevron down"
    />
    <div className="by-line">By {METRIC_ALIASES[kpi]}</div>
    <RankListChart
      width={width}
      data={data.list}
      setShowNode={setShowNode}
    />
  </div>
);

export default RankList;
