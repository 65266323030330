import React from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import { MetricsFormattingConstants } from 'constantsBase';
import { GoalType } from 'utils/types';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { TooltipConfig } from './utils';
import { ABColors } from './ABReportViz';

type Props = {
  tooltip: TooltipConfig
  budgetTypeDisplayText: string
  selectedKPI: Metric & GoalType
  colors: ABColors
  baseXPosition?: number
  baseYPosition?: number
  includeCombinedDelivery?: boolean
};

export default (props: Props) => {
  const {
    tooltip,
    budgetTypeDisplayText,
    colors,
    selectedKPI,
    baseXPosition,
    baseYPosition,
    includeCombinedDelivery = false,
  } = props;
  const { text: selectedKPIText, lowerIsBetter, format: selectedKPIFormat } = selectedKPI;
  return (
    <div
      className="tooltip-wrapper"
      style={{ left: `${baseXPosition + tooltip.position[0]}px`, top: `${baseYPosition + tooltip.position[1]}px` }}
    >
      <div className="tooltip-box">
        <table>
          <thead>
            <tr>
              <th className="domain">{tooltip.featureLabel}</th>
              <th>{budgetTypeDisplayText}</th>
              <th>{selectedKPIText}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ color: colors.a }}>A</td>
              <td>
                {
                  _.isNil(tooltip.delivery.a)
                    ? '--'
                    : numeral(tooltip.delivery.a).format(MetricsFormattingConstants.ROUNDED_ONE_DECIMAL)
                }
                {(tooltip.delivery.more === 'a') && (
                  <span className="highlight">
                    ▴
                    {
                      numeral(tooltip.delivery.diffRatio).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)
                    }
                  </span>
                )}
              </td>
              <td>
                {
                  _.isNil(tooltip.perf.a)
                    ? '--'
                    : numeral(tooltip.perf.a).format(selectedKPIFormat)
                }
                {(tooltip.perf.better === 'a') && (
                  <span className="highlight">
                    {lowerIsBetter ? '▾' : '▴'}
                    {
                      numeral(tooltip.perf.diffRatio).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)
                    }
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td style={{ color: colors.b }}>B</td>
              <td>
                {
                  _.isNil(tooltip.delivery.b)
                    ? '--'
                    : numeral(tooltip.delivery.b).format(MetricsFormattingConstants.ROUNDED_ONE_DECIMAL)
                }
                {(tooltip.delivery.more === 'b') && (
                  <span className="highlight">
                    ▴
                    {
                      numeral(tooltip.delivery.diffRatio).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)
                    }
                  </span>
                )}
              </td>
              <td>
                {
                  _.isNil(tooltip.perf.b)
                    ? '--'
                    : numeral(tooltip.perf.b).format(selectedKPIFormat)
                }
                {(tooltip.perf.better === 'b') && (
                  <span className="highlight">
                    {lowerIsBetter ? '▾' : '▴'}
                    {
                      numeral(tooltip.perf.diffRatio).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)
                    }
                  </span>
                )}
              </td>
            </tr>
            {includeCombinedDelivery && (
              <tr>
                <td />
                <td>{numeral(_.sum([tooltip.delivery.a, tooltip.delivery.b])).format(MetricsFormattingConstants.ROUNDED_ONE_DECIMAL)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
