import React from 'react';

type Props = {
  useNewColorPalette?: boolean
};

export default ({ useNewColorPalette = false }: Props) => (
  <div id="charts-empty-render" className={useNewColorPalette ? 'new-color-palette' : ''}>
    <div className="loading-icon">
      <div className="spinner" />
      <div className="spinner" />
      <div className="spinner" />
    </div>
  </div>
);
