import React from 'react';
import { WppTableProps } from '../types';
import '../style.scss';

const WppTableBody: React.FC<WppTableProps> = ({ children, style, className }: WppTableProps): React.ReactElement => (
  <tbody className={`wpp-table__header ${className}`} style={style}>
    {children}
  </tbody>
);

export default WppTableBody;
