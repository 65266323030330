import React from 'react';
import _ from 'lodash';
import { Message, Icon } from 'buildingBlocks';
import { useMount } from 'utils/hooks/generic/hookWrappers';

type Props = {
  message: string
  additionalClasses?: Array<string>
  onLoad?: () => void
};

export default ({ message, additionalClasses = [], onLoad = _.noop }: Props) => {
  useMount(onLoad);
  return (
    <div className={`full-container ${_.join(additionalClasses, ' ')}`}>
      <div className="message-container">
        <Message icon>
          <Icon size="small" name="info circle" />
          <Message.Content>
            <Message.Header>{message}</Message.Header>
          </Message.Content>
        </Message>
      </div>
    </div>
  );
};
