import React from 'react';
import * as _ from 'lodash';
import { METRIC_ALIASES, METRIC_FORMATS } from 'charts/HeliosDataViz/Helios/constants';
import TopRightPanel from 'charts/HeliosDataViz/Helios/TopRightPanel';
import DateSelector from 'charts/HeliosDataViz/Helios/DateSelector';
import { HeliosHeaderProps, MetricProps, HeliosMetricProps } from './types';

const HeliosMetric = ({ metric } : HeliosMetricProps) => (
  <div className="metric-item">
    <div className="metric-label">
      {METRIC_ALIASES[metric.key]}
    </div>
    <div className="metric-value">
      {metric.value ? METRIC_FORMATS[metric.key](metric.value) : '---'}
    </div>
  </div>
);

export const HeliosHeader = ({
  dateData,
  onSetDate,
  dateIndex,
  metrics,
  svgClick,
  viewProps,
  colorProps,
  kpiProps,
}: HeliosHeaderProps) => (
  <div className="helios-header">
    <div className="helios-controller">
      <div className="column">
        <div style={{ width: '320px', height: '1px' }} />
      </div>
      <div className="column">
        <DateSelector
          data={dateData}
          onChange={onSetDate}
          dateIndex={dateIndex}
          svgClick={svgClick}
        />
      </div>
      <div className="column">
        <TopRightPanel
          svgClick={svgClick}
          viewProps={viewProps}
          colorProps={colorProps}
          kpiProps={kpiProps}
        />
      </div>
    </div>

    <div className="helios-metrics">
      {_.map(metrics, (d: MetricProps) => (
        <HeliosMetric
          key={d.key}
          metric={d}
        />
      ))}
    </div>
  </div>
);

export default HeliosHeader;
