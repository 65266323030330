import _ from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import Loader from 'charts/Components/Loader';
import { RESULTS_LIMIT } from 'constantsBase';
import { getActiveStrategyGoalsInPriorityOrder } from 'utils';
import { ViewFlight, Strategy } from 'utils/copilotAPI';
import { toCamelCase } from 'utils/formattingUtils';
import useFetcher, { PossibleStates, State } from 'utils/hooks/useFetcher';
import HeliosFlightWrapper from './HeliosFlightWrapper';
import '../styles/helios.sass';
import '../styles/shared-styles.sass';

// fetches the data for all the flights in the strategy
const useFlightFetcher = (strategyId: string): State => {
  const fetchFlights = async (): Promise<State> => {
    const response = await ViewFlight.get({ strategy: strategyId, limit: RESULTS_LIMIT });
    // reverse response.data so that flights are sorted by recency
    return { kind: PossibleStates.hasData, data: _.reverse(response.data) };
  };
  return useFetcher(fetchFlights, [strategyId]);
};

// fetches the data for flight details
const useFetchStrategyDetails = (strategyId: string): State => {
  const fetchStrategyDetails = async (): Promise<State> => {
    const response = await Strategy.getById(strategyId, { populate: 'strategyGoals' });
    return { kind: PossibleStates.hasData, data: response.data };
  };
  return useFetcher(fetchStrategyDetails, [strategyId]);
};

const renderHeliosFlightWrapper = (
  flightDataFetchState: State,
  strategyDetailsFetchState: State,
  strategyId: string,
  flightIndex: number,
  onSetFlight: (index: number) => void,
  strategyGoalType: string,
) => {
  const currentStateKinds = [flightDataFetchState.kind, strategyDetailsFetchState.kind];

  if (currentStateKinds.includes(PossibleStates.initial) || currentStateKinds.includes(PossibleStates.loading)) {
    return <Loader />;
  } if (flightDataFetchState.kind === PossibleStates.hasData && !!flightDataFetchState.data.length) {
    const { data: flights } = flightDataFetchState;
    return (
      <HeliosFlightWrapper
        flights={_.map(flights, (f) => _.pick(f, ['id', 'name']))}
        selectedFlight={flights[flightIndex]}
        onSetFlight={onSetFlight}
        flightIndex={flightIndex}
        strategyGoalType={toCamelCase(strategyGoalType)}
        strategyId={strategyId}
      />
    );
  }
  return (
    <div id="charts-empty-render helios-nodata">
      <p>No data available for this strategy.</p>
    </div>
  );
};

export default () => {
  const params = useParams();
  const strategyId = params.id;
  const flightDataFetchState = useFlightFetcher(strategyId);
  const strategyDetailsFetchState = useFetchStrategyDetails(strategyId);

  const sortedActiveStrategyGoals = getActiveStrategyGoalsInPriorityOrder(
    _.get(strategyDetailsFetchState, 'data.strategyGoals'),
  );
  const strategyGoalType = _.get(_.first(sortedActiveStrategyGoals), 'type');
  const [flightIndex, setFlightIndex] = useState<number>(0);

  return (
    <div id="helios-viz">
      {renderHeliosFlightWrapper(
        flightDataFetchState,
        strategyDetailsFetchState,
        strategyId,
        flightIndex,
        setFlightIndex,
        strategyGoalType,
      )}
    </div>
  );
};
