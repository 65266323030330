import React from 'react';
import Selector from 'charts/Components/Selector';
import { METRIC_ALIASES } from 'charts/HeliosDataViz/Helios/constants';
import { TopRightPanelProps } from './types';

const getOptionIndex = (options, val) => options.map((d) => d.type).indexOf(val);

const valueLabel = (d) => d.label;

export const TopRightPanel = ({ svgClick, viewProps, colorProps, kpiProps }: TopRightPanelProps) => (
  <div className="top-right-panel">
    <Selector
      id="size-selector"
      prefix="Size: "
      options={kpiProps.options}
      selectedIndex={kpiProps.index}
      onChange={kpiProps.onChange}
      valueLabel={(d) => METRIC_ALIASES[d]}
      svgClick={svgClick}
      icon="chevron down"
    />
    <Selector
      id="color-selector"
      prefix="Color: "
      options={colorProps.options}
      selectedIndex={colorProps.index}
      onChange={colorProps.onChange}
      valueLabel={(d) => METRIC_ALIASES[d]}
      svgClick={svgClick}
      icon="chevron down"
    />
    <Selector
      id="view-selector"
      iconOnly
      icon="setting"
      options={viewProps.options}
      selectedIndex={getOptionIndex(viewProps.options, viewProps.selectedView)}
      onChange={(i) => viewProps.onChange(viewProps.options[i].type)}
      valueLabel={valueLabel}
      svgClick={svgClick}
    />
  </div>
);

export default TopRightPanel;
