import { SemanticICONS } from 'buildingBlocks';
import { GOAL_TYPES } from 'constantsBase';
import { metrics } from 'containers/StrategyAnalytics/constants/metricsConstants';

export const DEFAULT_BUDGET_TYPE = 'impressions';

export const NODATA_COLOR = '#d4d4d4';

export enum VizId {
  overview = 'overview',
  performance = 'performance',
  geography = 'geography',
  device = 'device',
  creativeSize = 'creativeSize',
  domain = 'domain',
}

export const VISUALIZATIONS_CONFIG = {
  [VizId.overview]: {
    tooltipContent: 'Compares performance against primary goal as well as any additional goals selected in the configuration step for A vs. B. Also compares delivery in terms of spend and impressions for A vs. B.',
  },
  [VizId.performance]: {
    tooltipContent: 'Compares selected goal performance over time, cumulatively or daily, for A vs. B.',
    overrideDimensions: {
      w: 10,
      h: 9,
    },
  },
  [VizId.geography]: {
    tooltipContent: 'Compares total delivery and selected goal performance uplift (A vs. B) across regions within a campaign\'s primary country. Raw total (A + B) delivery to each region is denoted by circle size (also indicated by circle label) and goal uplift is indicated by color.',
  },
  [VizId.device]: {
    tooltipContent: 'Compares selected goal performance (indicated by color) and delivery (indicated by label) for each device type that has delivery to both A and B.',
  },
  [VizId.creativeSize]: {
    tooltipContent: 'Compares selected goal performance (indicated by color) and delivery (indicated by label) for each creative size that has delivery to both A and B.',
  },
  [VizId.domain]: {
    tooltipContent: 'Compares selected goal performance (denoted by position in the horizontal direction) and delivery (indicated on the interactive tooltip and denoted by circle size, if box is checked) for A vs. B for the top 20 domains (by total delivery).',
  },
};

export enum IconType {
  fontAwesome = 'font-awesome',
  custom = 'custom',
}

type FontAwesomeIcon = {
  kind: IconType.fontAwesome
  name: SemanticICONS
};

type CustomIcon = {
  kind: IconType.custom
  name: string
};

export type IconConfig = FontAwesomeIcon | CustomIcon;

export const SlideIcons: { [title: string]: IconConfig } = {
  [VizId.overview]: {
    kind: IconType.fontAwesome,
    name: 'dashboard',
  },
  [VizId.creativeSize]: {
    kind: IconType.custom,
    name: 'CreativeSize',
  },
  [VizId.domain]: {
    kind: IconType.custom,
    name: 'Browser',
  },
  [VizId.device]: {
    kind: IconType.fontAwesome,
    name: 'mobile alternate',
  },
  [VizId.geography]: {
    kind: IconType.custom,
    name: 'Globe',
  },
};

export const AB_COLORS_ORANGE_TO_BLUE = ['#E3A746', '#F2C384', '#FBE1C1', '#CBC8F7', '#9293EE', '#4662E3'];
export const AB_COLORS_ORANGE_TO_PURPLE = ['#E3A746', '#F2C384', '#FBE1C1', '#E0BDF7', '#BC7BEE', '#9130E3'];

export const EXPORT_PREFIX = 'forExport';

export const KPIS_TO_SUCCESS_METRICS = {
  [GOAL_TYPES.ivrImps.value]: 'viewable impressions',
  [GOAL_TYPES.ivrMeasured.value]: 'viewable impressions',
  [GOAL_TYPES.ctr.value]: 'clicks',
  [GOAL_TYPES.conversionRate.value]: 'conversions',
  [GOAL_TYPES.completionRate.value]: 'video completes',
  [GOAL_TYPES.cpm.value]: 'impressions',
  [GOAL_TYPES.vcpm.value]: 'viewable impressions',
  [GOAL_TYPES.cpc.value]: 'clicks',
  [GOAL_TYPES.cpa.value]: 'conversions',
  [GOAL_TYPES.cpcv.value]: 'video completes',
  [GOAL_TYPES.vcpcv.value]: 'viewable video completes',
  [metrics.ratePercentage.netVcpcv.value]: 'viewable video completes',
};
