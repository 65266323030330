import React from 'react';
import { WppTableProps } from '../types';
import '../style.scss';

const WppTableBodyRow: React.FC<WppTableProps> = ({ children, style, className }: WppTableProps): React.ReactElement => (
  <tr className={`wpp-table__row${className ? ` ${className}` : ''}`} style={style}>
    {children}
  </tr>
);

export default WppTableBodyRow;
