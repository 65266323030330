import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import 'd3-selection-multi';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { COPILOT_COLORS } from 'globalStyles';
import { SliderProps } from './types';

const { NEW_DESIGN_SYSTEM: { BLUES } } = COPILOT_COLORS;

const D3Container = ({ id, defaultValue, dimensions, margins, xScale, valueLabel,
  onChange, isCompact }: SliderProps) => {
  const [drawLayer, setDrawLayer] = useState(null);
  const { width, height, barWidth, barHeight } = dimensions;
  const drawChart = (myDrawLayer) => {
    const slider = myDrawLayer
      .selectAll('g.slider')
      .data([null])
      .join('g')
      .attr('class', 'slider')
      .attr('transform', `translate(${margins.left}, 0)`);

    slider
      .selectAll('rect.track')
      .data([null])
      .join('rect')
      .attr('class', 'track')
      .attr('width', barWidth)
      .attr('height', height)
      .attr('fill', 'transparent')
      .style('cursor', 'pointer');

    slider.selectAll('rect.bar')
      .data([null])
      .join('rect')
      .attr('class', 'bar')
      .attr('width', barWidth)
      .attr('height', barHeight)
      .attr('fill', 'gray')
      .attr('opacity', '0.3')
      .attr('pointer-events', 'none')
      .attr('transform', `translate(0, ${margins.top})`);

    const handle = slider
      .selectAll('g.handle')
      .data([null])
      .join('g')
      .attr('class', 'handle')
      .attr('transform', `translate(${xScale(defaultValue) - 4}, ${margins.top - 4})`);

    handle
      .selectAll('rect.handle-rect')
      .data([null])
      .join('rect')
      .attr('class', 'handle-rect')
      .attr('width', 8)
      .attr('height', barHeight + 8)
      .attr('fill', BLUES.B500_WAVE)
      .attr('pointer-events', 'none');

    if (!isCompact) {
      handle
        .selectAll('path')
        .data([null])
        .join('path')
        .attr('d', 'M0,0 L12,0 L12,4 L6,10 L0,4 L0,0 Z')
        .attr('fill', BLUES.B500_WAVE)
        .attr('transform', 'translate(-2, -14)');
    }

    handle
      .selectAll('text')
      .data([null])
      .join('text')
      .attr('pointer-events', 'none')
      .attr('fill', BLUES.B500_WAVE)
      .styles({
        'font-size': `${isCompact ? 12 : 14}px`,
        'text-anchor': 'middle',
      })
      .attr('transform', `translate(0, -${isCompact ? 8 : 20})`)
      .text(valueLabel(defaultValue));

    const positionHandle = (d) => {
      handle
        .attr('transform', `translate(${xScale(d) - 4}, ${margins.top - 4})`)
        .selectAll('text')
        .text(valueLabel(d));
    };

    const drag = d3.drag()
      .on('start.interrupt', () => {
        slider.interrupt();
      })
      .on('start drag', () => {
        d3.select('.loading-icon')
          .style('display', 'block');
        positionHandle(xScale.invert(d3.event.x - margins.left));
      })
      .on('end', () => {
        onChange(xScale.invert(d3.event.x - margins.left));
      });

    slider.call(drag);
  };

  useMount(() => {
    const ch = d3.select(`#${id}-slider-chart`).style('width', `${width}px`);

    const myDrawLayer = ch.append('svg')
      .attr('class', 'drawLayer')
      .attr('width', width)
      .attr('height', height);

    setDrawLayer(myDrawLayer);
    drawChart(myDrawLayer);
  });

  useEffect(() => {
    if (drawLayer) {
      drawChart(drawLayer);
    }
  });

  return (<div id={`${id}-slider-chart`} />);
};

export const SliderChart = ({ id, defaultValue, dimensions, margins, xScale, valueLabel,
  onChange, isCompact }: SliderProps) => (
    <D3Container
      id={id}
      defaultValue={defaultValue}
      dimensions={dimensions}
      margins={margins}
      valueLabel={valueLabel}
      onChange={onChange}
      xScale={xScale}
      isCompact={isCompact}
    />
);
