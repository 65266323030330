import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { WppInput, WppTypography } from 'buildingBlocks';
import { shiftDecimalSeparator } from 'utils/formattingUtils';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import { BUDGET_INTERVALS } from 'containers/StrategyWizard/steps/GoalSelection/styles';

const getValueBasedOnShift = (
  value: number | string,
  shift: number,
  precision?: number,
): number => {
  let res;

  // if precision is set we need to round it on it final displaying form
  // => if we shift to the left, we need to round it before the shifting
  // => if we shift to the right, we need to round it after the shifting
  if (_.isInteger(precision)) {
    if (shift < 0) {
      res = shiftDecimalSeparator(_.round(Number(value), precision), shift);
    } else {
      res = _.round(shiftDecimalSeparator(value, shift), precision);
    }
  } else {
    res = shiftDecimalSeparator(value, shift);
  }

  // if the result of the shifting didn't worked out we return the entered value
  return res || Number(value);
};

export const getRealValue = (
  value: number | string | undefined,
  shift: number,
  precision?: number,
): number | string => {
  // user deleted all input from the field
  if (value === '') {
    return value;
  }
  const res = getValueBasedOnShift(value, shift, precision);
  return _.isFinite(res) ? res : value;
};

type ReactHookFormProps = {
  precision: number
  value: number
  onChange: (value: number | '') => void
  size?: 's' | 'm'
  style?: React.CSSProperties
};

type Props = ReactHookFormProps;

/**
 * Allow user input of percentage values, while automatically handling
 * conversions to real values. For example a user entering the number 50 will
 * be translated internally to a 0.5.
 */
const PercentageInput = (props: Props) => {
  const { style, size } = props;
  // We do the shifting right (multiplication by 100) for display
  const [value, setValue] = useState<number | string>(
    getRealValue(props.value, 2, props.precision),
  );

  useEffect(() => {
    const newValue = getRealValue(props.value, 2, props.precision);
    setValue(newValue);
  }, [props.value, props.precision]);

  const onInputChange = (inputValue: string) => {
    // We do the shift left (division by 100)
    const newValue = getRealValue(inputValue, -2, props.precision) || inputValue;
    if (newValue === '') {
      props.onChange('');
    } else {
      props.onChange(+newValue);
    }

    setValue(
      props.precision
        ? _.round(Number(inputValue), props.precision)
        : +inputValue,
    );
  };

  return (
    <WppInput
      type="decimal"
      size={size || 's'}
      style={style}
      onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => onInputChange(event.detail.value)}
      className="input-currency"
      value={!_.isNull(value) ? _.toString(value) : ''}
    >
      <WppTypography
        type="s-strong"
        slot="icon-end"
        tag="p"
        style={BUDGET_INTERVALS.inputcurrency}
      >
        %
      </WppTypography>
    </WppInput>
  );
};

export default PercentageInput;
