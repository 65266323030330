import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Microservices } from 'utils/copilotAPI';
import useFetcher, { PossibleStates, State } from 'utils/hooks/useFetcher';
import Selector from 'charts/Components/Selector';
import Loader from 'charts/Components/Loader';
import { getLocalStorageItem } from 'utils/localstorage/utils';
import { HeliosFlightWrapperProps } from './types';
import Helios from './Helios';

export const themeSettingLSKey = 'theme-setting';

// fetches the helios data viz data for a specific flight
const useHeliosDataVizFetcher = (strategyId: string, flightId: number): State => {
  const fetchHeliosData = async (): Promise<State> => {
    const response = await Microservices.runService(
      { strategy_id: strategyId, flight_id: flightId },
      'helios_dataviz',
    );
    return { kind: PossibleStates.hasData, data: response.data };
  };

  return useFetcher(fetchHeliosData, [strategyId, flightId]);
};

const renderHelios = (
  currentState: State,
  props: HeliosFlightWrapperProps,
) => {
  const NoData = (
    <div id="charts-empty-render">
      <p className="helios-nodata">No data available for this flight.</p>
    </div>
  );
  switch (currentState.kind) {
    case PossibleStates.initial:
    case PossibleStates.loading:
      return <Loader />;
    case PossibleStates.hasData: {
      const { data } = currentState;
      const { top10, trees } = data;
      if (!_.isNil(trees) && !_.isEmpty(trees)) {
        return <Helios {...props} top10={top10} trees={trees} />;
      }
      return NoData;
    }
    default:
      return NoData;
  }
};

const HeliosFlightWrapper = (props: HeliosFlightWrapperProps) => {
  const { strategyId, selectedFlight, flights, flightIndex, onSetFlight } = props;
  const dataVizFetchState = useHeliosDataVizFetcher(strategyId, selectedFlight.id);

  return (
    <div className="helios-wrapper">
      <div className="flight-selector-wrapper">
        <Selector
          id="flight-selector"
          options={flights}
          selectedIndex={flightIndex}
          onChange={onSetFlight}
          valueLabel={(d) => d.name}
          svgClick={{ isSvgClick: false, resetSvgClick: () => { } }}
          icon="chevron down"
        />
      </div>
      {renderHelios(dataVizFetchState, props)}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userSelectedTheme: getLocalStorageItem(state, themeSettingLSKey),
  env: state.login.env,
});

export default connect(
  mapStateToProps,
)(HeliosFlightWrapper);
