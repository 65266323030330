import _ from 'lodash';
import React, { useState } from 'react';
import { WrappedFieldInputProps } from 'redux-form';
import { Button, Modal } from 'buildingBlocks';
import { AnyObject, StateModifier } from 'utils/types';
import MultiSelect from './MultiSelect';
import { ModalMultiSelectProps } from './TwoListMultiSelect';

type InMapperType = (x: any) => { text: string, value: string };

export const multiSelectHeaderStyle = {
  fontSize: '16px',
  fontWeight: 500,
  color: '#4a4a4a',
};

function getSelected<T>(value: Array<T>, input: WrappedFieldInputProps, inMapper?: InMapperType) {
  const data: Array<T> = _.get(input, 'value', value || []);
  return inMapper ? _.filter(_.map(data, inMapper)) : data;
}

function handleSubmit<T>(
  selected: Array<T>,
  setModalOpen: StateModifier<boolean>,
  outMapper: (outData: T, additionalState: AnyObject) => any,
  input: WrappedFieldInputProps,
  onChange: Function,
  additionalState: AnyObject,
) {
  setModalOpen(false);
  let outData = selected;
  if (outMapper) {
    outData = selected.map((s) => outMapper(s, additionalState));
  }
  if (input && input.onChange) {
    input.onChange(outData);
  }
  if (onChange) {
    onChange(outData);
  }
}

const handleClick = (input: WrappedFieldInputProps, setModalOpen: StateModifier<boolean>) => {
  // Touch the field if it's in a form so we can display errors if required
  if (input?.onBlur) {
    input.onBlur(undefined);
  }
  setModalOpen(true);
};

const ModalMultiSelect = (props: ModalMultiSelectProps) => {
  const { additionalDefaultState = {}, btnParams, btnContent,
    input, onChange, inMapper, outMapper, pluralize, name, value, header, renderCustomButton } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [additionalState, setAdditionalState] = useState(additionalDefaultState);
  const selected = getSelected(value, input, inMapper);
  const numberSelected = selected.length;
  const buttonContent = btnContent || `${numberSelected} ${pluralize(numberSelected)} Selected`;
  const buttonName = _.get(input, 'name', name);

  return (
    <div>
      {renderCustomButton ? renderCustomButton(numberSelected, () => handleClick(input, setModalOpen), buttonName) : (
        <Button name={buttonName} fluid onClick={() => handleClick(input, setModalOpen)} {...btnParams}>
          {buttonContent}
        </Button>
      )}
      <Modal
        size="large"
        open={modalOpen}
        style={{ minHeight: '30em' }}
        dimmer="blurring"
      >
        {header && <Modal.Header style={multiSelectHeaderStyle}>{header}</Modal.Header>}
        <Modal.Content>
          <MultiSelect
            onSubmit={(s: any) => (handleSubmit(s, setModalOpen, outMapper, input, onChange, additionalState))}
            onCancel={() => { setModalOpen(false); setAdditionalState(additionalDefaultState); }}
            value={selected}
            onInputChange={(v: AnyObject) => { setAdditionalState((as) => ({ ...as, ...v })); }}
            additionalState={additionalState}
            {...props}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ModalMultiSelect;
