import React from 'react';
import { SliderChart } from './SliderChart';
import { SliderProps } from './types';

export const Slider = ({ id, defaultValue, dimensions, margins, xScale, valueLabel,
  onChange, isCompact }: SliderProps) => (
    <div className="comp-slider">
      <SliderChart
        id={id}
        defaultValue={defaultValue}
        dimensions={dimensions}
        margins={margins}
        valueLabel={valueLabel}
        onChange={onChange}
        xScale={xScale}
        isCompact={isCompact}
      />
    </div>
);

export default Slider;
