import * as d3 from 'd3';
import { parseDate } from 'charts/utils';

const bisect = d3.bisector((d: any) => parseDate(d.date)).left;

export const findHoverPoint = (mx, x, data) => {
  const date = x.invert(mx);
  const index = bisect(data, date);
  return data[index];
};
