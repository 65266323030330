import React from 'react';
import ColorLegend, { ColorLegendProps, GoalPriority } from 'charts/Components/ColorLegend';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { getColorLegendLabel } from 'charts/InsightsViz/utils';
import DiffColorLegend from './Components/DiffColorLegend';

type Props = Omit<ColorLegendProps, 'type' | 'label' | 'lowerIsBetter'> & {
  selectedMetric: Metric
  currencyCode: string
  variant?: 'perfRange' | 'abDiff'
};

export default ({ id,
  data,
  width,
  margins,
  orientation,
  selectedMetric,
  showMeanValue = false,
  meanValue,
  showGoalValue = false,
  goalValue,
  currencyCode,
  variant = 'perfRange',
}: Props) => (
  <div className="legend-wrapper-updated legend">
    <div className="legend-row">
      {
        (variant === 'abDiff')
          ? (
            <DiffColorLegend
              id={id}
              data={data}
              label={getColorLegendLabel(selectedMetric, currencyCode)}
              width={width}
              margins={margins}
              lowerIsBetter={selectedMetric.lowerIsBetter}
            />
          )
          : (
            <ColorLegend
              id={id}
              data={data}
              label={getColorLegendLabel(selectedMetric, currencyCode)}
              width={width}
              margins={margins}
              orientation={orientation}
              type="discrete"
              lowerIsBetter={selectedMetric.lowerIsBetter}
              showMeanValue={showMeanValue}
              meanValue={meanValue}
              showGoalValue={showGoalValue}
              goalValue={goalValue}
              priority={GoalPriority.none}
            />
          )
      }
      <div className={`no-data ${variant}`}> No {variant === 'abDiff' ? '' : 'Data / '}Success Events</div>
    </div>
  </div>
);
