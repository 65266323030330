import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { WppInput, WppTypography } from 'buildingBlocks';
import { shiftDecimalSeparator } from 'utils/formattingUtils';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import styles from './style';

const getValueBasedOnShift = (value: number | string, shift: number, precision: number): number => {
  let res;

  // if precision is set we need to round it on it final displaying form
  // => if we shift to the left, we need to round it before the shifting
  // => if we shift to the right, we need to round it after the shifting
  if (_.isInteger(precision)) {
    if (shift < 0) {
      res = shiftDecimalSeparator(_.round(Number(value), precision), shift);
    } else {
      res = _.round(shiftDecimalSeparator(value, shift), precision);
    }
  } else {
    res = shiftDecimalSeparator(value, shift);
  }

  // if the result of the shifting didn't worked out we return the entered value
  return res || Number(value);
};

const getRealValue = (value: number | string | undefined, shift: number, precision: number) => {
  // user deleted all input from the field
  if (value === '') {
    return value;
  }
  const res = getValueBasedOnShift(value, shift, precision);
  return _.isFinite(res) ? res : value;
};

type ReduxFormProps = {
  input: {
    name: string
    value: number | string
    onChange: (value: number | string) => void
    onBlur: Function
  }
  precision: number
  label: string
};

type NormalProps = {
  /** Function called when value changes. */
  onChange: (value: number | string) => void
  /** Number of digits of precision. */
  precision: number
  /** Form name of the input. */
  name: string
  /** Value of the input. */
  value: number | string
  /** value for label */
  label: string
  /** disable the field */
  disabled?: string
};

type Props = ReduxFormProps | NormalProps;

const getDisplayValueBasedOnProps = (props: Props) => {
  const { precision } = props;

  // We do the shifting right (multiplication by 100) for display
  const value = (props as ReduxFormProps).input
    ? (props as ReduxFormProps).input.value
    : (props as NormalProps).value;
  return getRealValue(value, 2, precision);
};

const PercentageInput: React.FC<Props> = (props) => {
  const [name, setName] = useState<string | null>(null);
  const [value, setValue] = useState<number | string>(getDisplayValueBasedOnProps(props));

  useEffect(() => {
    const newValue = getDisplayValueBasedOnProps(props);
    setValue(newValue);
    setName(
      (props as ReduxFormProps).input?.name || (props as NormalProps).name || undefined,
    );
  }, [props]);

  const handleChange = (newValue: number | string): void => {
    const { precision } = props;

    // We do the shift left (division by 100)
    const transformedValue = getRealValue(newValue, -2, precision) || newValue;

    if ('onChange' in props) {
      props.onChange(transformedValue);
    }
    if ('input' in props && props.input.onChange) {
      // Set the divided value to the form
      props.input.onChange(transformedValue);
    }

    setValue(precision ? _.round(Number(newValue), precision) : newValue);
  };

  // fixes issue where redux form thinks the field isn't dirty yet.
  const handleBlur = (v: any) => {
    if ((props as ReduxFormProps).input && (props as ReduxFormProps).input.onBlur) {
      (props as ReduxFormProps).input.onBlur(v);
    }
  };

  return (
    <WppInput
      type="decimal"
      size="m"
      name={name}
      data-testid="percentage-input"
      onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => handleChange(event.detail.value)}
      onWppBlur={(v: any) => handleBlur(v)}
      {..._.omit(props, 'value', 'input', 'precision', 'onChange')}
      value={!_.isNull(value) ? _.toString(value) : ''}
      className="percentage-input-with-label"
    >
      <WppTypography
        type="s-strong"
        tag="p"
        slot="icon-end"
        style={styles.percentageInputLabel}
      >
        {props.label}
      </WppTypography>
    </WppInput>
  );
};

export default PercentageInput;
