import _ from 'lodash';
import React, { ReactText } from 'react';
import { OrderedMap } from 'immutable';
import { Checkbox } from 'buildingBlocks';
import { AnyObject } from 'utils/types';

export type ItemDisplayProps = {
  text: string,
  checked?: boolean,
  onClick: (item: { [key: string]: any }) => void,
  item: any,
  disabled?: boolean,
};

export const BasicSubHeader = ({ label }: { label: string }) => (
  <div className="row-item subheader">
    <span className="label">{label}</span>
  </div>
);

const ItemDisplay = ({ text, checked, onClick, item, disabled }: ItemDisplayProps) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div onClick={() => onClick(item)} className={`row-item item-display ${disabled && 'item-display-disabled'}`}>
    <span><Checkbox checked={checked} disabled={disabled} /></span>
    <span className="text" style={(checked && !disabled) ? { fontWeight: 500, color: '#376e8f' } : {}}>{text}</span>
  </div>
);

type OptionsListProps = {
  items: Array<any>,
  keyFn: (x: unknown) => ReactText,
  displayFn: (x: unknown) => string,
  pluralize: (n: number) => string,
  selected: OrderedMap<unknown, unknown>,
  onSelected: (value: any) => void,
  isSelected?: boolean,
  renderPaginationControl?: () => JSX.Element,
  customRow?: (...args: Array<any>) => JSX.Element,
  onInputChange?: (v: AnyObject) => void,
  additionalState?: AnyObject,
  renderTopHeader?: () => JSX.Element,
  customSubHeader?: (label: string) => JSX.Element,
  defaultValue?: Array<any>,
  disabled?: boolean,
};

const OptionsList = ({
  items,
  onSelected,
  selected,
  keyFn,
  displayFn,
  pluralize,
  isSelected,
  renderPaginationControl,
  customRow,
  onInputChange,
  additionalState,
  renderTopHeader,
  customSubHeader,
  defaultValue,
  disabled,
}: OptionsListProps) => {
  const label = isSelected ? `${items.length} ${pluralize(items.length)} Selected` : `Select ${pluralize(2)}`;
  const disabledItemInDefaultValues = (item: any) => (disabled && defaultValue && _.includes(_.map(defaultValue, 'id'), item.id));
  const onClick = (item: any) => {
    if (!disabled || (defaultValue && !disabledItemInDefaultValues(item))) {
      onSelected(item);
    }
  };
  return (
    <div className="option-list">
      <div className="viewport-wrapper">
        {renderTopHeader ? renderTopHeader() : <div style={{ height: '40px', borderBottom: '1px solid #d8d8d8' }} />}
        {customSubHeader ? customSubHeader(label) : <BasicSubHeader label={label} />}
        {_.map(items, (item) => {
          const text = displayFn(item);
          const checked = selected.has(keyFn(item));
          return (
            customRow
              ? customRow(item, text, checked, onSelected, onInputChange, additionalState)
              : (
                <ItemDisplay
                  key={keyFn(item)}
                  onClick={onClick}
                  checked={checked}
                  text={text}
                  item={item}
                  disabled={defaultValue ? disabledItemInDefaultValues(item) : disabled}
                />
              )
          );
        })}
        <div style={{ height: '40px' }} />
        {renderPaginationControl && renderPaginationControl()}
      </div>
    </div>
  );
};

export default OptionsList;
