import _ from 'lodash';
import { shiftDecimalSeparator } from 'utils/formattingUtils';

const getValueBasedOnShift = (
  value: number | string,
  shift: number,
  precision?: number,
): number => {
  let res;

  // if precision is set we need to round it on it final displaying form
  // => if we shift to the left, we need to round it before the shifting
  // => if we shift to the right, we need to round it after the shifting
  if (_.isInteger(precision)) {
    if (shift < 0) {
      res = shiftDecimalSeparator(_.round(Number(value), precision), shift);
    } else {
      res = _.round(shiftDecimalSeparator(value, shift), precision);
    }
  } else {
    res = shiftDecimalSeparator(value, shift);
  }

  // if the result of the shifting didn't worked out we return the entered value
  return res || Number(value);
};

export const getRealValue = (
  value: number | string | undefined,
  shift: number,
  precision?: number,
): number | string => {
  // user deleted all input from the field
  if (value === '') {
    return value;
  }
  const res = getValueBasedOnShift(value, shift, precision);
  return _.isFinite(res) ? res : value;
};

export const setNumberValue = (value: string, validatePercentage: boolean = false): number | string => {
  if (value === '' || value === '-') {
    return value;
  }

  if (value === '.') {
    return '0.';
  }

  if (_.startsWith(value, '0.') && _.every(_.last(_.split(value, '.')), (num) => _.isEqual(num, '0'))) {
    return value;
  }

  if (validatePercentage) {
    return _.toNumber(getRealValue(value, -2));
  }

  return _.toNumber(value);
};
