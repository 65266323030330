import * as d3 from 'd3';
import _ from 'lodash';
import { metrics } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { COLOR_RAMP_GREEN_RED } from 'charts/HeliosDataViz/constants';

export const COLOR_RAMP_RED_GREEN = _.reverse([...COLOR_RAMP_GREEN_RED]);

export const FEATURES_ALIASES = {
  supply_type: {
    alias: 'Supply Type',
    values: {
      0: 'web',
      1: 'mobile web',
      2: 'mobile app',
      6: 'toolbar',
    },
  },
  position: {
    alias: 'Position',
    values: {
      0: 'unknown',
      1: 'above the fold',
      2: 'below the fold',
    },
  },
  browser: {
    alias: 'Browser',
    values: {
      0: 'Unknown',
      1: 'Internet Explorer 8',
      2: 'Internet Explorer 7',
      3: 'Internet Explorer 6',
      4: 'Internet Explorer (other versions)',
      5: 'Firefox 4',
      6: 'Firefox 3',
      7: 'Firefox (other versions)',
      8: 'Chrome (all versions)',
      9: 'Safari (all versions)',
      10: 'Opera Mobile',
      11: 'Opera (all versions)',
      12: 'iPhone',
      13: 'BlackBerry',
      14: 'Android',
      16: 'Internet Explorer 9',
      17: 'Internet Explorer 10',
    },
  },
};

export const METRIC_ALIASES = {
  cumulativeBid: 'Bid Multiplier',
  impressions: metrics.aggregator.impressions.text,
  viewableImpressions: metrics.aggregator.viewableImpressions.text,
  clicks: metrics.aggregator.clicks.text,
  postClickConversions: 'Post Click Conv.',
  postViewConversions: 'Post View Conv.',
  conversions: metrics.aggregator.conversions.text,
  viewMeasuredImps: 'View Measured Imps',
  revenue: metrics.aggregator.revenue.text,
  cost: metrics.aggregator.cost.text,
  advRevenue: 'Revenue',
  advCost: 'Cost',
  cpm: 'CPM',
  cpa: metrics.ratePercentage.cpa.text,
  cpc: metrics.ratePercentage.cpc.text,
  ctr: metrics.ratePercentage.ctr.text,
  conversionRate: metrics.ratePercentage.conversionRate.text,
  advCostCPM: 'Net CPM',
  advCostCPA: 'Net CPA',
  advCostCPC: 'Net CPC',
  advRevenueCPM: 'CPM',
  advRevenueCPA: 'CPA',
  advRevenueCPC: 'CPC',
  ivrMeasured: metrics.ratePercentage.ivrMeasured.text,
  videoCompletes: metrics.aggregator.videoCompletes.text,
  cpcv: metrics.ratePercentage.cpcv.text,
  netCPCV: 'Net CPCV',
  advCPCV: metrics.ratePercentage.cpcv.text,
  advNetCPCV: 'Net CPCV',
  completionRate: metrics.ratePercentage.completionRate.text,
  lift: metrics.ratePercentage.margin.text,
  leafCount: 'Leaves',
};

export const METRIC_FORMATS = {
  cumulativeBid: d3.format('.2f'),
  impressions: d3.format('.3s'),
  viewableImpressions: d3.format('.3s'),
  clicks: (n) => n,
  postClickConversions: (n) => n,
  postViewConversions: (n) => n,
  conversions: (n) => n,
  viewMeasuredImps: d3.format('.3s'),
  revenue: d3.format(',.0f'),
  cost: d3.format(',.0f'),
  advRevenue: d3.format(',.0f'),
  advCost: d3.format(',.0f'),
  cpm: d3.format('.2f'),
  cpa: d3.format('.2f'),
  cpc: d3.format('.2f'),
  ctr: d3.format('.2%'),
  conversionRate: d3.format('.3%'),
  advCostCPM: d3.format('.2f'),
  advCostCPA: d3.format('.2f'),
  advCostCPC: d3.format('.2f'),
  advRevenueCPM: d3.format('.2f'),
  advRevenueCPA: d3.format('.2f'),
  advRevenueCPC: d3.format('.2f'),
  ivrMeasured: d3.format('.0%'),
  videoCompletes: (n) => n,
  cpcv: d3.format('.2f'),
  netCPCV: d3.format('.2f'),
  advCPCV: d3.format('.2f'),
  advNetCPCV: d3.format('.2f'),
  completionRate: d3.format('.2%'),
  lift: d3.format('.0%'),
  leafCount: (n) => n,
};

export const TRELLIS_TICK_FORMATS = {
  cumulativeBid: d3.format('.2f'),
  impressions: d3.format('.3s'),
  viewableImpressions: d3.format('.3s'),
  clicks: (n) => n,
  postClickConversions: (n) => n,
  postViewConversions: (n) => n,
  conversions: (n) => n,
  viewMeasuredImps: d3.format('.3s'),
  revenue: d3.format('.3s'),
  cost: d3.format('.3s'),
  advRevenue: d3.format('.3s'),
  advCost: d3.format('.3s'),
  cpm: d3.format('.3s'),
  cpa: d3.format('.3s'),
  cpc: d3.format('.3s'),
  ctr: d3.format('.0%'),
  conversionRate: d3.format('.3%'),
  advCostCPM: d3.format('.3s'),
  advCostCPA: d3.format('.3s'),
  advCostCPC: d3.format('.3s'),
  advRevenueCPM: d3.format('.3s'),
  advRevenueCPA: d3.format('.3s'),
  advRevenueCPC: d3.format('.3s'),
  ivrMeasured: d3.format('.0%'),
  videoCompletes: (n) => n,
  cpcv: d3.format('.3s'),
  netCPCV: d3.format('.3s'),
  advCPCV: d3.format('.3s'),
  advNetCPCV: d3.format('.3s'),
  completionRate: d3.format('.0%'),
  lift: d3.format('.0%'),
  leafCount: (n) => n,
};

export const METRIC_COLORS = {
  impressions: COLOR_RAMP_RED_GREEN,
  viewableImpressions: COLOR_RAMP_RED_GREEN,
  clicks: COLOR_RAMP_RED_GREEN,
  postClickConversions: COLOR_RAMP_RED_GREEN,
  postViewConversions: COLOR_RAMP_RED_GREEN,
  conversions: COLOR_RAMP_RED_GREEN,
  viewMeasuredImps: COLOR_RAMP_RED_GREEN,
  revenue: COLOR_RAMP_RED_GREEN,
  cost: COLOR_RAMP_GREEN_RED,
  advRevenue: COLOR_RAMP_RED_GREEN,
  advCost: COLOR_RAMP_GREEN_RED,
  cpm: COLOR_RAMP_GREEN_RED,
  cpa: COLOR_RAMP_GREEN_RED,
  cpc: COLOR_RAMP_GREEN_RED,
  cpcl: COLOR_RAMP_GREEN_RED,
  ctr: COLOR_RAMP_RED_GREEN,
  conversionRate: COLOR_RAMP_RED_GREEN,
  advCostCPM: COLOR_RAMP_GREEN_RED,
  advCostCPA: COLOR_RAMP_GREEN_RED,
  advCostCPC: COLOR_RAMP_GREEN_RED,
  advRevenueCPM: COLOR_RAMP_GREEN_RED,
  advRevenueCPA: COLOR_RAMP_GREEN_RED,
  advRevenueCPC: COLOR_RAMP_GREEN_RED,
  ivrMeasured: COLOR_RAMP_RED_GREEN,
  videoCompletes: COLOR_RAMP_RED_GREEN,
  cpcv: COLOR_RAMP_GREEN_RED,
  netCPCV: COLOR_RAMP_GREEN_RED,
  advCPCV: COLOR_RAMP_GREEN_RED,
  advNetCPCV: COLOR_RAMP_GREEN_RED,
  completionRate: COLOR_RAMP_RED_GREEN,
  lift: COLOR_RAMP_RED_GREEN,
  leafCount: COLOR_RAMP_RED_GREEN,
};

export const TRELLIS_CONFIG = {
  trader: ['impressions', 'advCost', 'advCostCPM'],
  client: ['impressions', 'advRevenue', 'advRevenueCPM'],
};

export const POPUP_CONFIG = {
  trader: ['cumulativeBid', 'impressions', 'advCost', 'advRevenue', 'advCostCPM', 'advRevenueCPM', 'clicks', 'ivrMeasured'],
  client: ['cumulativeBid', 'impressions', 'advRevenue', 'advRevenueCPM', 'clicks', 'ivrMeasured'],
};

export const LOCALIZE_METRIC_MAPPINGS = {
  trader: {
    cpa: 'advCostCPA',
    cpc: 'advCostCPC',
    cpcv: 'advNetCPCV',
    cpm: 'advCostCPM',
    ivrMeasured: 'ivrMeasured',
  },
  client: {
    cpa: 'advRevenueCPA',
    cpc: 'advRevenueCPC',
    cpcv: 'advCPCV',
    cpm: 'advRevenueCPM',
    ivrMeasured: 'ivrMeasured',
  },
};

export const GOALTYPE_MAPPINGS = {
  conversionRevenue: 'cpa',
  engagementScore: 'cpa',
};

export const RANKLIST_CONFIG = {
  topFeaturesKey: 'top10Lists',
  topFeatures: {
    topSegmentGroupNames: {
      label: 'Top Segment Group',
      childKey: 'segmentGroupName',
    },
    topSupplyTypes: {
      label: 'Top Supply Type',
      childKey: 'includedSupplyTypes',
    },
    topDevices: {
      label: 'Top Device Type',
      childKey: 'includedDeviceTypes',
    },
    topCreativeSizes: {
      label: 'Top Creative Sizes',
      childKey: 'includedSizes',
    },
    topBrowsers: {
      label: 'Top Browsers',
      childKey: 'includedBrowsers',
    },
    topLeaves: {
      label: 'Top 10 Leaves',
      childKey: 'leafName',
    },
  },
  sortbyKey: {
    impressions: 'byImps',
    revenue: 'byRev',
  },
};

export const ZOOM_CONFIG = {
  domain: [0.5, 0.8, 1, 2.5, 5],
};

export const COLOR_GRADIENT = ['#22ba46', '#fbbd0a', '#db2828'];

export const VIEW_OPTIONS = [{
  type: 'trader',
  label: 'Trader View',
}, {
  type: 'client',
  label: 'Client View',
}];

export const HEADER_METRICS = {
  trader: ['impressions', 'advCostCPM'],
  client: ['impressions', 'advRevenueCPM'],
};

export const COLOR_OPTIONS = {
  trader: ['advCostCPM'],
  client: ['advRevenueCPM'],
};
