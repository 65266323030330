import React, { useContext } from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import { rgb } from 'd3';
import { MetricsFormattingConstants } from 'constantsBase';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { KPIs } from 'containers/ABInsights/types';
import { ColorContext, ABColors, BudgetType, BudgetTypeOptions } from './ABReportViz';
import { CustomColorScaleBuilder, getDiffConfig } from './utils';

export type Datum = {
  a: KPIs
  b: KPIs
};

const getColorStyles = (hexColor: string, showPerfColors: boolean) => {
  const { r, g, b } = rgb(hexColor);
  return {
    backgroundColor: showPerfColors ? `rgba(${r},${g},${b},0.6)` : 'none',
    border: showPerfColors ? `1.5px solid ${hexColor}` : 'none',
  };
};

const DataRow = (
  data: Datum,
  budgetTypeConfig: BudgetType,
  selectedKPI: Metric,
  accessor: string,
  accessorFormatter: (a: string) => string,
  colors: ABColors,
  showPerfColors,
  colorScale: ReturnType<CustomColorScaleBuilder> = _.identity,
) => {
  const { value: budgetType } = budgetTypeConfig;
  const KPIFormat = selectedKPI.format;
  const { a, b } = data;
  const label = accessorFormatter ? accessorFormatter(data[accessor]) : data[accessor];
  const aPerf = _.get(a, selectedKPI.value);
  const bPerf = _.get(b, selectedKPI.value);

  const { greater, text } = getDiffConfig(aPerf, bPerf, selectedKPI.lowerIsBetter);
  return (
    <tr className="list-row" key={data[accessor]}>
      <td className="rank-value">
        {numeral(_.sumBy([a, b], (v) => _.get(v, budgetType, 0))).format(MetricsFormattingConstants.ROUNDED_NO_DECIMALS)}
      </td>
      <td className="name" title={label}>
        {label}
      </td>
      <td style={getColorStyles(colorScale(a), showPerfColors)}>
        {a ? numeral(aPerf).format(KPIFormat) : '--'}
      </td>
      <td style={getColorStyles(colorScale(b), showPerfColors)}>
        {b ? numeral(bPerf).format(KPIFormat) : '--'}
      </td>
      <td style={{ color: colors[greater] }}>{text}</td>
    </tr>
  );
};

type Props = {
  title: string
  accessor: string
  accessorOverrideLabel?: string
  accessorFormatter?: (a: string) => string
  data: Array<Datum>
  budgetType: BudgetType
  kpi: Metric
  colorScale?: any
  showPerfColors?: boolean
  budgetTypeOptions: BudgetTypeOptions,
};

export default ({
  title,
  accessor,
  accessorOverrideLabel,
  accessorFormatter,
  data,
  budgetType,
  kpi,
  colorScale,
  showPerfColors = true,
  budgetTypeOptions,
}: Props) => {
  const [colors] = useContext(ColorContext);
  return (
    <div className="top-list">
      <div className="list-header">
        <div className="title">{title}</div>
        <div className="sub-title">
          {kpi.text}
        </div>
      </div>
      <div className="list-body">
        <table>
          <thead>
            <tr>
              <td>Total {budgetTypeOptions[budgetType.value].abbreviation}</td>
              <td>{accessorOverrideLabel || accessor}</td>
              <td style={{ color: colors.a }}>A</td>
              <td style={{ color: colors.b }}>B</td>
              <td />
            </tr>
          </thead>
          <tbody>
            {_.map(data, (d) => DataRow(d, budgetType, kpi, accessor, accessorFormatter, colors, showPerfColors, colorScale))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
