/**
* Wrapper for semantic ui's List component
* @see {@link https://react.semantic-ui.com/elements/list#list-example-basic}
*/

import React from 'react';
import {
  List as SemanticList,
  ListItem,
  ListContent,
  ListDescription,
  ListHeader,
  ListIcon,
  ListList,
  ListProps,
} from 'semantic-ui-react';

/**
 * Wrapper around the Semantic UI <List>.
 */
const List = (props: ListProps) => <SemanticList {...props} />;

List.Item = ListItem;
List.Content = ListContent;
List.Description = ListDescription;
List.Header = ListHeader;
List.Icon = ListIcon;
List.Item = ListItem;
List.List = ListList;

export default List;
