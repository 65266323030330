import React from 'react';

type Props = {
  fill: string
};

export default ({ fill }: Props) => (
  <svg width="45" height="66" viewBox="0 0 45 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.125" y="0.125" width="44.75" height="65.75" rx="3.875" stroke="black" strokeWidth="0.25" />
    <circle cx="22.75" cy="2.84961" r="0.75" stroke="black" strokeWidth="0.25" />
    <circle cx="23" cy="62" r="2" stroke="black" strokeWidth="0.25" />
    <rect x="2.125" y="5.92578" width="40.25" height="52.25" fill={fill} stroke="black" strokeWidth="0.25" />
  </svg>
);
