import React, { useState, useEffect, useCallback } from 'react';
import * as d3 from 'd3';
import 'd3-selection-multi';
import { RankListDatum } from 'charts/HeliosDataViz//Helios/types';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { COLOR_RAMP_GREEN_RED, TEXT_COLOR } from 'charts/HeliosDataViz/constants';
import { COPILOT_COLORS } from 'globalStyles';
import { BAR_HEIGHT, BLOCK_HEIGHT, MARGINS, LABEL_STYLES, METRIC_P_STYLES, METRIC_S_STYLES } from './constants';
import { getColor, formatMetric, labelFormat } from './utils';

const { NEW_DESIGN_SYSTEM: { BLUES } } = COPILOT_COLORS;
const D3Container = ({ width, data, setShowNode }: RankListChartProps) => {
  const [drawLayer, setDrawLayer] = useState(null);

  const widthScale = d3.scaleLinear()
    .domain([0, d3.max(data, (d) => d.metricPrimary.value)])
    .range([0, width - (MARGINS.left + MARGINS.right)]);

  const colorScale = d3.scaleLinear()
    .domain([0, d3.max(data, (d) => (d.metricSecondary ? d.metricSecondary.value : 0))])
    .range([0, 1]);

  const drawChart = useCallback((myDrawLayer) => {
    const bars = myDrawLayer.selectAll('div.item')
      .data(data.sort((a, b) => b.metricPrimary.value - a.metricPrimary.value), (d) => d.name);

    bars.exit().remove();

    bars
      .transition()
      .duration(400)
      .ease(d3.easeCubicOut)
      .style('transform', (_d, i) => `translate(${MARGINS.left}px, ${i * BLOCK_HEIGHT + MARGINS.top}px)`);

    bars.select('.label')
      .attr('title', (d) => labelFormat(d.key, d.name))
      .text((d) => labelFormat(d.key, d.name));

    bars.select('.metric-primary')
      .text((d) => formatMetric(d.metricPrimary));

    bars.select('.metric-secondary')
      .text((d) => (d.metricSecondary ? formatMetric(d.metricSecondary) : ''));

    bars.select('.bar')
      .transition()
      .delay(100)
      .duration(400)
      .ease(d3.easeCubicOut)
      .style('width', (d) => `${widthScale(d.metricPrimary.value)}px`);

    const barsEnter = bars
      .enter()
      .append('div')
      .attr('class', 'item')
      .style('position', 'absolute')
      .style('width', `${width - (MARGINS.left + MARGINS.right)}px`)
      .style('height', `${BLOCK_HEIGHT}px`)
      .style('cursor', (d) => (d.key === 'leafName' ? 'pointer' : 'default'))
      .style('transform', (_d, i) => `translate(${MARGINS.left}px, ${i * BLOCK_HEIGHT + MARGINS.top}px)`)
      .on('click', (d) => {
        if (d.key === 'leafName') {
          setShowNode(`leaf ${d.name}`);
        }
      });

    barsEnter
      .append('div')
      .attr('class', 'bar')
      .style('width', '0')
      .style('margin-top', '2px')
      .style('height', `${BAR_HEIGHT}px`)
      .style('background', (d) => (d.metricSecondary
        ? getColor(d.metricSecondary.value, colorScale, COLOR_RAMP_GREEN_RED)
        : BLUES.B500_WAVE))
      .transition()
      .delay(100)
      .duration(400)
      .ease(d3.easeCubicOut)
      .style('width', (d) => `${widthScale(d.metricPrimary.value)}px`);

    barsEnter
      .append('div')
      .attr('class', 'label')
      .styles(LABEL_STYLES)
      .attr('title', (d) => labelFormat(d.key, d.name))
      .text((d) => labelFormat(d.key, d.name))
      .on('mouseenter', function highlight(d) {
        if (d.key === 'leafName') {
          d3.select(this).style('color', BLUES.B500_WAVE);
        }
      })
      .on('mouseleave', function unhighlight(d) {
        if (d.key === 'leafName') {
          d3.select(this).style('color', TEXT_COLOR);
        }
      });

    barsEnter
      .append('div')
      .attr('class', 'metric-primary')
      .styles(METRIC_P_STYLES)
      .text((d) => formatMetric(d.metricPrimary));

    barsEnter
      .append('div')
      .attr('class', 'metric-secondary')
      .styles(METRIC_S_STYLES)
      .text((d) => (d.metricSecondary ? formatMetric(d.metricSecondary) : ''));
  }, [colorScale, widthScale, data, width, setShowNode]);

  useMount(() => {
    const ch = d3.select('#toplist-chart').style('width', `${width}px`);

    const myDrawLayer = ch.append('div')
      .attr('class', 'drawLayer')
      .style('min-height', '480px')
      .style('position', 'relative');
    setDrawLayer(myDrawLayer);
    drawChart(myDrawLayer);
  });

  useEffect(() => {
    if (drawLayer) {
      drawChart(drawLayer);
    }
  }, [data, drawLayer, drawChart]);

  return (<div id="toplist-chart" />);
};

type RankListChartProps = {
  width: number,
  data: Array<RankListDatum>,
  setShowNode: Function,
};

export const RankListChart = ({ width, data, setShowNode }: RankListChartProps) => (
  <D3Container
    width={width}
    data={data}
    setShowNode={setShowNode}
  />
);
