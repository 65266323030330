import React, { useContext } from 'react';
import _ from 'lodash';
import { KPIOptions as KPIOptionsType, KPIsByVariantWithVariant, VariantToFlightExtId, FlightExtIdToAlias, AugmentedStatBoxData } from 'containers/ABInsights/types';
import SlideTitle from 'charts/InsightsViz/Components/SlideTitle';
import VizHeader from 'charts/InsightsViz/Components/VizHeader';
import MessageContainer from 'charts/Components/MessageContainer';
import { EXPORT_PREFIX, SlideIcons, VizId } from '../constants';
import { ABColorContext, BudgetTypeOptions, ColorContext } from '../ABReportViz';
import PrimaryKPICompare from './PrimaryKPICompare';
import SecondaryKPICompare from './SecondaryKPICompare';
import DeliveryCompare from './DeliveryCompare';
import ABLegend from '../Components/ABLegend';
import InsightsPanel from '../Components/InsightsPanel';
import { getInsightsByVizId } from '../utils';
import { renderStatbox } from '../StatBoxes/utils';

const TOTAL_WIDTH = 1144;
type Props = {
  vizId: string
  KPIOptions: KPIOptionsType
  variantToFlightExtId: VariantToFlightExtId
  flightExtIdToAlias: FlightExtIdToAlias
  kpisByVariantWithVariant: KPIsByVariantWithVariant
  tooltipContent: string
  showPlaceholder: boolean
  budgetTypeOptions: BudgetTypeOptions
  statBoxData: AugmentedStatBoxData
};

const Overview = ({
  vizId,
  KPIOptions,
  kpisByVariantWithVariant,
  flightExtIdToAlias,
  variantToFlightExtId,
  tooltipContent,
  showPlaceholder,
  budgetTypeOptions,
  statBoxData,
}: Props) => {
  const primaryKPI = _.first(KPIOptions);
  const secondaryKPIs = _.tail(KPIOptions);
  const sortedSecondaryKPIs = _.orderBy(secondaryKPIs, (first) => {
    const kpiExistsForBothAAndB = _.get(kpisByVariantWithVariant, ['a', first.value]) && _.get(kpisByVariantWithVariant, ['b', first.value]);
    return (kpiExistsForBothAAndB) ? 1 : -1;
  }, ['desc']);
  // set up column widths
  const secondaryKPIWidth = (_.size(sortedSecondaryKPIs) < 4) ? (TOTAL_WIDTH / 3) : (TOTAL_WIDTH / _.size(sortedSecondaryKPIs));
  const [colors] = useContext<ABColorContext>(ColorContext);
  return (
    <>
      {
        vizId.includes(EXPORT_PREFIX) && (
          <SlideTitle
            section="overview"
            icon={SlideIcons.overview}
          />
        )
      }
      {
        showPlaceholder
          ? (
            <MessageContainer
              message="There is currently not enough data available for comparison. Only one of your flights generated spend within the past 60 days."
              additionalClasses={['ab-overview', 'ab-viz-placeholder']}
            />
          )
          : (
            <div className="main-visualization">
              <VizHeader
                title="Performance Comparison"
                tooltipContent={tooltipContent}
              />
              <ABLegend
                colors={colors}
                flightExtIdToAlias={flightExtIdToAlias}
                variantToFlightExtId={variantToFlightExtId}
                KPIsByVariantWithVariant={kpisByVariantWithVariant}
              />
              <div className="grid-container">
                <PrimaryKPICompare
                  kpisByVariantWithVariant={kpisByVariantWithVariant}
                  colors={colors}
                  primaryKPI={primaryKPI}
                />
                <DeliveryCompare
                  kpisByVariantWithVariant={kpisByVariantWithVariant}
                  budgetTypeOptions={budgetTypeOptions}
                />
                <div className="secondary-KPIs">
                  <p className="label">Secondary Goals</p>
                  {
                    _.map(sortedSecondaryKPIs, (k) => (
                      <div key={k.text}>
                        <SecondaryKPICompare
                          kpisByVariantWithVariant={kpisByVariantWithVariant}
                          colors={colors}
                          KPI={k}
                          width={secondaryKPIWidth}
                          vizId={vizId}
                        />
                      </div>
                    ))
                  }
                  {(_.size(secondaryKPIs) === 0) && (
                  <p className="placeholder">No Secondary Goals selected</p>
                  )}
                </div>
              </div>
            </div>
          )
}
      {!showPlaceholder && renderStatbox(primaryKPI.value) && _.get(statBoxData, ['statBoxData', 'Overview', 'overallPerformanceWinner', primaryKPI.value]) && (
        <InsightsPanel
          abColors={colors}
          insights={getInsightsByVizId(VizId.overview, statBoxData, primaryKPI)}
        />
      )}
    </>
  );
};

export default Overview;
