import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { notEnoughData } from 'charts/utils';
import { GlobalState } from 'reducers';
import BudgetOptimizationViz from './BudgetOptimizationViz';
import { transformData } from './transforms';
import { DataVizData } from './types';
import '../styles/budget-optimization.sass';
import '../styles/shared-styles.sass';

const NoDataDiv = () => <div id="charts-empty-render">There is no data available.</div>;

const BOV = () => {
  const {
    login: { env },
    strategyAnalytics: {
      metadata: {
        strategy,
        strategyGoalAnalyticsMetadata,
        stratData,
        budgetOptData,
        childData,
      },
    },
  } = useSelector<GlobalState>((state) => state) as any;

  if (!strategy || !stratData || !budgetOptData || !childData) {
    return <NoDataDiv />;
  }

  const dataVizData = {
    stratData: { ...stratData, metadata: strategyGoalAnalyticsMetadata },
    childData: { ...childData, metadata: strategyGoalAnalyticsMetadata },
    budgetOptData,
  } as DataVizData;
  const transformedData = transformData(dataVizData, strategy);
  const {
    performance: cumulativePerformanceData,
    pacing: pacingData,
    cumulativePacingChildren: cumulativePacingChildrenData,
  } = transformedData;

  if (notEnoughData(transformedData.metaData.flightAttachmentTime)) {
    return <div id="charts-empty-render">Budget optimization data begins populating after 3 days of activation. Please check back later.</div>;
  }

  // If there is important data missing, return the "no data available" message
  if (_.some([cumulativePerformanceData, pacingData, cumulativePacingChildrenData], _.isEmpty)) {
    return <NoDataDiv />;
  }

  return <BudgetOptimizationViz data={transformedData} strategy={strategy} env={env} stratData={dataVizData.stratData} />;
};

export default BOV;
