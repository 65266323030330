/**
 * Wrapper for semantic Dropdown
 */
import React from 'react';
import {
  Dropdown as SemanticDropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
  DropdownProps,
} from 'semantic-ui-react';

/**
 * Wrapper around the Semantic UI Dropdown.
 */
const Dropdown = (props: DropdownProps) => <SemanticDropdown {...props} />;

Dropdown.Divider = DropdownDivider;
Dropdown.Header = DropdownHeader;
Dropdown.Item = DropdownItem;
Dropdown.Menu = DropdownMenu;

export default Dropdown;
