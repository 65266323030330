import type { JSX } from '@wppopen/components-library/components';
import { StyleReactProps } from '@wppopen/components-library-react/lib/react-component-lib/interfaces';
import _ from 'lodash';
import React, { useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { WppInlineMessage, WppInput, WppLabel } from 'buildingBlocks';
import style from './styles';

const { container, titleStyle, input, labelContainer, smallInputLabelBorderRadius, labelHover, labelFocus, labelError } = style;

interface ValidatedCustomWppInputProps extends Omit<JSX.WppInput, 'value'>, Omit<React.HTMLAttributes<HTMLWppInputElement>, 'style'>, StyleReactProps, React.RefAttributes<HTMLWppInputElement> {
  title?: string
  label?: string
  value?: string | number
  skipIsDirtyCheck?: boolean
  inputIcon?: React.ReactElement<any>
  field?: ControllerRenderProps<any, any>
  fieldState?: ControllerFieldState
  autocomplete?: string
}

const ValidatedCustomWppInput = ({ title, label, field, fieldState, skipIsDirtyCheck, inputIcon, ...rest }: ValidatedCustomWppInputProps) => {
  const [hover, setHover] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const smallInputSize = _.get(rest, 'size') === 's';
  const dirty = skipIsDirtyCheck ? fieldState?.error : (fieldState?.isDirty && fieldState?.error);
  const showError = (!_.isNil(field) && !_.isNil(fieldState) && dirty) && fieldState?.error.message;
  const value = rest.value ?? (field?.value ?? '');

  const labelStyle = {
    ...labelContainer,
    ...(smallInputSize && smallInputLabelBorderRadius),
    ...(hover && labelHover),
    ...(focus && labelFocus),
    ...(showError && labelError),
  };

  return (
    <div>
      {title && <WppLabel htmlFor={field.name ?? title} style={titleStyle} config={{ text: title }} />}
      <div style={container}>
        <WppInput
          style={input}
          onWppFocus={() => setFocus(true)}
          onWppBlur={() => setFocus(false)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          value={value}
          className={`${label ? 'custom-wpp-input-with-label' : ''}${showError ? ' validated-input-error' : ''}`}
          {...rest}
        >
          {inputIcon && inputIcon}
        </WppInput>
        {label && (
          <div
            style={labelStyle}
            className={showError ? 'validated-input-error' : ''}
          >
            {label}
          </div>
        )}
      </div>
      {showError && (
        <WppInlineMessage
          className="validated-error-msg"
          size="s"
          message={fieldState.error.message}
          type="error"
        />
      )}
    </div>
  );
};

export default ValidatedCustomWppInput;
