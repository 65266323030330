import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const headerContainer: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const deletePanel: CSSProperties = {
  display: 'flex',
  gap: SPACING[12],
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const actionButton: CSSProperties = {
  display: 'flex',
  height: '100%',
};
