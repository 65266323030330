import React from 'react';
import { dimmerPage, dimmerElement, dimmer, childrenContainer } from './styles';

type Props = {
  /** The contents of the page. */
  children?: React.ReactNode
  // If the Dimmer is active
  active?: boolean
  // Show dimmer on whole page
  page?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  inverted?: boolean
};
// Not in use but can be configured to replace existing Semantic UI dimmer once all pages will be migrated to Wpp Open Components.
const WppDimmer: React.FC<Props> = (props: Props): React.ReactElement => {
  if (!props.active) {
    return <></>;
  }

  return (
    <div style={props.page ? dimmerPage : dimmerElement}>
      <div style={dimmer} />
      <div style={childrenContainer}>{props.children}</div>
    </div>
  );
};

export default WppDimmer;
