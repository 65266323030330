import { METRIC_FORMATS } from './constants';

export const getColor = (value, scale, colors) => colors[Math.floor(scale(value) * (colors.length - 1))];

export const formatMetric = (metric) => {
  const formats = METRIC_FORMATS[metric.key];
  const correctKey = Object.keys(formats).reduce((key, prevKey) => {
    if (key === 'default' || metric.value < parseInt(key, 10)) {
      return key;
    }
    return prevKey;
  });

  return formats[correctKey](metric.value);
};

export const labelFormat = (key: string, name: string) => {
  const prefix = (key === 'leafName') ? 'Leaf ' : '';
  if (Array.isArray(name)) {
    return `${prefix}${name.join(', ')}`;
  }
  return `${prefix}${name}`;
};
