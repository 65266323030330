import _ from 'lodash';
import numeral from 'numeral';
import { MetricsFormattingConstants } from 'constantsBase';
import { CreativeSizeStatBoxDataTransformed, DeviceStatBoxDataTransformed, DomainStatBoxDataTransformed, GeoStatBoxDataTransformed, OverviewStatBoxDataTransformed } from 'containers/ABInsights/types';
import { KPIS_TO_SUCCESS_METRICS, VizId } from '../constants';
import { ABInsightsSingleConfig, ABSummaryInsightSingleConfig, InsightSingleConfig } from '../Components/InsightsPanel';

const { PERCENTAGE_NO_DECIMALS, NO_DECIMALS } = MetricsFormattingConstants;

const betterPerformanceDescription = (data, feature: string) => {
  if (_.isNil(data.topPerformingVariant)) {
    return `Neither A nor B performed better across ${feature}`;
  }
  return `${_.upperCase(data.topPerformingVariant)}: ${data.topPerformingAlias} had a ${data.comparisonText} ${data.kpiText} than ${_.upperCase(data.bottomPerformingVariant)}: ${data.bottomPerformingAlias} in ${numeral(data.countFeaturesWinnerPerformedBetter).format(NO_DECIMALS)} of ${numeral(data.totalFeatureCount).format(NO_DECIMALS)} ${feature}`;
};

type StatBoxesConfig = {
  [VizId.overview]: [ABSummaryInsightSingleConfig]
  [VizId.domain]: [ABInsightsSingleConfig, InsightSingleConfig]
  [VizId.geography]: [ABInsightsSingleConfig, InsightSingleConfig]
  [VizId.device]: [ABInsightsSingleConfig, InsightSingleConfig]
  [VizId.creativeSize]: [ABInsightsSingleConfig, InsightSingleConfig]
};
export const statBoxesConfig: StatBoxesConfig = {
  [VizId.overview]: [
    {
      winnerText: (data: OverviewStatBoxDataTransformed) => `${_.upperCase(data.winnerVariant)}: ${data.winnerAlias}`,
      winnerVariant: (data: OverviewStatBoxDataTransformed) => data.winnerVariant,
      loserText: (data: OverviewStatBoxDataTransformed) => `${_.upperCase(data.loserVariant)}: ${data.loserAlias}`,
      loserVariant: (data: OverviewStatBoxDataTransformed) => data.loserVariant,
      perfDiff: (data: OverviewStatBoxDataTransformed) => `${numeral(data.diffRatio).format(PERCENTAGE_NO_DECIMALS)} ${data.comparisonText} ${data.primaryKPIText}`,
      primaryKPIText: (data: OverviewStatBoxDataTransformed) => data.primaryKPIText,
      projectedSuccessMetrics: (data: OverviewStatBoxDataTransformed) => `${numeral(data.projectedSuccessMetrics).format(NO_DECIMALS)} ${_.get(KPIS_TO_SUCCESS_METRICS, data.primaryKPIValue, 'N/A')}`,
      variant: 'overview-summary',
    },
  ],
  [VizId.domain]: [
    {
      title: () => 'Better Performance',
      statistics: [
        {
          statisticValue: (data: DomainStatBoxDataTransformed) => numeral(data.countFeaturesAPerformedBetter).format(NO_DECIMALS),
          statisticSuffix: () => 'Domains',
          statisticWinner: () => 'a',
          statisticWinnerText: () => 'A performed better',
        },
        {
          statisticValue: (data: DomainStatBoxDataTransformed) => numeral(data.countFeaturesBPerformedBetter).format(NO_DECIMALS),
          statisticSuffix: () => 'Domains',
          statisticWinner: () => 'b',
          statisticWinnerText: () => 'B performed better',
        },
      ],
      description: (data: DomainStatBoxDataTransformed) => betterPerformanceDescription(data, 'Domains'),
      variant: 'ab',
    },
    {
      title: (data: DomainStatBoxDataTransformed) => `Majority ${_.upperFirst(data.budgetTypeText)}`,
      statisticValue: (data: DomainStatBoxDataTransformed) => `${numeral(data.majorityDeliveryShareFeatureCount).format(NO_DECIMALS)} / ${numeral(data.totalFeatureCount).format(NO_DECIMALS)}`,
      statisticSuffix: () => 'Domains',
      description: (data: DomainStatBoxDataTransformed) => {
        const firstSentence = `${numeral(data.majorityDeliveryShareFeatureCount).format(NO_DECIMALS)} out of ${numeral(data.totalFeatureCount).format(NO_DECIMALS)} domains accounted for ${numeral(data.majorityDeliveryShare).format(PERCENTAGE_NO_DECIMALS)} ${data.budgetTypeText}.`;
        const secondSentence = `${_.upperCase(data.winnerVariantMajorityFeatures)} had a ${data.comparisonText} ${data.kpiText} than ${_.upperCase(data.loserVariantMajorityFeatures)} in the majority of these domains.`;
        return `${firstSentence} ${data.winnerVariantMajorityFeatures ? secondSentence : ''}`;
      },
      variant: 'standard',
    },
  ],
  [VizId.geography]: [
    {
      title: () => 'Better Performance',
      statistics: [
        {
          statisticValue: (data: GeoStatBoxDataTransformed) => numeral(data.countFeaturesAPerformedBetter).format(NO_DECIMALS),
          statisticSuffix: () => 'Regions',
          statisticWinner: () => 'a',
          statisticWinnerText: () => 'A performed better',
        },
        {
          statisticValue: (data: GeoStatBoxDataTransformed) => numeral(data.countFeaturesBPerformedBetter).format(NO_DECIMALS),
          statisticSuffix: () => 'Regions',
          statisticWinner: () => 'b',
          statisticWinnerText: () => 'B performed better',
        },
      ],
      description: (data: GeoStatBoxDataTransformed) => betterPerformanceDescription(data, 'Regions'),
      variant: 'ab',
    },
    {
      title: (data: GeoStatBoxDataTransformed) => `Majority ${_.upperFirst(data.budgetTypeText)}`,
      statisticValue: (data: GeoStatBoxDataTransformed) => `${numeral(data.majorityDeliveryShareFeatureCount).format(NO_DECIMALS)} / ${numeral(data.totalFeatureCount).format(NO_DECIMALS)}`,
      statisticSuffix: () => 'Regions',
      description: (data: GeoStatBoxDataTransformed) => {
        const firstSentence = `${numeral(data.majorityDeliveryShareFeatureCount).format(NO_DECIMALS)} out of ${numeral(data.totalFeatureCount).format(NO_DECIMALS)} regions accounted for ${numeral(data.majorityDeliveryShare).format(PERCENTAGE_NO_DECIMALS)} ${data.budgetTypeText}. `;
        const secondSentence = `${_.upperCase(data.winnerVariantMajorityFeatures)} had a ${data.comparisonText} ${data.kpiText} than ${_.upperCase(data.loserVariantMajorityFeatures)} in the majority of these regions.`;
        return `${firstSentence} ${data.winnerVariantMajorityFeatures ? secondSentence : ''}`;
      },
      variant: 'standard',
    },
  ],
  [VizId.device]: [
    {
      title: () => 'Better Performance',
      statistics: [
        {
          statisticValue: (data: DeviceStatBoxDataTransformed) => numeral(data.countFeaturesAPerformedBetter).format(NO_DECIMALS),
          statisticSuffix: () => 'Devices',
          statisticWinner: () => 'a',
          statisticWinnerText: () => 'A performed better',
        },
        {
          statisticValue: (data: DeviceStatBoxDataTransformed) => numeral(data.countFeaturesBPerformedBetter).format(NO_DECIMALS),
          statisticSuffix: () => 'Devices',
          statisticWinner: () => 'b',
          statisticWinnerText: () => 'B performed better',
        },
      ],
      description: (data: DeviceStatBoxDataTransformed) => betterPerformanceDescription(data, 'Devices'),
      variant: 'ab',
    },
    {
      title: (data: DeviceStatBoxDataTransformed) => `Highest ${_.upperFirst(data.budgetTypeText)}`,
      statisticValue: (data: DeviceStatBoxDataTransformed) => data.highestDeliveringFeatures,
      statisticSuffix: (data: DeviceStatBoxDataTransformed) => `${numeral(data.majorityDeliveryShare).format(PERCENTAGE_NO_DECIMALS)} ${data.budgetTypeText}`,
      description: (data: DeviceStatBoxDataTransformed) => {
        const firstSentence = `${data.highestDeliveringFeatures} accounted for ${numeral(data.majorityDeliveryShare).format(PERCENTAGE_NO_DECIMALS)} ${data.budgetTypeText}. `;
        const secondSentence = `${_.upperCase(data.winnerVariantMajorityFeatures)} had a ${data.comparisonText} ${data.kpiText} than ${_.upperCase(data.loserVariantMajorityFeatures)} on ${data.highestDeliveringFeatures} devices.`;
        return `${firstSentence} ${data.winnerVariantMajorityFeatures ? secondSentence : ''}`;
      },
      variant: 'standard',
    },
  ],
  [VizId.creativeSize]: [
    {
      title: () => 'Better Performance',
      statistics: [
        {
          statisticValue: (data: CreativeSizeStatBoxDataTransformed) => numeral(data.countFeaturesAPerformedBetter).format(NO_DECIMALS),
          statisticSuffix: () => 'Creative Sizes',
          statisticWinner: () => 'a',
          statisticWinnerText: () => 'A performed better',
        },
        {
          statisticValue: (data: CreativeSizeStatBoxDataTransformed) => numeral(data.countFeaturesBPerformedBetter).format(NO_DECIMALS),
          statisticSuffix: () => 'Creative Sizes',
          statisticWinner: () => 'b',
          statisticWinnerText: () => 'B performed better',
        },
      ],
      description: (data: CreativeSizeStatBoxDataTransformed) => betterPerformanceDescription(data, 'Creative sizes'),
      variant: 'ab',
    },
    {
      title: (data: CreativeSizeStatBoxDataTransformed) => `Highest ${_.upperFirst(data.budgetTypeText)}`,
      statisticValue: (data: CreativeSizeStatBoxDataTransformed) => data.highestDeliveringFeatures,
      statisticSuffix: (data: CreativeSizeStatBoxDataTransformed) => `${numeral(data.majorityDeliveryShare).format(PERCENTAGE_NO_DECIMALS)} ${data.budgetTypeText}`,
      description: (data: CreativeSizeStatBoxDataTransformed) => {
        const firstSentence = `${data.highestDeliveringFeatures} accounted for ${numeral(data.majorityDeliveryShare).format(PERCENTAGE_NO_DECIMALS)} of ${data.budgetTypeText}. `;
        const secondSentence = `${_.upperCase(data.winnerVariantMajorityFeatures)} had a ${data.comparisonText} ${data.kpiText} than ${_.upperCase(data.loserVariantMajorityFeatures)} on ${data.highestDeliveringFeatures} creative sizes.`;
        return `${firstSentence} ${data.winnerVariantMajorityFeatures ? secondSentence : ''}`;
      },
      variant: 'standard',
    },
  ],
};
