/* eslint-disable camelcase */
import { Moment } from 'moment';
import { BaseType, Selection, ScaleTime } from 'd3';
import { StrategyGoalDB, Strategy, EnvironmentVariables } from 'utils/types';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { AggregatedAnalyticsData, ParentSettings, StrategyGoalAnalyticsData } from 'containers/StrategyAnalytics/types';
import { PossibleMoment } from 'utils/dateTime';

export type StackingDatum = {
  [childName: string]: number;
} & {
  date: Moment
  total: number
};

export type BudgetType = 'amount' | 'imps';

export type PacingDatum = {
  date: Moment
  revenue: number
  clicks: number
  completes: number
} | {
  date: Moment
  imps: number
  clicks: number
  completes: number
} | {
  date: Moment
  revenue: number
  revenueInCurrency: number
};

type MetaDatum = {
  active: boolean
  budgetAmount: null
  budgetImps: null
  budgetType: BudgetType
  currencyCode: string
  dailyBudgetAmount: number | null
  dailyBudgetImps: number | null
  endDate: string
  extType: number
  name: string
  startDate: string
};

export type BaseAnalyticsDatum = {
  revenue: number
  clicks: number
  completes: number
  completion_rate: number
  conversion_rate: number
  conversions: number
  cpa: number
  cpc: number
  cpm: number
  cpcv: number
  ctr: number
  date: string
  imps: number
  ivrMeasured: number
  vcpm: number
  viewMeasuredImps: number
  viewableImps: number
};

export type ChildAnalyticsDatum = BaseAnalyticsDatum & {
  parentExtId: string
  childExtId: string
};

export type ParentDatum = BaseAnalyticsDatum;

export type BasePacingBoundsDatum = {
  lowerBound: number
  upperBound: number
};

export type ChildData = {
  [id: string]: {
    data: Array<StrategyGoalAnalyticsData>
    cumData: Array<StrategyGoalAnalyticsData>
  }
};

export enum PacingState {
  active = 'Active',
  paused = 'Paused',
  final = 'Final',
}

export type ParentData = {
  data: Array<StrategyGoalAnalyticsData>
  cumData: Array<StrategyGoalAnalyticsData>
  pacingBounds?: Array<PacingBoundsDatum>
};

export type ChildSettings = {
  [key: string]: MetaDatum
};

export type IntelligentChildObjectMap = {
  [ICOExtId: string]: string
};

export type Data = {
  hierarchy: {
    childExtIdToSettings: ChildSettings
    parentSettings: ParentSettings
    parentExtId?: string
  }
  flightAttachmentTime: PossibleMoment
  childData: ChildData
  parentData: ParentData
  origToClone: IntelligentChildObjectMap
  strategyGoals?: Array<StrategyGoalDB>
  primaryStrategyGoal?: string
};

export type RawData = { [key in keyof Data]: any };

type DeliveryCapDatum = {
  budgetAllocation: number
  childExtId: string
  clientDeliveryCapRevenue: number
  date: string
  deliveryCapacityConfidence: boolean
  deliveryToBudgetRatio: number
  dspDeliveryCapRevenue: number
  parentExtId: string
  stratId: number
};

export type BudgetOptDataPacingDatum = {
  cumPacing: number
  date: string
  estimatedKpiCumulative: number
  estimatedUplift: number
  lowerBound: number
  upperBound: number
};

export type TopDeliveringChild = {
  childExtId: string
  childName: string
  averageDailyDelivery: number
  averageDailyBudgetAllocation: number
};

export type NotableBudget = {
  childExtId: string
  childName: string
  budgetChange: number
};

export type BudgetOptData = {
  deliveryCapData: Array<DeliveryCapDatum>
  pacingData: Array<BudgetOptDataPacingDatum>
  negativeBudgetDate: string
  dailyParentBudgetInflationRatio: number
  currentPacing: number
  pacingState: PacingState
  eventBudgetDelivered: number
  budgetAllocationData: {
    topDeliveringChildren: Array<TopDeliveringChild>
    notableBudgetGrowth: NotableBudget
    notableBudgetReduction: NotableBudget
    childDeliveryCapacityCount: number
  }
};

export type StackingDataConfig = {
  keys: Array<string>
  dataWithICOSeparated: Array<StackingDatum>
  dataWithICOMerged: Array<StackingDatum>
};

export type PerformanceDatum = (Omit<BaseAnalyticsDatum, 'date'> | Omit<StrategyGoalAnalyticsData, 'date'>) & { date: Moment } & { [estimatedKPIKey: string]: number };
export type PacingBoundsDatum = BasePacingBoundsDatum & { date: Moment };
export type CumulativeChildPacing = {
  [id: string]: {
    cumData: Array<PacingDatum>
    totalAccumulatedBudget: number
  }
};

export type PerformanceData = Array<PerformanceDatum>;

export type TransformedData = {
  pacing: Array<PacingDatum>
  totalAccumulatedBudget: number
  cumulativePacingChildren: CumulativeChildPacing
  performance: PerformanceData
  stacked: StackingDataConfig
  metaData: {
    dateRange: Array<Moment>
    budgetType: BudgetType
    strategyGoalType: string
    estimatedKPIKey: string
    yScaleUnits: {
      pacing: string
      performance: string | null
    }
    childExtIdToSettings: ChildSettings
    flightAttachmentTime: Moment
    dailyParentBudgetInflationRatio: number
    origToClone: IntelligentChildObjectMap
    cloneToOrig: IntelligentChildObjectMap
    parentExtType?: number
    goalTypeKey?: string
  }
  pacingBounds: Array<PacingBoundsDatum>
  headerMetrics: HeaderMetrics
  negativeBudgetDate: Date
};

export type HeaderMetrics = {
  metrics: {
    revenue: number
    impressions: number
    currentPacing: number | string
    [strategyGoalType: string]: number | string
    margin: number
  }
  metricsConfig: {
    revenue: Metric
    impressions: Metric
    currentPacing: Metric
    [strategyGoalType: string]: Metric
    margin: Metric
  }
};

export type BudgetOptimizationVizProps = {
  data: TransformedData
  strategy: Strategy
  env: EnvironmentVariables
  stratData: AggregatedAnalyticsData
};

/*
d3 types
*/
export type GenericSelection = Selection<BaseType, unknown, BaseType, unknown>;

export type XScale = ScaleTime<number, number>;

export type DataVizData = {
  stratData: AggregatedAnalyticsData
  budgetOptData: BudgetOptData
  childData: AggregatedAnalyticsData
  currentPacing: number
};
