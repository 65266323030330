import React, { useState } from 'react';
import { Button, Icon, Popup } from 'buildingBlocks';
import { ABInsightsExportConfig, exportPPT, InsightsExportConfig } from '../utils';

type Props = {
  containerType: 'header' | 'footer'
  missingSlides: boolean
  loading: boolean
  tooltipContent?: string
} & (InsightsExportConfig | ABInsightsExportConfig);

const ExportButton = ({
  containerType,
  loading,
  tooltipContent,
  missingSlides,
  preExportCallbacks,
  postExportCallbacks = [],
  chartIdsToExport,
  speakerNotes,
  ...rest }: Props) => {
  const [pptExportLoading, setPptExportLoading] = useState(false);
  const doExportPPT = async () => {
    setPptExportLoading(true);
    try {
      const exportConfigBase = { preExportCallbacks, postExportCallbacks, chartIdsToExport, speakerNotes };
      await exportPPT({ reportType: rest.reportType, ...exportConfigBase, ...rest });
    } catch (error) {
      console.log(error);
    } finally {
      setPptExportLoading(false);
    }
  };

  return (
    <div className={`export-button-container ${containerType}`}>
      <Button
        className="export-button"
        icon
        labelPosition="left"
        onClick={doExportPPT}
        loading={pptExportLoading || loading}
        disabled={pptExportLoading || loading}
      >
        <Icon name="file powerpoint" />
        Export as PowerPoint
      </Button>
      {tooltipContent && (
        <Popup
          hoverable
          wide="very"
          trigger={<span><Icon name="question circle outline" /></span>}
        >
          <p>{tooltipContent}</p>
        </Popup>
      )}
      {!loading && missingSlides
        && (
          <p>
            <strong>Missing Slides: </strong>
            Some slides failed to load and will not be included in this export.
          </p>
        )}
    </div>
  );
};

export default ExportButton;
