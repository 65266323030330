import React, { useMemo } from 'react';
import { HeliosTreeChart } from './HeliosTreeChart';
import { noData, generateScales, isDataSansMetrics } from './utils';
import { HeliosTreeProps } from './types';

export const HeliosTree = ({ data, zoomValue, onSvgClick, doneLoading,
  setZoom, userSelectedTheme, showNode, setShowNode }: HeliosTreeProps) => {
  const isNoData = noData(data);
  const dataHasNoMetrics = isDataSansMetrics(data);

  if (isNoData || dataHasNoMetrics) {
    doneLoading(true);
  }

  const scales = useMemo(() => generateScales(data.domains), [data.domains]);

  return (
    <div className="helios-tree">
      {(isNoData || dataHasNoMetrics)
        ? (
          <div className="helios-nodata">
            No Data Available
          </div>
        )
        : (
          <HeliosTreeChart
            scales={scales}
            data={data}
            colorKPI={data.attributes.color}
            sizeKPI={data.attributes.size}
            strokeKPI={data.attributes.stroke}
            popupMetrics={data.popupMetrics}
            width={4000}
            height={720}
            zoomValue={zoomValue}
            onSvgClick={onSvgClick}
            doneLoading={doneLoading}
            setZoom={setZoom}
            userSelectedTheme={userSelectedTheme}
            showNode={showNode}
            setShowNode={setShowNode}
          />
        )}
    </div>
  );
};

export default HeliosTree;
