import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const { WPP } = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

export const styles: { [key: string]: CSSProperties } = {
  percentageInputLabel: {
    backgroundColor: WPP.grey200,
    padding: '8px 2px',
    borderRadius: '0 5px 5px 0',
    color: WPP.grey1000,
    marginRight: SPACING[2],
    borderLeft: `1px solid ${WPP.grey500}`,
  },
  percentageInputLableContainer: {
    display: 'flex',
    backgroundColor: WPP.grey200,
  },
  percentageInputLableText: {
    marginRight: '3px',
  },
};

export default styles;
