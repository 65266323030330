import React from 'react';
import { WppInput } from 'buildingBlocks';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import ActionsPanel from './DeleteRowPanel';
import { headerContainer } from './style';

type Props = {
  withSearch?: boolean
  withDeletePanel?: boolean
  withEditPanel?: boolean
  searchPlaceholder?: string
  searchValue?: string
  handleDeleteRowAction?: React.MouseEventHandler<HTMLWppActionButtonElement>
  handleEditRowAction?: React.MouseEventHandler<HTMLWppActionButtonElement>
  onSearchChange?: (event: WppInputCustomEvent<InputChangeEventDetail>) => void
  selectedRowCount?: number
  handleDeselectAll?: () => void
};

const AgGridHeader: React.FC<Props> = ({
  withSearch,
  handleDeleteRowAction,
  handleEditRowAction,
  onSearchChange,
  searchPlaceholder,
  searchValue,
  withDeletePanel,
  selectedRowCount,
  handleDeselectAll,
}: Props): React.ReactElement => (
  <div style={headerContainer}>
    {withSearch && (
      <WppInput
        onWppChange={onSearchChange}
        type="search"
        placeholder={searchPlaceholder}
        size="s"
        value={searchValue}
      />
    )}
    {withDeletePanel && (
      <ActionsPanel
        handleDeleteRowAction={handleDeleteRowAction}
        selectedRowCount={selectedRowCount}
        handleDeselectAll={handleDeselectAll}
        handleEditRowAction={handleEditRowAction}
      />
    )}
  </div>
);

export default AgGridHeader;
