import React from 'react';
import { WppIconSort } from 'buildingBlocks';
import { WppTableHeaderCellProps } from '../types';
import '../style.scss';

const WppTableHeaderCell: React.FC<WppTableHeaderCellProps> = ({ children, style, enableSort, activeSortColumn, className, colSpan }: WppTableHeaderCellProps): React.ReactElement => (
  <td className={`wpp-table__header__cell ${className}`} style={style} colSpan={colSpan}>
    {children}
    { enableSort && <WppIconSort size="s" color={activeSortColumn ? 'var(--wpp-grey-color-800)' : 'var(--wpp-icon-color)'} />}
  </td>
);

export default WppTableHeaderCell;
