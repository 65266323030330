import _ from 'lodash';
import React, { Component, ReactText, CSSProperties } from 'react';
import { OrderedMap } from 'immutable';
import { Grid, Input, Checkbox, Button, Dimmer, Loader } from 'buildingBlocks';
import PaginationControl from 'components/PaginationControl';
import { TwoListMultiSelectProps, TwoListMultiSelectState } from './TwoListMultiSelect';
import OptionsList from './OptionsList';

export const headerStyle: CSSProperties = {
  fontWeight: 500,
  textAlign: 'left',
};

type SelectAllSubHeaderProps = {
  label: string,
  selectAllChecked: boolean,
  onSelectAll: (e: React.FormEvent<HTMLInputElement>, data: { checked: boolean }) => void,
};
const SelectAllSubHeader = ({ label, selectAllChecked, onSelectAll }: SelectAllSubHeaderProps) => (
  <div className="row-item subheader">
    <span><Checkbox checked={selectAllChecked} onClick={onSelectAll} /></span>
    <span className="label checkbox-label">{label}</span>
  </div>
);

const extractValueFromMap = <T extends any>(immutableMap: OrderedMap<unknown, T>): Array<T> => (
  Array.from(immutableMap.values())
);

class TwoListMultiSelect extends Component<TwoListMultiSelectProps, TwoListMultiSelectState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    value: [],
  };

  static getDerivedStateFromProps(props, state) {
    const { sortFn, keyFn, value } = props;
    const orderedMap = OrderedMap(_.keyBy(value, keyFn));
    if (!_.isEqual(value, state.value)) {
      return {
        value,
        selectedItems: sortFn ? orderedMap.sortBy(sortFn) : orderedMap,
      };
    }
    return null;
  }

  constructor(props: TwoListMultiSelectProps) {
    super(props);
    const {
      sortFn, keyFn, value, defaultValue,
    } = props;
    const sortedMap = OrderedMap((defaultValue || value).map((item) => [keyFn(item), item]));

    this.state = {
      items: [],
      selectedItems: sortFn ? sortedMap.sortBy(sortFn) : sortedMap,
      filteredItems: [],
      search: '',
      loading: false,
      numberOfItems: 0,
      value,
    };
  }

  componentDidMount() {
    this.getData();
  }

  onItemClicked = (value: any) => {
    const key = this.props.keyFn(value);

    let selectedItems;
    if (this.state.selectedItems.has(key)) {
      selectedItems = this.state.selectedItems.remove(key);
    } else {
      selectedItems = this.state.selectedItems.set(key, value);
    }

    const sortedSelectedItems = this.props.sortFn ? selectedItems.sortBy(this.props.sortFn) : selectedItems;
    this.setState({ selectedItems: sortedSelectedItems });
    if (this.props.onChange) {
      this.props.onChange(extractValueFromMap(this.props.sortFn ? selectedItems.sortBy(this.props.sortFn) : selectedItems));
    }
  };

  onSearchChange = (_e: React.SyntheticEvent<HTMLInputElement, Event>, data: { value: string }) => {
    const search = data.value;
    this.setState({ search });
    this.getData({ search });
  };

  onSubmit() {
    this.props.onSubmit(extractValueFromMap(this.state.selectedItems));
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel(this.props.value);
    }
  }

  getData(args?: { search: string, limit?: number, skip?: number }) {
    const search = _.get(args, 'search', '');
    const limit = _.get(args, 'limit', 10);
    const skip = _.get(args, 'skip', 0);

    this.setState({ loading: true });
    this.props.dataProvider.queryWithPagination({
      search, skip, limit, ...args,
    }).then((res) => {
      this.setState({
        loading: false,
        items: res.elements,
        filteredItems: res.elements,
        numberOfItems: res.count,
      });
    });
  }

  deselectAll() {
    this.setState({ selectedItems: OrderedMap() });
    if (this.props.onChange) {
      this.props.onChange(extractValueFromMap(OrderedMap()));
    }
  }

  selectAll = (_e: React.SyntheticEvent, { checked }: { checked: boolean }) => {
    if (!checked) {
      this.deselectAll();
      return;
    }
    this.setState({ loading: true });
    const { search } = this.state;
    const { keyFn, dataProvider, sortFn } = this.props;
    dataProvider.queryAll({ search })
      .then((res) => {
        const items = res.elements.slice();
        const selectedItems = this.state.selectedItems.merge(
          OrderedMap(items.map((item) => [keyFn(item), item]) as Array<[ReactText, unknown]>),
        );
        const sortFnName = sortFn ? selectedItems.sortBy(sortFn) : selectedItems;
        this.setState({ loading: false, selectedItems: sortFnName });
        if (this.props.onChange) {
          this.props.onChange(extractValueFromMap(sortFnName));
        }
      });
  };

  renderPaginationControl = () => (
    <div className="pagination-wrapper">
      <PaginationControl
        numberOfElements={this.state.numberOfItems}
        onDataRangeChange={({ start, end }) => this.getData({
          search: this.state.search,
          limit: end - start,
          skip: start,
        })}
        startingElementsPerPage={10}
        elementsPerPageOptions={[10]}
        style={{ float: 'right' }}
        backgroundColor="#f7f7f7"
      />
    </div>
  );

  renderTopHeader = () => (
    <Input
      className="search"
      icon="search"
      iconPosition="left"
      fluid
      placeholder={this.props.searchPlaceholder || 'Search'}
      onChange={this.onSearchChange}
    />

  );

  render() {
    const { keyFn, displayFn, pluralize, limit, limitExceededMessage, rightSubHeader,
      customRow, onInputChange, additionalState, disableSelectAll, disableOptions, disableSelected, defaultValue } = this.props;
    const { filteredItems, selectedItems, numberOfItems } = this.state;
    const loading = this.props.loading || this.state.loading;
    const submitButtonProps = _.isFunction(this.props.submitButtonProps)
      ? this.props.submitButtonProps(extractValueFromMap(selectedItems))
      : {};
    const renderSelectAllSubHeader = (label: string) => (
      <SelectAllSubHeader
        label={label}
        selectAllChecked={numberOfItems === selectedItems.size}
        onSelectAll={this.selectAll}
      />
    );
    return (
      <div className="multi-select">
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Dimmer active={loading}>
                <Loader inverted>{this.props.loadingMsg || ''}</Loader>
              </Dimmer>
              <OptionsList
                items={filteredItems}
                selected={selectedItems}
                onSelected={this.onItemClicked}
                keyFn={keyFn}
                displayFn={displayFn}
                pluralize={pluralize}
                renderPaginationControl={this.renderPaginationControl}
                renderTopHeader={this.renderTopHeader}
                customSubHeader={!disableSelectAll && renderSelectAllSubHeader}
                disabled={disableOptions}
              />
            </Grid.Column>
            <Grid.Column>
              <OptionsList
                items={extractValueFromMap(selectedItems)}
                selected={selectedItems}
                onSelected={this.onItemClicked}
                keyFn={keyFn}
                displayFn={displayFn}
                pluralize={pluralize}
                isSelected
                onInputChange={onInputChange}
                additionalState={additionalState}
                customRow={customRow}
                customSubHeader={rightSubHeader}
                defaultValue={defaultValue}
                disabled={disableSelected}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {
          this.props.footer
            ? React.cloneElement(this.props.footer, { ...this.state })
            : (
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                {
                  limit
                  && this.state.selectedItems.size > limit
                  && (
                    <small style={{ color: '#E03E4D', fontSize: '16px', padding: '5px 30px 0 0' }}>
                      {limitExceededMessage}
                    </small>
                  )
                }
                <Button onClick={() => this.onCancel()}>Cancel</Button>
                <Button
                  primary
                  onClick={() => this.onSubmit()}
                  disabled={limit && this.state.selectedItems.size > limit}
                  {...submitButtonProps}
                >
                  Submit
                </Button>
              </div>
            )
        }
      </div>
    );
  }
}

export default TwoListMultiSelect;
