import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;
const { WPP } = COPILOT_COLORS;

const style: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    width: '100%',
  },
  titleStyle: {
    marginBottom: SPACING[8],
  },
  input: {
    width: 'inherit',
  },
  labelContainer: {
    height: 'inherit',
    wordBreak: 'keep-all',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px 0px 12px',
    color: WPP.grey1000,
    backgroundColor: WPP.grey200,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderLeft: 'none',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    fontWeight: 600,
    fontSize: 14,
    borderColor: WPP.grey500,
    whiteSpace: 'nowrap',
  },
  smallInputLabelBorderRadius: {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  labelHover: {
    borderColor: WPP.grey700,
  },
  labelFocus: {
    borderColor: WPP.grey800,
  },
  labelError: {
    backgroundColor: WPP.danger200,
    borderColor: WPP.danger500,
    color: WPP.danger600,
  },
};

export default style;
