import React from 'react';

type Props = {
  fill: string
};

export default ({ fill }: Props) => (
  <svg width="111" height="76" viewBox="0 0 111 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="54.1016" y="75.375" width="11.7434" height="2.69426" transform="rotate(-90 54.1016 75.375)" fill="white" stroke="black" strokeWidth="0.25" />
    <rect x="0.125" y="0.125" width="110.65" height="65.419" rx="1.875" fill="white" stroke="black" strokeWidth="0.25" />
    <rect x="6.50391" y="5.23633" width="97.892" height="54.9596" fill="white" stroke="black" strokeWidth="0.25" />
    <rect x="25.4453" y="72.6758" width="60.0092" height="2.69921" rx="1.3496" fill="white" stroke="black" strokeWidth="0.25" />
    <path d="M100.411 61.9972C100.599 62.117 100.742 62.2942 100.821 62.5025C100.899 62.7107 100.908 62.9388 100.847 63.1527C100.785 63.3666 100.657 63.5549 100.479 63.6895C100.302 63.824 100.087 63.8976 99.8645 63.8993C99.6423 63.901 99.4255 63.8307 99.2464 63.6989C99.0673 63.567 98.9356 63.3807 98.8709 63.1678C98.8061 62.9548 98.8119 62.7266 98.8872 62.5172C98.9626 62.3078 99.1035 62.1284 99.2889 62.0057" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <line x1="99.7578" y1="62.7637" x2="99.7578" y2="61.8874" stroke="black" strokeWidth="0.5" strokeLinecap="round" />
    <rect x="7.19922" y="5.84961" width="96.5" height="53.65" fill={fill} />
  </svg>
);
