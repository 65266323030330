import _ from 'lodash';
import { KPI_TO_SUCCESS_EVENTS } from 'charts/constants';
import { COLOR_OPTIONS, GOALTYPE_MAPPINGS } from './constants';
import { LookupTable, Metrics } from './types';

/* eslint-disable no-param-reassign */

type RawDataSampleNode = {
  weeklyMetrics: {},
  dailyMetrics: {},
  isLeaf: boolean,
  wasLeaf: boolean,
  name: string,
  totalMetrics: Metrics,
  children: Array<RawDataSampleNode>,
};

// const mkMap = <T>(keyFn: (k: string) => T) => obj => new Map(_.map(obj, (v, k) => [keyFn(k), v]));
// const mkDateMap = mkMap(moment);

const populateLookupTable = (data: RawDataSampleNode, table: LookupTable): LookupTable => {
  // TODO: finish transition to use Map instead of object so we can use moment as a key
  // data.weeklyMetrics = mkDateMap(data.weeklyMetrics);
  // data.dailyMetrics = mkDateMap(data.dailyMetrics);

  const dates = _.uniq([..._.keys(data.weeklyMetrics)]);
  _.each(dates, (date) => {
    if (!table.weeklyMetrics[date]) {
      table.weeklyMetrics[date] = {};
    }
    table.weeklyMetrics[date][data.name] = {
      ..._.get(data.weeklyMetrics, date, {}),
      // @ts-ignore
      isLeaf: data.isLeaf,
      wasLeaf: data.wasLeaf,
    };
  });
  table.totalMetrics[data.name] = {
    ...data.totalMetrics,
    isLeaf: data.isLeaf,
    wasLeaf: data.wasLeaf,
  };
  if (data.children) {
    data.children.forEach((c) => populateLookupTable(c, table));
  }

  return table;
};

export const createLookupTable = (data: RawDataSampleNode): LookupTable => populateLookupTable(data, {
  totalMetrics: {},
  weeklyMetrics: {},
});

export const getColorOptions = (viewType, goal) => [goal, KPI_TO_SUCCESS_EVENTS[goal], ...COLOR_OPTIONS[viewType]];

export const deepFreeze = (object: Object) => {
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self
  // eslint-disable-next-line
  for (let name of propNames) {
    const value = object[name];
    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }
  return Object.freeze(object);
};

export const goalMapping = (goal) => GOALTYPE_MAPPINGS[goal] || goal;
