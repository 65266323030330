import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  WppActionButton,
  WppIconEdit,
} from 'buildingBlocks';
import { actionButton } from './style';

type ActionTypeProps = {
  type: 'edit' | 'view'
  to: string
};

const AgGridActionButton: React.FC<ActionTypeProps> = ({ to, type }: ActionTypeProps): React.ReactElement => (
  <Link to={to} style={actionButton}>
    <WppActionButton>
      {_.isEqual(type, 'edit') ? 'Edit' : 'View'}
      {_.isEqual(type, 'edit') && (
        <WppIconEdit slot="icon-start" />
      )}
    </WppActionButton>
  </Link>
);

export default AgGridActionButton;
