import _ from 'lodash';
import { AugmentedStatBoxData, OverviewStatBoxDataTransformed, DomainStatBoxDataTransformed, GeoStatBoxDataTransformed, DeviceStatBoxDataTransformed, CreativeSizeStatBoxDataTransformed, BaseStatBoxDataTransformed } from 'containers/ABInsights/types';
// eslint-disable-next-line import/no-named-as-default
import metrics, { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { BudgetType } from '../ABReportViz';
import { VizId } from '../constants';

const overviewProcessFn = (data: AugmentedStatBoxData, primaryKPI: Metric): OverviewStatBoxDataTransformed => {
  const { flightExtIdToAlias, flightExtIdToVariant, variantToFlightExtId, statBoxData } = data;
  const { value: primaryKPIValue, lowerIsBetter, text: primaryKPIText } = primaryKPI;
  const { Overview: { diffRatio, overallPerformanceWinner, projectedSuccessMetricsForLoser } } = statBoxData;
  const winnerId = overallPerformanceWinner[primaryKPIValue];
  const winnerVariant = flightExtIdToVariant[winnerId];
  const loserVariant = (winnerVariant === 'a') ? 'b' : 'a';
  const loserId = variantToFlightExtId[loserVariant];

  return {
    winnerVariant,
    winnerAlias: flightExtIdToAlias[winnerId],
    loserVariant,
    loserAlias: flightExtIdToAlias[loserId],
    diffRatio: diffRatio[primaryKPIValue],
    comparisonText: lowerIsBetter ? 'lower' : 'higher',
    primaryKPIText,
    primaryKPIValue,
    projectedSuccessMetrics: projectedSuccessMetricsForLoser[primaryKPIValue],
  };
};

const baseStatBoxDataProcessFn = (
  data: AugmentedStatBoxData,
  statBoxDataKey: string,
  feature: string,
  kpi: Metric,
  budgetType: BudgetType,
): BaseStatBoxDataTransformed & { highestDeliveringFeatures: string | undefined } => {
  const { statBoxData, variantToFlightExtId, flightExtIdToVariant, flightExtIdToAlias } = data;
  const { value: kpiValue, lowerIsBetter, text: kpiText } = kpi;
  const {
    countBetterPerformingFeatures,
    majorityDeliveringFeatures,
    majorityDeliveryShare,
    majorityDeliveryWinners,
    topPerformingFlight,
    totalFeatureCount,
  } = statBoxData[statBoxDataKey];
  const topPerformingFlightId = topPerformingFlight[kpiValue];
  const countFeaturesAPerformedBetter = _.get(countBetterPerformingFeatures, `${kpiValue}.${variantToFlightExtId.a}`, 0);
  const countFeaturesBPerformedBetter = _.get(countBetterPerformingFeatures, `${kpiValue}.${variantToFlightExtId.b}`, 0);
  const countFeaturesWinnerPerformedBetter = topPerformingFlightId === variantToFlightExtId.a ? countFeaturesAPerformedBetter : countFeaturesBPerformedBetter;

  const topPerformingVariant = flightExtIdToVariant[topPerformingFlightId];
  const topPerformingAlias = flightExtIdToAlias[topPerformingFlightId];

  const bottomPerformingVariant = topPerformingVariant === 'a' ? 'b' : 'a';
  const bottomPerformingId = variantToFlightExtId[bottomPerformingVariant];
  const bottomPerformingAlias = flightExtIdToAlias[bottomPerformingId];

  const winnerVariantMajorityFeatures = _.get(flightExtIdToVariant, [majorityDeliveryWinners, kpiValue, budgetType.value]);
  const loserVariantMajorityFeatures = (winnerVariantMajorityFeatures === 'a') ? 'b' : 'a';

  const highestDeliveringFeatures = _.chain(majorityDeliveringFeatures)
    .get(budgetType.value)
    // @ts-ignore
    .map(feature)
    .join(', ')
    .value();

  return {
    totalFeatureCount,
    countFeaturesAPerformedBetter,
    countFeaturesBPerformedBetter,
    countFeaturesWinnerPerformedBetter,
    topPerformingVariant,
    topPerformingAlias,
    bottomPerformingVariant: bottomPerformingVariant as 'a' | 'b',
    bottomPerformingAlias,
    comparisonText: lowerIsBetter ? 'lower' : 'higher',
    kpiText,
    majorityDeliveryShareFeatureCount: _.size(majorityDeliveringFeatures[budgetType.value]),
    majorityDeliveryShare: majorityDeliveryShare[budgetType.value],
    winnerVariantMajorityFeatures,
    loserVariantMajorityFeatures,
    budgetTypeText: budgetType.text,
    highestDeliveringFeatures,
  };
};

const domainProcessFn = (data: AugmentedStatBoxData, kpi: Metric, budgetType: BudgetType): DomainStatBoxDataTransformed => (
  baseStatBoxDataProcessFn(data, 'DomainData', 'domain', kpi, budgetType));

const geoProcessFn = (data: AugmentedStatBoxData, kpi: Metric, budgetType: BudgetType): GeoStatBoxDataTransformed => (
  baseStatBoxDataProcessFn(data, 'GeoData', 'displayRegion', kpi, budgetType));

const deviceProcessFn = (data: AugmentedStatBoxData, kpi: Metric, budgetType: BudgetType): DeviceStatBoxDataTransformed => (
  baseStatBoxDataProcessFn(data, 'DeviceData', 'deviceType', kpi, budgetType));

const creativeSizeProcessFn = (data: AugmentedStatBoxData, kpi: Metric, budgetType: BudgetType): CreativeSizeStatBoxDataTransformed => (
  baseStatBoxDataProcessFn(data, 'CreativeSizeData', 'creativeSize', kpi, budgetType));

export const processABInsightsDataForDisplay = {
  [VizId.overview]: overviewProcessFn,
  [VizId.domain]: domainProcessFn,
  [VizId.geography]: geoProcessFn,
  [VizId.device]: deviceProcessFn,
  [VizId.creativeSize]: creativeSizeProcessFn,
};

// do not render statbox if it's a non-ratePercentage kpi
export const renderStatbox = (kpiValue: string) => !!metrics.ratePercentage[kpiValue];
