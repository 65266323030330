import React from 'react';

type Props = {
  fill: string
};

export default ({ fill }: Props) => (
  <svg width="149" height="78" viewBox="0 0 149 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="17.8794" y="4.23511" width="113.202" height="62.4862" stroke="black" strokeWidth="0.25" fill={fill} />
    <rect x="14.002" y="0.125" width="121.035" height="71.4042" stroke="black" strokeWidth="0.25" />
    <path d="M9.49539 77.8193L1.85693 75.6479L147.259 75.7255L139.504 77.8193H9.49539Z" stroke="black" strokeWidth="0.25" />
    <path d="M2.08973 75.6866C1.36315 75.5664 1.01538 75.3915 0.5 74.8724V72.6623C0.901952 72.1491 1.25781 71.9508 2.08973 71.7317H59.8628M147.065 75.6479C147.691 75.491 148.006 75.3491 148.5 74.9887V72.701C148.196 72.1622 147.888 71.9442 147.065 71.7317H89.2147M59.8628 71.7317V72.8174C60.0577 73.2192 60.2111 73.3579 60.5608 73.4765H88.5168C88.8488 73.3563 89.0109 73.2225 89.2535 72.8561L89.2147 71.7317M59.8628 71.7317H89.2147M59.8628 71.7317C60.094 72.005 60.2208 72.0925 60.4445 72.1194H88.5943C88.871 72.0619 89.0032 71.9665 89.2147 71.7317" stroke="black" strokeWidth="0.25" />
  </svg>
);
