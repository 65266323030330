import _ from 'lodash';
import React, { useContext, useEffect, useState, useMemo, Dispatch } from 'react';
import numeral from 'numeral';
import { geoPath, select, scaleThreshold, forceSimulation, forceX, forceY, forceCollide, scaleLinear, ScaleThreshold, ScaleLinear, extent } from 'd3';
import { feature } from 'topojson';
import { GoalType } from 'utils/types';
import { ObjectDropdown } from 'buildingBlocks';
import { MetricsFormattingConstants } from 'constantsBase';
import { COPILOT_COLORS } from 'globalStyles';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { COLORS } from 'charts/constants';
import SlideTitle from 'charts/InsightsViz/Components/SlideTitle';
import VizHeader from 'charts/InsightsViz/Components/VizHeader';
import MessageContainer from 'charts/Components/MessageContainer';
import { AugmentedStatBoxData, GeoInsightsData, GeoKPIs, KPIOptions as KPIOptionsType, VariantToFlightExtId } from 'containers/ABInsights/types';
import { AB_COLORS_ORANGE_TO_BLUE, AB_COLORS_ORANGE_TO_PURPLE, EXPORT_PREFIX, SlideIcons, VizId } from './constants';
import InsightsPanel from './Components/InsightsPanel';
import List, { Datum } from './List';
import { ABColorContext, BudgetType, BudgetTypeOptions, ColorContext } from './ABReportViz';
import { getABDiffColorScaleDomain, getDataSansOutliers, getDiffConfig, TooltipConfig, addStripePattern, Greater, getInsightsByVizId } from './utils';
import KPILegend from './KPILegend';
import Tooltip from './Tooltip';
import { renderStatbox } from './StatBoxes/utils';

const WIDTH = 824;
const HEIGHT = 537;
const TOP_N = 10;
const DELIVERY_LEGEND_RADII = [10, 14, 27.5];
const NODE_PADDING = 1;
const MAX_BUBBLE_RADIUS = 75;

type DataNode = {
  size: number
  x: number
  y: number
};

// we don't want the bubbles overlapping
const applySimulation = (nodes: Array<DataNode>) => {
  const simulation = forceSimulation(nodes)
    .force('x', forceX((d) => d.x))
    .force('y', forceY((d) => d.y))
    .force('collide', forceCollide().radius((d: DataNode) => d.size + NODE_PADDING).strength(1))
    .stop();
  for (let i = 0; i < 600; i += 1) {
    simulation.tick();
  }
};

type GeoVizDatum = {
  a: GeoKPIs,
  b: GeoKPIs,
  geoRegion: string,
  displayRegion: string,
  abbreviatedRegion: string,
  perf: { better: Greater, diffRatio: number, a: number, b: number },
  delivery: { more: Greater, diffRatio: number, a: number, b: number }
};

const buildViz = (
  vizId: string,
  singleCountryGeoJson: GeoJSON.FeatureCollection<GeoJSON.GeometryObject, any>,
  performanceData: Array<GeoVizDatum>,
  abDiffColorScale: ScaleThreshold<number, string>,
  budgetType: string,
  deliveryScale: ScaleLinear<number, number>,
  setTooltipConfig: Dispatch<TooltipConfig>,
) => {
  const path = geoPath();

  const mapContainer = select(`#${vizId} #map`);

  const nodeData = _.map(performanceData, (datum: GeoVizDatum) => {
    // find matching geojson feature in order to get x, y position of bubble
    const geojsonFeature = _.find(singleCountryGeoJson.features, (f) => f.properties.iso_3166_2 === datum.geoRegion || f.properties.region === datum.geoRegion || f.properties.geonunit === datum.geoRegion);
    const [x, y] = path.centroid(geojsonFeature);
    const combinedDelivery = _.sumBy([datum.a, datum.b], (d) => _.get(d, budgetType, 0) as number);
    return { ...datum, x, y, size: _.min([deliveryScale(combinedDelivery), MAX_BUBBLE_RADIUS]) };
  });

  // apply force simulation to get (uncollided) x and y positions of the circles
  applySimulation(nodeData);

  // build region polygons
  mapContainer
    .selectAll('.region')
    .data(singleCountryGeoJson.features)
    .join('path')
    .attr('class', 'region')
    .attr('d', path);

  // striped pattern will be used for regions where no comparison can be made (e.g. 0.1 vs null CPA)
  const stripePatternId = addStripePattern(mapContainer);

  // build and position bubbles
  mapContainer
    .selectAll('.bubble')
    .data(nodeData)
    .join('g')
    .attr('class', 'bubble')
    .attr('transform', (d) => `translate(${d.x},${d.y})`)
    .on('mouseenter', (d) => setTooltipConfig({ ...d, featureLabel: d.displayRegion, position: [d.x, d.y] }))
    .on('mouseleave', () => setTooltipConfig(null))
    .each(function addCircleAndLabel(d) {
      const group = select(this);

      const fillColor = (d.perf.diffRatio === 0 || _.isNil(d.perf.diffRatio))
        ? COLORS.GREYS.NO_DATA
        // because of the way ScaleThreshold works, we needed to set A diffs as negative
        // so we need to pass them in that way as well
        : abDiffColorScale(d.perf.better === 'a' ? -d.perf.diffRatio : d.perf.diffRatio);

      group
        .selectAll('circle')
        .data(['single-datum'])
        .join('circle')
        .attr('fill', fillColor)
        .transition()
        .attr('r', d.size);

      // add a striped pattern on top if no meaningful difference in KPI
      if (_.isNil(d.perf.diffRatio)) {
        group
          .selectAll('circle.patterned')
          .data(['single-datum'])
          .join('circle')
          .attr('class', 'patterned')
          .attr('fill', `url(#${stripePatternId})`)
          .transition()
          .attr('r', d.size);
      }

      // only show label if circle is above a certain threshold size
      if (d.size > 12) {
        const combinedDelivery = _.sum([d.delivery.a, d.delivery.b]);
        group
          .selectAll('text')
          .data([
            d.abbreviatedRegion || d.displayRegion,
            numeral(combinedDelivery).format(MetricsFormattingConstants.ROUNDED_ONE_DECIMAL),
          ])
          .join('text')
          .text(_.identity)
          .attr('dy', (_d, i) => ((i === 0) ? -6 : 6))
          .style('fill', () => {
            // either display a white or dark text depending on how it will contrast with bubble background
            const indexOfFillColorInRange = _.indexOf(abDiffColorScale.range(), fillColor);
            const circleFillIsDark = (indexOfFillColorInRange === 0) || (indexOfFillColorInRange === _.size(abDiffColorScale.range()) - 1);
            return circleFillIsDark ? 'white' : '#535D5F';
          });
      }
    });
};

type Props = {
  vizId: string
  data: GeoInsightsData
  variantToFlightExtId: VariantToFlightExtId
  currency: string
  KPIOptions: KPIOptionsType
  tooltipContent: string
  budgetTypeOptions: BudgetTypeOptions
  statBoxData: AugmentedStatBoxData
  selectedBudgetType: BudgetType
  setSelectedBudgetType: Function
  selectedKPI: Metric & GoalType
  setSelectedKPI: Function
};

const GeoMap = (props: Props) => {
  const { vizId,
    data,
    variantToFlightExtId,
    currency,
    KPIOptions,
    tooltipContent,
    budgetTypeOptions,
    statBoxData,
    selectedBudgetType,
    setSelectedBudgetType,
    selectedKPI,
    setSelectedKPI,
  } = props;
  if (_.isNil(props.data)) {
    return (
      <div id={vizId} className="slide">
        {
          vizId.includes(EXPORT_PREFIX) && (
            <SlideTitle section="Geography" icon={SlideIcons[VizId.geography]} />
          )
        }
        <MessageContainer
          additionalClasses={['geo', 'ab-viz-placeholder']}
          message="Geo Insights does not support this country yet. We are working on it so stay tuned!"
        />
      </div>
    );
  }
  const { geoJSON } = data;

  /* eslint-disable react-hooks/rules-of-hooks */
  /* (need to be able to shortcircuit to return geo placeholder when appropriate) */
  const [tooltipConfig, setTooltipConfig] = useState<TooltipConfig>(null);

  const { value: selectedKPIValue, lowerIsBetter } = selectedKPI;

  const [colors] = useContext<ABColorContext>(ColorContext);

  const memoData = useMemo(() => {
    const dataForA = data[variantToFlightExtId.a];
    const dataForB = data[variantToFlightExtId.b];

    const uniqueRegions = _.reject(_.uniq(_.map([...dataForA, ...dataForB], 'geoRegion')), (d) => d === 'Unknown');

    const dataFormattedAB = _.map(uniqueRegions, (region: string) => {
      const aDatum = _.find(dataForA, { geoRegion: region });
      const bDatum = _.find(dataForB, { geoRegion: region });

      const aPerf = _.get(aDatum, selectedKPIValue);
      const bPerf = _.get(bDatum, selectedKPIValue);
      const { greater: perfGreater, diffRatio: perfDiffRatio } = getDiffConfig(aPerf, bPerf, lowerIsBetter);

      const aDelivery = _.get(aDatum, selectedBudgetType.value);
      const bDelivery = _.get(bDatum, selectedBudgetType.value);
      // moreisBetter with delivery so pass false for lowerIsBetter arg
      const { greater: deliveryGreater, diffRatio: deliveryDiffRatio } = getDiffConfig(aDelivery, bDelivery, false);

      return {
        a: aDatum,
        b: bDatum,
        geoRegion: _.get(aDatum, 'geoRegion') || _.get(bDatum, 'geoRegion'),
        displayRegion: _.get(aDatum, 'displayRegion') || _.get(bDatum, 'displayRegion'),
        abbreviatedRegion: _.get(aDatum, 'displayRegionAbbr') || _.get(bDatum, 'displayRegionAbbr'),
        perf: {
          a: aPerf,
          b: bPerf,
          diffRatio: perfDiffRatio,
          better: perfGreater,
        },
        delivery: {
          a: aDelivery,
          b: bDelivery,
          diffRatio: deliveryDiffRatio,
          more: deliveryGreater,
        },
      };
    });

    const maxADiff = _.max(_.map(_.filter(dataFormattedAB, (d) => d.perf.better === 'a'), (d) => d.perf.diffRatio));
    const maxBDiff = _.max(_.map(_.filter(dataFormattedAB, (d) => d.perf.better === 'b'), (d) => d.perf.diffRatio));

    const abDiffColorScaleDomain = getABDiffColorScaleDomain(maxADiff, maxBDiff);
    const abDiffColorScale = scaleThreshold<number, string>()
      .domain(abDiffColorScaleDomain)
      .range((colors.b === COPILOT_COLORS.NEW_DESIGN_SYSTEM.purple) ? AB_COLORS_ORANGE_TO_PURPLE : AB_COLORS_ORANGE_TO_BLUE);

    const topNDataByCombinedDelivery = _.take(_.orderBy(dataFormattedAB, ({ a, b }) => _.sumBy([a, b], (d) => _.get(d, selectedBudgetType.value, 0)), 'desc'), TOP_N);

    const flatData = _.flatMap(dataFormattedAB, ({ a, b }) => ([a, b]));

    const dataWithNoOutliersByDelivery = getDataSansOutliers(flatData, selectedBudgetType.value, 3);
    const deliveryScale = scaleLinear()
      .domain(extent(dataWithNoOutliersByDelivery, (d) => d[selectedBudgetType.value]))
      .range([3, _.last(DELIVERY_LEGEND_RADII)]);

    const { topojson, metadata: geoMetadata } = _.last(geoJSON);
    const singleCountryGeoJson = feature<GeoJSON.GeoJsonProperties>(
      topojson,
      (topojson.objects[geoMetadata.key] as TopoJSON.GeometryCollection<GeoJSON.GeoJsonProperties>),
    );

    return {
      singleCountryGeoJson,
      topNDataByCombinedDelivery,
      dataFormattedAB,
      abDiffColorScale,
      deliveryScale,
    };
  }, [data, geoJSON, lowerIsBetter, selectedKPIValue, colors, variantToFlightExtId, selectedBudgetType]);

  useEffect(() => buildViz(
    vizId,
    memoData.singleCountryGeoJson,
    memoData.dataFormattedAB,
    memoData.abDiffColorScale,
    selectedBudgetType.value,
    memoData.deliveryScale,
    setTooltipConfig,
  ), [
    vizId,
    memoData.singleCountryGeoJson,
    memoData.dataFormattedAB,
    memoData.abDiffColorScale,
    selectedBudgetType.value,
    memoData.deliveryScale,
  ]);
  /* eslint-enable */
  return (
    <div id={vizId} className="slide">
      {
        vizId.includes(EXPORT_PREFIX) && (
          <SlideTitle section="Geography" icon={SlideIcons[VizId.geography]} />
        )
      }
      <div className="grid-container">
        <div className="main-visualization">
          {tooltipConfig && (
            <Tooltip
              tooltip={tooltipConfig}
              budgetTypeDisplayText={selectedBudgetType.text}
              selectedKPI={selectedKPI}
              colors={colors}
              baseXPosition={16}
              baseYPosition={104}
              includeCombinedDelivery
            />
          )}
          <VizHeader
            title="How Do Regions Compare"
            tooltipContent={tooltipContent}
          />
          <div className="controls">
            <div>
              <label htmlFor="budget-select">Budget Type</label>
              <ObjectDropdown
                // @ts-ignore passthrough props
                id="budget-select"
                keyFn={(metric: { text: string }) => metric.text}
                options={_.values(budgetTypeOptions)}
                selection
                compact
                onChange={setSelectedBudgetType}
                value={selectedBudgetType}
              />
            </div>
            <div>
              <label htmlFor="kpi-select">Goal</label>
              <ObjectDropdown
                // @ts-ignore passthrough props
                id="kpi-select"
                keyFn={(metric: { text: string }) => metric.text}
                options={KPIOptions}
                selection
                compact
                onChange={setSelectedKPI}
                value={selectedKPI}
              />
            </div>
          </div>
          <svg id="map" width={WIDTH} height={HEIGHT} />
        </div>
        <div className="viz-legend">
          <KPILegend
            id={`${vizId}-color-diff-legend`}
            data={{
              colorRange: memoData.abDiffColorScale.range(),
              domain: memoData.abDiffColorScale.domain(),
            }}
            width={196}
            variant="abDiff"
            selectedMetric={selectedKPI}
            currencyCode={currency}
          />
          <div className="delivery-legend">
            <p>(A+B) {selectedBudgetType.text}</p>
            <div>
              <span>{numeral(memoData.deliveryScale.invert(DELIVERY_LEGEND_RADII[0])).format(MetricsFormattingConstants.ROUNDED_NO_DECIMALS)}</span>
              <div className="circle" style={{ width: DELIVERY_LEGEND_RADII[0] * 2, height: DELIVERY_LEGEND_RADII[0] * 2 }} />
              <div className="circle" style={{ width: DELIVERY_LEGEND_RADII[1] * 2, height: DELIVERY_LEGEND_RADII[1] * 2 }} />
              <div className="circle" style={{ width: DELIVERY_LEGEND_RADII[2] * 2, height: DELIVERY_LEGEND_RADII[2] * 2 }} />
              <span>{numeral(memoData.deliveryScale.invert(DELIVERY_LEGEND_RADII[2])).format(MetricsFormattingConstants.ROUNDED_NO_DECIMALS)}</span>
            </div>
          </div>
        </div>
        <List
          title={`Top 10 Regions by ${selectedBudgetType.text}`}
          accessor="displayRegion"
          accessorOverrideLabel="Region"
          accessorFormatter={(r) => _.truncate(r, { length: 16 })}
          data={memoData.topNDataByCombinedDelivery as Array<Datum>}
          budgetType={selectedBudgetType}
          kpi={selectedKPI}
          showPerfColors={false}
          budgetTypeOptions={budgetTypeOptions}
        />
      </div>
      {renderStatbox(selectedKPIValue) && (
        <InsightsPanel
          abColors={colors}
          insights={getInsightsByVizId(VizId.geography, statBoxData, selectedKPI, selectedBudgetType)}
        />
      )}
    </div>
  );
};

export default GeoMap;
