import React, { MouseEventHandler } from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import { MetricsFormattingConstants } from 'constantsBase';
import { Icon } from 'buildingBlocks';
import { Devices, KPIs, VariantToFlightExtId } from 'containers/ABInsights/types';
import Mobile from './Mobile';
import Tablet from './Tablet';
import CTV from './CTV';
import Laptop from './Laptop';
import { ABColors, BudgetTypeOptions } from '../ABReportViz';
import { CustomColorScaleBuilder, Greater } from '../utils';

const deviceMap = {
  [Devices.PHONE]: Mobile,
  [Devices.TABLET]: Tablet,
  [Devices.LAPTOP_OR_PC]: Laptop,
  [Devices.CTV]: CTV,
};

const VerticalLabel = ({ label }: { label: string }) => (
  <div className="vertical-line-and-label">
    <span>{label}</span>
  </div>
);

type Props = {
  type: Devices,
  data: {
    [flightExtId: string]: KPIs
  }
  colors: ABColors
  variantToFlightExtId: VariantToFlightExtId
  budgetType: string
  colorScale: ReturnType<CustomColorScaleBuilder>
  onMouseEnter: MouseEventHandler
  onMouseLeave: MouseEventHandler
  isInActive: boolean
  isFirst: boolean
  winner: Greater
  budgetTypeOptions: BudgetTypeOptions
};

export default ({
  type,
  data,
  colors,
  variantToFlightExtId,
  budgetType,
  colorScale,
  onMouseEnter,
  onMouseLeave,
  isInActive,
  isFirst,
  winner,
  budgetTypeOptions,
}: Props) => {
  const DeviceComponent = deviceMap[type];
  const { a, b } = variantToFlightExtId;
  const budgetTypeAbbr = isFirst ? `${_.toUpper(budgetTypeOptions[budgetType].abbreviation)}: ` : '';

  return (
    <div className="single-device" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ opacity: isInActive ? 0.15 : 1 }}>
      {(!_.isNull(winner) && (winner !== 'equal') && !isInActive)
        && <Icon className="winner-check" name="check circle" style={{ color: colors[winner] }} data-winner={winner} />}
      <span className="delivery">
        {budgetTypeAbbr}{_.toUpper(numeral(data[a][budgetType]).format(MetricsFormattingConstants.ROUNDED_NO_DECIMALS))}
      </span>
      <DeviceComponent fill={colorScale(data[a])} />
      <VerticalLabel label={type} />
      <DeviceComponent fill={colorScale(data[b])} />
      <span className="delivery">
        {budgetTypeAbbr}{_.toUpper(numeral(data[b][budgetType]).format(MetricsFormattingConstants.ROUNDED_NO_DECIMALS))}
      </span>
    </div>
  );
};
