import { TEXT_COLOR } from 'charts/HeliosDataViz/constants';

export const BLOCK_HEIGHT = 120;
export const GRAY_LIGHT = '#999999';

export const MARGINS = {
  top: 50,
  right: 24,
  bottom: 24,
  left: 40,
};

export const TITLE_STYLES = {
  'font-size': '10px',
  fill: TEXT_COLOR,
  'text-transform': 'uppercase',
  'text-anchor': 'start',
};

export const TICK_STYLES = {
  'font-size': '10px',
  color: GRAY_LIGHT,
  fill: GRAY_LIGHT,
  'text-transform': 'uppercase',
};

export const LINE_STYLES = {
  fill: 'none',
  'stroke-width': '1.5',
  'stroke-linejoin': 'round',
  'stroke-linecap': 'round',
};

export const LABEL_STYLES = {
  'font-size': '12px',
  'text-transform': 'uppercase',
  'text-anchor': 'middle',
  'dominant-baseline': 'middle',
};
