import { TEXT_COLOR } from 'charts/HeliosDataViz/constants';
import { COPILOT_COLORS } from 'globalStyles';

export const GRAY_LIGHT = '#999999';
export const INNER_RADIUS = 80;

export const MAX_CLUSTER_NODES = 1500;
export const MAX_FIRSTLEVEL_LEAVES = 100;

export const DEFAULT_COLOR = COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE;
export const NODATA_COLOR = '#999999';
export const NODATA_STROKE_COLOR = '#CCCCCC';

export const MIN_BUBBLE_RADIUS = 1.5;
export const MAX_BUBBLE_RADIUS = 10;
export const BUBBLE_PADDING = 2;
export const TWO_PI = 2 * Math.PI;

export const MIN_STROKE_WIDTH = 0.5;
export const MAX_STROKE_WIDTH = 6;

export const MIN_STROKE_BRIGHT = 25;
export const MAX_STROKE_BRIGHT = 50;

export const POPUP_MARGINS = {
  left: 40,
  top: -80,
  bottom: 40,
};

export const PACK_STYLES = {
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
};

export const PACK_LABEL_STYLES = {
  fill: TEXT_COLOR,
  'font-size': '11px',
  'text-anchor': 'middle',
  'dominant-baseline': 'middle',
  'pointer-events': 'none',
  'user-select': 'none',
};

export const POPUP_ORIENTATIONS = {
  rightDown: {
    type: 'rightDown',
    rotation: '45deg',
    origin: '0 0',
  },
  right: {
    type: 'right',
    rotation: '-45deg',
    origin: '0 0',
  },
  left: {
    type: 'left',
    rotation: '-135deg',
    origin: '100% 0',
  },
  leftDown: {
    type: 'leftDown',
    rotation: '-225deg',
    origin: '100% 0',
  },
  leftUp: {
    type: 'leftUp',
    rotation: '-135deg',
    origin: '100% 100%',
  },
  rightUp: {
    type: 'rightUp',
    rotation: '-45deg',
    origin: '0 100%',
  },
};
