import { CATEGORICAL_COLORS } from 'charts/constants';

export const LINECHART_HEIGHT = 160;
export const STACKEDCHART_HEIGHT = 240;

export const STACKED_AREA_CHART_COLORS = CATEGORICAL_COLORS;

export const MARGINS = { top: 32, right: 16, bottom: 32, left: 80 };

export const BUDGET_OPT_ANALYTICS_WIKI = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125072-analytics-budget-optimization';

export enum BudgetTypeMapping {
  amount = 'revenue',
  imps = 'impressions',
}

export const BUDGET_OPT_DATA_ENDPOINT = 'budget_opt_data';
