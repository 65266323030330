import React, { useState, useEffect, useCallback } from 'react';
import * as d3 from 'd3';
import 'd3-selection-multi';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { parseDate, formatMonthDay } from 'charts/utils';
import { COPILOT_COLORS } from 'globalStyles';
import {
  MARGINS, BLOCK_HEIGHT, LABEL_STYLES,
  TICK_STYLES, LINE_STYLES, TITLE_STYLES,
} from './constants';
import { findHoverPoint } from './utils';
import { TrellisChartProps, LineDatum } from './types';

const { NEW_DESIGN_SYSTEM: { BLUES } } = COPILOT_COLORS;

const D3Container = ({ width, data }: TrellisChartProps) => {
  const [drawLayer, setDrawLayer] = useState(null);
  const [tooltip, setTooltip] = useState(null);
  const [hitbox, setHitbox] = useState(null);

  const drawLineChart = (selection, lineData) => {
    const xAxis = (g) => g
      .attr('class', 'x-axis')
      .styles(TICK_STYLES)
      .attr('transform', `translate(0,${BLOCK_HEIGHT - MARGINS.bottom})`)
      .call(d3.axisBottom(lineData.x).ticks(0).tickSizeOuter(0))
      .call((group) => group.select('.domain').attr('stroke', '#d4d4d4'));

    const yAxis = (g) => g
      .attr('class', 'y-axis')
      .attr('transform', `translate(${MARGINS.left},0)`)
      .styles(TICK_STYLES)
      .call(d3.axisLeft(lineData.y).ticks(2).tickFormat(lineData.tickFormat))
      .call((group) => group.select('.domain').remove())
      .call((group) => group.selectAll('.tick line').attr('stroke', '#d4d4d4'))
      .call((group) => group.selectAll('.tick:not(:first-child):not(:last-child) text').remove());

    const line = d3.line<LineDatum>()
      .defined((d) => d.value && !isNaN(d.value))
      .x((d) => lineData.x(parseDate(d.date)))
      .y((d) => lineData.y(d.value));

    selection.selectAll('g.x-axis')
      .data([null])
      .join('g')
      .call(xAxis);

    selection.selectAll('g.y-axis')
      .data([null])
      .join('g')
      .call(yAxis);

    selection.selectAll('path.line')
      .data([null])
      .join('path')
      .attr('class', 'line')
      .datum(lineData.values)
      .attr('stroke', BLUES.B500_WAVE)
      .styles(LINE_STYLES)
      .transition()
      .delay(100)
      .duration(400)
      .attr('d', line);

    selection.selectAll('text.title')
      .data([null])
      .join('text')
      .attr('class', 'title')
      .attr('x', 4)
      .attr('y', MARGINS.top - 24)
      .styles(TITLE_STYLES)
      .text(`Daily ${lineData.label}`);
  };

  const drawTooltip = (g, i, datum, hl) => {
    if (datum.values.length === 0) return;

    const highlight = hl || datum.latest;

    if (!highlight.value) return;

    const x = datum.x(parseDate(highlight.date));
    const y = datum.y(highlight.value);

    const text = g.selectAll('text')
      .data([null])
      .join('text')
      .styles(LABEL_STYLES)
      .style('fill', BLUES.B500_WAVE)
      .attr('transform', `translate(${x},${y + (i * BLOCK_HEIGHT)})`)
      .text(() => datum.metricFormat(highlight.value));

    const { height: h } = text.node().getBBox();

    text.attr('dy', -h);

    g.selectAll('circle')
      .data([null])
      .join('circle')
      .attr('r', 4)
      .attr('fill', BLUES.B500_WAVE)
      .attr('transform', `translate(${x},${y + (i * BLOCK_HEIGHT)})`);

    g.selectAll('text.date')
      .data([null])
      .join('text')
      .attr('class', 'date')
      .text(formatMonthDay(parseDate(highlight.date)))
      .attr('transform', `translate(${x}, ${(i + 1) * BLOCK_HEIGHT - 8})`)
      .styles(TICK_STYLES)
      .attr('text-anchor', 'middle')
      .style('text-transform', 'capitalize');
  };

  const drawChart = useCallback((myDrawLayer, myTooltip, myHitbox) => {
    const augmentedData = data.map((d) => {
      const values = d.values.filter((v) => parseDate(v.date) <= v.currentDateTimestamp);

      return { ...d,
        values,
        x: d3
          .scaleUtc()
          .domain(d3.extent(d.values, (v) => parseDate(v.date)))
          .range([MARGINS.left, width - MARGINS.right]),
        y: d3
          .scaleLinear()
          .domain([0, d3.max(d.values, (v) => v.value)])
          .nice()
          .range([BLOCK_HEIGHT - MARGINS.bottom, MARGINS.top]),
        latest: values[values.length - 1] };
    });

    myDrawLayer.selectAll('g.line-chart')
      .data(augmentedData)
      .join('g')
      .attr('class', 'line-chart')
      .attr('transform', (_d, i) => `translate(0, ${i * BLOCK_HEIGHT})`)
      .each((d, i, g) => {
        drawLineChart(d3.select(g[i]), d);
      });

    myTooltip.selectAll('g.tooltip')
      .data(augmentedData)
      .join('g')
      .attr('class', 'tooltip')
      .each((d, i, g) => {
        drawTooltip(d3.select(g[i]), i, d, null);
      });

    myHitbox.on('touchmove mousemove', (_d, i, nodes) => {
      const mouseX = d3.mouse(nodes[i])[0];

      myTooltip.selectAll('line')
        .remove();

      myTooltip.append('line')
        .attr('stroke', BLUES.B500_WAVE)
        .style('opacity', '0.6')
        .attr('x1', mouseX)
        .attr('x2', mouseX)
        .attr('y1', 0)
        .attr('y2', 640);

      myTooltip.selectAll('g.tooltip')
        .each((datum, index, selection) => {
          const { date, value } = findHoverPoint(mouseX, datum.x, datum.values);
          drawTooltip(d3.select(selection[index]), index, datum, { date, value });
        });
    });

    myHitbox.on('touchend mouseleave', () => {
      myTooltip
        .selectAll('line')
        .remove();

      myTooltip.selectAll('g.tooltip')
        .each((d, i, g) => {
          drawTooltip(d3.select(g[i]), i, d, null);
        });
    });
  }, [data, width]);

  useMount(() => {
    const ch = d3.select('#trellis-chart').style('width', `${width}px`);

    const svg = ch.append('svg')
      .attr('width', width)
      .attr('height', data.length * BLOCK_HEIGHT);

    const myDrawLayer = svg.append('g')
      .attr('class', 'drawLayer');

    const myTooltip = svg.append('g')
      .attr('class', 'tooltip');

    const myHitbox = svg.append('rect')
      .attr('class', 'hitbox')
      .attr('width', width - MARGINS.right)
      .attr('transform', `translate(0, ${MARGINS.top})`)
      .attr('height', data.length * BLOCK_HEIGHT)
      .attr('fill', 'transparent');

    setDrawLayer(myDrawLayer);
    setTooltip(myTooltip);
    setHitbox(myHitbox);
    drawChart(myDrawLayer, myTooltip, myHitbox);
  });

  useEffect(() => {
    if (drawLayer && tooltip && hitbox) {
      drawChart(drawLayer, tooltip, hitbox);
    }
  }, [data, hitbox, tooltip, drawLayer, drawChart]);

  return (<div id="trellis-chart" />);
};

export const TrellisChart = ({ width, data }: TrellisChartProps) => (
  <D3Container
    width={width}
    data={data}
  />
);
