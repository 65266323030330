import React from 'react';
import {
  WppActionButton,
  WppIconEdit,
  WppIconTrash,
  WppPill,
  WppPillGroup,
} from 'buildingBlocks';
import { deletePanel } from './style';

type Props = {
  handleDeleteRowAction: React.MouseEventHandler<HTMLWppActionButtonElement>
  handleEditRowAction: React.MouseEventHandler<HTMLWppActionButtonElement>
  selectedRowCount: number
  handleDeselectAll?: () => void
};

const DeleteRowPanel: React.FC<Props> = ({
  handleDeleteRowAction, selectedRowCount, handleDeselectAll, handleEditRowAction,
}: Props): React.ReactElement => (
  <div style={deletePanel}>
    <WppPillGroup type="display">
      <WppPill label={`${selectedRowCount} selected`} value={selectedRowCount} removable onWppClose={handleDeselectAll} />
    </WppPillGroup>
    <WppActionButton variant="destructive" onClick={handleDeleteRowAction}>
      Delete
      <WppIconTrash slot="icon-start" />
    </WppActionButton>
    <WppActionButton variant="primary" onClick={handleEditRowAction}>
      Edit
      <WppIconEdit slot="icon-start" />
    </WppActionButton>
  </div>
);

export default DeleteRowPanel;
