import React from 'react';
import { WppTableProps } from '../types';
import '../style.scss';

const WppTableHeader: React.FC<WppTableProps> = ({ children, style, className }: WppTableProps): React.ReactElement => (
  <thead className={`wpp-table__header${className ? ` ${className}` : ''}`} style={style}>
    {children}
  </thead>
);

export default WppTableHeader;
