import _ from 'lodash';
import numeral from 'numeral';
import { bisectLeft, format, select, ScaleOrdinal, ScaleLinear, Series } from 'd3';
import { Moment } from 'moment';
import { chartShortDate, createDate } from 'charts/utils';
import { CROSS_PLATFORM_ROAS_GOAL_NAME, GOAL_TYPES, MetricsFormattingConstants, RevenueType } from 'constantsBase';
import { safeDivision } from 'utils/calculations';
import { roundToNearest } from 'utils/functionHelpers';
import { MetricsConstant } from 'utils/types';
import {
  BudgetType,
  PacingDatum,
  PacingBoundsDatum,
  CumulativeChildPacing,
  ChildSettings,
  StackingDatum,
  GenericSelection,
  XScale,
  IntelligentChildObjectMap,
  PerformanceData,
} from './types';
import {
  MARGINS, STACKEDCHART_HEIGHT, LINECHART_HEIGHT, BudgetTypeMapping,
} from './constants';

const getCorrectGoalName = (strategyGoal: string) => (_.isEqual(strategyGoal, CROSS_PLATFORM_ROAS_GOAL_NAME) ? GOAL_TYPES.conversionRevenue.value : strategyGoal);

export const getPerformanceLabel = (strategyGoal: string, isCustomGoal: boolean) => {
  if (isCustomGoal) {
    return GOAL_TYPES.awgCreateYourOwn.shortText;
  }
  const goalType = getCorrectGoalName(strategyGoal);
  return GOAL_TYPES[goalType].shortText;
};

export const getPerformanceFormat = (strategyGoal: string, metrics: MetricsConstant) => {
  const goalType = getCorrectGoalName(strategyGoal);
  return (metrics.ratePercentage[goalType] || metrics.aggregator[goalType]).format;
};

export const getPacingUnitsFormat = (budgetType: string, revenueType: RevenueType, metricsConstant: MetricsConstant) => {
  if (!_.isNil(revenueType)) {
    return MetricsFormattingConstants.ROUNDED_NO_DECIMALS;
  }
  return metricsConstant.aggregator[BudgetTypeMapping[budgetType]].vizFormat;
};

export const createAxisLabel = (label: string, container: GenericSelection, yRange: Array<number>) => {
  container.append('g')
    .attr('class', 'y-axis-label')
    .selectAll('text')
    .data([label], _.identity)
    .join((enter) => enter.append('text')
      .attr('transform', `translate(${-MARGINS.left * 0.75},${(yRange[0] + yRange[1]) / 2}) rotate(-90)`)
      .text(_.identity));
};

export const createHorizontalLine = (container: GenericSelection, y: number) => {
  container.append('div')
    .attr('class', 'horizontal-divider')
    .style('position', 'absolute')
    .style('top', `${Math.ceil(y)}px`);
};

export const createEventBudgetDeliveredMarker = (
  svg: GenericSelection,
  yDimensions: { [key: string]: number },
  x: XScale,
  negativeBudgetDate: Moment,
) => {
  // only show marker if negativeBudgetDate is within x-scale bounds
  const [minDate, maxDate] = _.map(x.domain(), (d) => createDate(d));
  if (!negativeBudgetDate.isBetween(minDate, maxDate, null, '[]')) {
    return;
  }

  const xPosition = x(negativeBudgetDate);
  const budgetDeliveredGroup = svg.select('#budget-delivered-group')
    .attr('transform', `translate(${MARGINS.left + xPosition}, ${MARGINS.top})`);

  const budgetDeliveredMarker = budgetDeliveredGroup.append('g')
    .attr('id', 'marker')
    .attr('transform', `translate(${-6}, 0)`);

  budgetDeliveredMarker.append('polygon')
    .attr('transform', `translate(0, ${-6})`)
    .attr('points', '0,0 12,0 6.01,6.01');

  budgetDeliveredMarker.append('text')
    .text('Lifetime Budget Delivered')
    .attr('dy', -15);

  budgetDeliveredGroup.append('rect')
    .attr('width', x.range()[1] - x(negativeBudgetDate))
    .attr('height', yDimensions.pacingLineChart);
};

export const createFlightAttachmentMarker = (
  svg: GenericSelection,
  yDimensions: { [key: string]: number },
  x: XScale,
  flightAttachmentTime: Moment,
  negativeBudgetDate?: Moment,
) => {
  // only show marker if flightAttachmentTime is within x-scale bounds
  const [minDate, maxDate] = _.map(x.domain(), (d) => createDate(d));

  if (!flightAttachmentTime.isBetween(minDate, maxDate, null, '[]')) {
    return;
  }

  const flightAttachmentGroup = svg.append('g')
    .attr('id', 'flight-attachment-group')
    .attr('transform', `translate(${MARGINS.left}, ${MARGINS.top})`);

  const flightAttachmentMarker = flightAttachmentGroup.append('g')
    .attr('id', 'marker')
    .attr('transform', `translate(${x(flightAttachmentTime) - 6}, 0)`);

  flightAttachmentMarker.append('polygon')
    .attr('transform', `translate(0, ${-6})`)
    .attr('points', '0,0 12,0 6.01,6.01');

  const avoidTextOverlap = !!negativeBudgetDate && (negativeBudgetDate > flightAttachmentTime);
  flightAttachmentMarker.append('text')
    .text('Copilot activated')
    .attr('dy', -15)
    .attr('dx', avoidTextOverlap ? 6 : 0)
    .attr('text-anchor', avoidTextOverlap ? 'end' : 'start');

  flightAttachmentGroup.append('rect')
    .attr('width', x(flightAttachmentTime))
    .attr('height', yDimensions.pacingLineChart);

  flightAttachmentGroup.append('rect')
    .attr('transform', `translate(0, ${_.sum([
      yDimensions.pacingLineChart,
      yDimensions.divider1,
    ])})`)
    .attr('width', x(flightAttachmentTime))
    .attr('height', yDimensions.stackedAreaChart);

  flightAttachmentGroup.append('rect')
    .attr('transform', `translate(0, ${_.sum([
      yDimensions.pacingLineChart,
      yDimensions.divider1,
      yDimensions.stackedAreaChart,
      yDimensions.divider2,
    ])})`)
    .attr('width', x(flightAttachmentTime))
    .attr('height', yDimensions.performanceLineChart);
};

export const initHoverBehavior = (
  container: GenericSelection,
  svg: GenericSelection,
  stackedData,
  stackedDataWithICOSeparated,
  origToClone: IntelligentChildObjectMap,
  cumulativePerformanceData: PerformanceData,
  pacingData: Array<PacingDatum>,
  xScale: XScale,
  width: number,
  height: number,
  goalTypeKey: string,
  strategyGoalType: string,
  estimatedKPIKey: string,
  unit: string,
  stackedAreaChart: GenericSelection,
  yPacingLine: ScaleLinear<number, number>,
  yPerformance: ScaleLinear<number, number>,
  colorScale,
  pacingBounds: Array<PacingBoundsDatum>,
  flightAttachmentTime: Moment,
  metricsConstant: MetricsConstant,
) => {
  const sortedDates = _.sortedUniq(_.sortBy(_.map(pacingData, (d) => d.date))) as unknown as Array<Date>;
  svg.on('mousemove touchmove', () => {
    const date = xScale.invert((event as MouseEvent).clientX - MARGINS.left - (svg.node() as SVGSVGElement).getBoundingClientRect().left);

    // TODO: replace this with our own custom bisect function so it always the closest data point, right or left
    const closestDateIdx = _.min([bisectLeft(sortedDates, date), sortedDates.length - 1]);
    const closestDate = sortedDates[closestDateIdx];

    const pacingDatum = _.find(pacingData, (d) => _.isEqual(d.date, closestDate));
    const pacing = pacingDatum[unit];

    const pacingBoundsDatum = _.find(pacingBounds, (d) => _.isEqual(d.date, closestDate));

    const matchingPerformanceDatum = _.find(cumulativePerformanceData, (d) => _.isEqual(d.date, closestDate));
    const performance = matchingPerformanceDatum[goalTypeKey];
    const estimatedPerformance = matchingPerformanceDatum[estimatedKPIKey];
    container.selectAll('#hover-group').remove();

    const hoverGroup = container.append('div')
      .attr('id', 'hover-group');

    hoverGroup.append('div')
      .attr('id', 'hover-line')
      .style('height', `${height - yPacingLine(pacing) + 4}px`)
      .style('transform', `translate(${MARGINS.left + xScale(closestDate) - 0.5}px, 
      ${MARGINS.top + yPacingLine(pacing)}px)`);

    hoverGroup.append('div')
      .attr('id', 'hover-date')
      .style('left', `${MARGINS.left + xScale(closestDate)}px`)
      .style('top', `${MARGINS.top + height + 4}px`)
      .text(chartShortDate(closestDate));

    // pacing marker
    if (!_.isNil(pacing)) {
      hoverGroup.append('div')
        .attr('id', 'pacing-marker')
        .attr('class', 'marker')
        .style('left', `${MARGINS.left + xScale(closestDate)}px`)
        .style('top', `${MARGINS.top + yPacingLine(pacing)}px`)
        .style('width', '12px')
        .style('height', '12px')
        .append('span')
        .classed('flipped', xScale(closestDate) > width / 2)
        .text(() => format('.0f')(pacing));
    }

    if (pacingBoundsDatum) {
      hoverGroup.append('div')
        .attr('id', 'bounds-desc')
        .style('left', `${MARGINS.left + xScale(closestDate)}px`)
        .style('top', `${LINECHART_HEIGHT - MARGINS.top}px`)
        .classed('flipped', xScale(closestDate) > width / 2)
        .html(`<span><strong>Pacing Target</strong></span><br/>
        ${format('.0f')(pacingBoundsDatum.lowerBound)} - ${format('.0f')(pacingBoundsDatum.upperBound)}`);
    }

    // stackedAreaChart labels
    stackedAreaChart.selectAll('.layer')
      .nodes().map((d) => select(d).datum());

    const stackedPopup = hoverGroup.append('div')
      .attr('id', 'stacked-popup')
      .style('height', `${STACKEDCHART_HEIGHT - MARGINS.top - 10}px`)
      .style('left', `${MARGINS.left + xScale(closestDate)}px`)
      .style('top', `${LINECHART_HEIGHT + MARGINS.top - 11}px`)
      .classed('flipped', xScale(closestDate) > width / 2);

    stackedPopup.selectAll('.stacked-val')
      // need to reverse data b/c we want to have child items appear in alpha order
      .data(_.reverse([...stackedData]))
      .enter()
      .filter((d) => !!d[closestDateIdx].data[d.key])
      .append('div')
      .attr('class', 'stack-val')
      .style('height', (d) => `${(d[closestDateIdx][1] - d[closestDateIdx][0]) * 2}px`)
      .html((d) => {
        const { key } = d;
        const bgColor = colorScale(key);
        const relevantSeparatedDatum = _.find(stackedDataWithICOSeparated, { key })[closestDateIdx];
        const percentage = relevantSeparatedDatum.data[key] / 100;

        if (_.has(origToClone, key)) {
          // orig / clone will render side by side
          const clone = _.find(stackedDataWithICOSeparated, { key: origToClone[key] });
          const clonePercentage = (clone[closestDateIdx].data[origToClone[d.key]] ?? 0) / 100;

          return `<span>
            <span style="background-color: ${bgColor};">
             ${format('.0%')(percentage)}
             </span>
             <span class='clone'>${format('.0%')(clonePercentage)}</span>
            </span>`;
        }
        return `<span style="background-color: ${bgColor};">${format('.0%')(percentage)}</span>`;
      });

    // performance markers
    const performanceMarkers = hoverGroup.append('div')
      .attr('id', 'performance-markers')
      .style('left', `${MARGINS.left + xScale(closestDate)}px`)
      .style('top', `${LINECHART_HEIGHT + STACKEDCHART_HEIGHT + MARGINS.top}px`);

    const performanceMarkerData = [
      { metric: performance, id: 'performance', larger: performance >= estimatedPerformance },
      { metric: estimatedPerformance, id: 'estimated-performance', larger: estimatedPerformance > performance },
    ];

    // only show estimated markers after copilotAttachmentTime
    const includeEstimatedMarker = createDate(closestDate) > flightAttachmentTime;
    const includedPerformanceMarkerData = includeEstimatedMarker ? performanceMarkerData : _.slice(performanceMarkerData, 0, 1);
    const goalType = getCorrectGoalName(strategyGoalType);
    performanceMarkers.selectAll('div')
      .data(includedPerformanceMarkerData)
      .enter()
      .append('span')
      .attr('id', (d) => d.id)
      .style('top', (d) => `${yPerformance(d.metric)}px`)
      .attr('class', (d) => {
        const flipped = xScale(closestDate) > width / 2;
        const positionX = flipped ? 'left' : 'right';
        const positionY = d.larger ? 'over' : 'under';
        return `${positionX}_${positionY}`;
      })
      .html((d) => {
        if (_.isNil(d.metric)) {
          return null;
        }
        return numeral(d.metric).format((
          metricsConstant.ratePercentage[goalType] || metricsConstant.aggregator[goalType])
          .format);
      });

    performanceMarkers
      .selectAll('.marker')
      .data(includedPerformanceMarkerData)
      .join('div')
      .attr('class', 'marker')
      .attr('id', (d) => d.id)
      .style('top', (d) => `${yPerformance(d.metric)}px`)
      .style('display', (d) => (_.isNil(d.metric) ? 'none' : 'block'));
  }).on('mouseleave touchleave', () => {
    container.selectAll('#hover-group').remove();
  });
};

const getCumulativeNumAndPercentageFormatted = (
  cumulativePacingChildrenData,
  key: string,
  origToClone: IntelligentChildObjectMap,
  totalAccumulatedBudget: number,
  hasClone: boolean,
  separateICOs: boolean,
): { formattedNum: string, formattedPercentage: string } => {
  const childPacingData = cumulativePacingChildrenData[key];
  const childTotalAccumulatedBudget = !_.isNil(childPacingData) ? childPacingData.totalAccumulatedBudget : 0;
  let num = childTotalAccumulatedBudget;
  if (hasClone && !separateICOs) {
    const clonePacingData = _.get(cumulativePacingChildrenData, origToClone[key]);
    const cloneTotalAccumulatedBudget = !_.isNil(clonePacingData) ? clonePacingData.totalAccumulatedBudget : 0;
    num = _.sum([childTotalAccumulatedBudget, cloneTotalAccumulatedBudget]);
  }
  const percentage = safeDivision(num, totalAccumulatedBudget);
  return {
    formattedNum: numeral(num).format(MetricsFormattingConstants.ROUNDED_ONE_DECIMAL),
    formattedPercentage: numeral(percentage).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS),
  };
};

export const buildLegend = (
  budgetType: BudgetType,
  yDimensions: { [key: string]: number },
  stackedDataWithICOSeparated: Series<StackingDatum, string>[],
  origToClone: IntelligentChildObjectMap,
  cloneToOrig: IntelligentChildObjectMap,
  colorScale: ScaleOrdinal<string, string>,
  performanceLabel: string,
  totalAccumulatedBudget: number,
  cumulativePacingChildrenData: CumulativeChildPacing,
  childExtIdToSettings: ChildSettings,
) => {
  const legend = select('#legend');
  const label = _.startCase(BudgetTypeMapping[budgetType]);
  legend.select('#pacing')
    .style('margin-top', `${MARGINS.top / 2}px`)
    .style('height', `${_.sum([MARGINS.top / 2, yDimensions.pacingLineChart, (yDimensions.divider1 / 2)])}px`);

  legend.selectAll('.label')
    .text(label);

  const budgetAllocation = legend.select('#budget-allocation')
    .style('height', `${_.sum([yDimensions.stackedAreaChart,
      (yDimensions.divider1 / 2), (yDimensions.divider2 / 2)])}px`);

  budgetAllocation.select('table tbody')
    .selectAll('.child-item-row')
    // need to reverse data b/c we want to have child items appear in alpha order
    .data(_.reverse([...stackedDataWithICOSeparated]))
    .join((enter) => enter
      .append('tr')
      .classed('child-item-row', true)
      .classed('expanded', false)
      .classed('isClone', (d) => _.has(cloneToOrig, d.key))
      .classed('hasClone', (d) => _.has(origToClone, d.key))
      .attr('id', (d) => `child_${d.key}`)
      .attr('data-clone', (d) => _.get(origToClone, d.key, null))
      .html(function buildContent(child) {
        const { key } = child;
        const childName = _.get(childExtIdToSettings[key], 'name', `${key} Unknown/Deleted`);

        const hasClone = select(this).classed('hasClone');

        const { formattedNum, formattedPercentage } = getCumulativeNumAndPercentageFormatted(cumulativePacingChildrenData, key, origToClone, totalAccumulatedBudget, hasClone, false);

        const style = `background-color:${colorScale(_.get(cloneToOrig, key, key))}`;
        return `${hasClone ? "<td class='expandIcon'><i class='chevron right icon'/></td>" : '<td />'}
        <td><span class='square' style=${style}></span></td>
            <td class='childname' title=${childName.replace(/[ ]/g, '\u00a0')}>
              ${childName}
            </td>
          </td>
          <td class='delivery'>${formattedNum}</td>
          <td class='percentage'>
            ${formattedPercentage}
          </td>`;
      }))
    .each(function registerExpandButtonClick(d) {
      const tableRowSelection = select(this);
      select(this).select('.expandIcon i')
        .on('click', function onExpandClick() {
          const icon = select(this);
          const cloneId = tableRowSelection.attr('data-clone');

          const clone = select(`tr#child_${cloneId}`);
          if (clone.classed('expanded')) {
            const { formattedNum, formattedPercentage } = getCumulativeNumAndPercentageFormatted(cumulativePacingChildrenData, d.key, origToClone, totalAccumulatedBudget, true, false);
            tableRowSelection.select('.delivery').text(formattedNum);
            tableRowSelection.select('.percentage').text(formattedPercentage);
            tableRowSelection.classed('expanded', false);
            clone.classed('expanded', false);
            icon.classed('right', true);
            icon.classed('down', false);
          } else {
            const { formattedNum, formattedPercentage } = getCumulativeNumAndPercentageFormatted(cumulativePacingChildrenData, d.key, origToClone, totalAccumulatedBudget, true, true);
            tableRowSelection.select('.delivery').text(formattedNum);
            tableRowSelection.select('.percentage').text(formattedPercentage);
            tableRowSelection.classed('expanded', true);
            clone.classed('expanded', true);
            icon.classed('down', true);
            icon.classed('right', false);
          }
        });
    });

  const performanceLegend = legend.select('#performance')
    .style('height', `${_.sum([yDimensions.performanceLineChart, (yDimensions.divider2 / 2), MARGINS.bottom])}px`);

  performanceLegend.selectAll('.kpi')
    .text(performanceLabel);
};

// area generator for when we only have a single array of data. The default areaGenerator would produce a path
// with zero area, but instead we need to have our stackedAreaChart be full width
export const areaGeneratorForSinglePointData = (
  x: XScale,
  yStackedArea: ScaleLinear<number, number>,
) => (d) => {
  const datum = d[0];
  const startingX = x.range()[0];
  const endingX = x.range()[1];
  const startingY = yStackedArea(datum[0]);
  const endingY = yStackedArea(datum[1]);
  return `M${startingX},${startingY}L${endingX},${startingY}L${endingX},${endingY}L${startingX},${endingY}Z`;
};

export const getEstimatedKPIKey = (strategyGoalType: string): string => `unoptimized${_.upperFirst(strategyGoalType)}`;

export const getPacingCopy = (dailyParentBudgetInflationRatio: number = 1) => {
  const lower = roundToNearest(100 * dailyParentBudgetInflationRatio, 5);
  const upper = roundToNearest(120 * dailyParentBudgetInflationRatio, 5);
  return `The model will aim for smooth delivery between ${lower} - ${upper}% of the required daily target.`;
};
