import React from 'react';
import { Image } from 'buildingBlocks';

export default () => (
  <div className="copilot-indicator">
    <Image
      src="/img/copilot-icon-new.png"
    />
  </div>
);
