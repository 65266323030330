import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const SELECTED_OPTIONS_STYLES: { [key: string]: CSSProperties } = {
  agGrid: {
    minHeight: '520px',
  },
};

export const OPTIONS_STYLES: { [key: string]: CSSProperties } = {

  container: {
    backgroundColor: 'var(--wpp-grey-color-100)',
    padding: '12px 16px',
  },
  header: {
    margin: '12px 0px',
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  listItem: {
    marginBottom: SPACING[4],
  },
  noOptionsFound: {
    display: 'block',
    textAlign: 'center',
  },
  listItems: {
    height: '400px',
    overflowY: 'auto',
  },
};
