import React, { useEffect, useContext } from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import {
  select,
  area,
  line,
  scaleUtc,
  extent,
  scaleLinear,
  axisBottom,
  axisLeft,
  bisectLeft,
  timeDays,
} from 'd3';
import { GOAL_VALUE_TYPE } from 'constantsBase';
import { ObjectDropdown } from 'buildingBlocks';
import { createDate, chartShortDate } from 'charts/utils';
import { GoalType } from 'utils/types';
import { ColorContext, ABColorContext, ABColors, METRIC_TYPES } from 'charts/ABReportViz/ABReportViz';
import {
  KPIOptions as KPIOptionsType,
  KPIsByVariantWithVariant,
  KPIsByVariantWithDate,
  KPIsWithDate,
} from 'containers/ABInsights/types';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import VizHeader from 'charts/InsightsViz/Components/VizHeader';
import Legend from './ABLegend';

const HEIGHT = 388;
const WIDTH = 930;
const MARGINS = { left: 48, top: 0, right: 48, bottom: 48 };
const ARBITRARY_SINGLE_DATUM = ['single-datum'];
const TRANSITION_TIME = 500;

const getYAxisUnits = (additionalFormatting: string, currency: string): string => {
  switch (additionalFormatting) {
    case GOAL_VALUE_TYPE.CURRENCY:
      return currency;
    case GOAL_VALUE_TYPE.PERCENTAGE:
      return '%';
    default:
      return '';
  }
};

export const getSortedDates = (aData: Array<KPIsWithDate>, bData: Array<KPIsWithDate>) => _.sortBy(_.map(_.uniqBy([...aData, ...bData], (d) => d.date), (d) => createDate(d.date)), (d) => d.unix());

export type MetricType = 'daily' | 'cumulative';
const buildTimeSeries = (
  vizId: string,
  data: KPIsByVariantWithDate,
  selectedMetric: Metric,
  selectedMetricType: MetricType,
  colors: ABColors,
  currency: string,
) => {
  const { value: selectedMetricValue, format: selectedMetricFormat, additionalFormatting } = selectedMetric;

  const { a: aData, b: bData } = data[selectedMetricType];

  const sortedDates = getSortedDates(aData, bData);

  const x = scaleUtc()
    .domain(extent(sortedDates))
    .range([0, WIDTH - MARGINS.left - MARGINS.right]);

  const [yMin, yMax] = extent([...aData, ...bData], (d) => d[selectedMetricValue]);

  const yDiff = _.subtract(yMax, yMin);
  const yPadding = yDiff * 0.25;
  const y = scaleLinear()
    .domain([0, yMax + yPadding])
    .range([HEIGHT - MARGINS.top - MARGINS.bottom, 0]);

  const lineA = line<KPIsWithDate>()
    .x((d) => x(createDate(d.date)))
    .y((d) => y(d[selectedMetricValue]));

  const lineB = line<KPIsWithDate>()
    .x(lineA.x())
    .y((d) => y(d[selectedMetricValue]));

  const areaAboveA = area<KPIsWithDate>()
    .x(lineA.x())
    .y0(lineA.y())
    .y1(0);

  const areaBelowA = area<KPIsWithDate>()
    .x(lineA.x())
    .y0(lineA.y())
    .y1(y.range()[0]);

  const areaAboveB = area<KPIsWithDate>()
    .x(lineB.x())
    .y0(lineB.y())
    .y1(0);

  const areaBelowB = area<KPIsWithDate>()
    .x(lineB.x())
    .y0(lineB.y())
    .y1(y.range()[0]);

  const svg = select(`#${vizId} svg`)
    .attr('width', WIDTH)
    .attr('height', HEIGHT);
  const container = svg
    .select('#container')
    .attr('transform', `translate(${MARGINS.left},${MARGINS.top})`);
  const wrapper = svg.select('#timeseries-wrapper');

  container.select('#hitbox')
    .attr('width', WIDTH - MARGINS.left - MARGINS.right)
    .attr('height', HEIGHT - MARGINS.top - MARGINS.bottom);

  const defs = svg.selectAll('defs')
    .data(ARBITRARY_SINGLE_DATUM)
    .join('defs');
  /*
   In order to shade the area between the two lines, we need to define clip paths so that area below A
   will be clipped by area above B and vice versa
  */
  defs.selectAll('clipPath')
    .data([
      { data: aData, id: 'clip-A', pathGen: areaAboveA },
      { data: bData, id: 'clip-B', pathGen: areaAboveB },
    ])
    .join(
      (e) => e
        .append('clipPath')
        .attr('id', (d) => d.id)
        .append('path')
        .attr('d', (d) => d.pathGen(d.data)),
      (update) => update
        .select('path').call((updateSelection) => updateSelection
          .transition()
          .duration(TRANSITION_TIME)
          .attr('d', (d) => d.pathGen(d.data))),
      (exit) => exit.remove(),
    );

  // path area should be filled with color of flight whose metric is better
  const [fillBelowA, fillBelowB] = selectedMetric.lowerIsBetter ? [colors.b, colors.a] : [colors.a, colors.b];

  wrapper.selectAll('.area')
    .data([
      { data: aData, id: 'area-below-A', clipId: 'clip-B', fill: fillBelowA, pathGen: areaBelowA },
      { data: bData, id: 'area-below-B', clipId: 'clip-A', fill: fillBelowB, pathGen: areaBelowB },
    ])
    .join(
      (e) => e.append('path')
        .attr('id', (d) => d.id)
        .attr('class', 'area')
        .attr('clip-path', (d) => `url(#${d.clipId})`)
        .attr('fill-opacity', 0.3),
      (update) => update,
      (exit) => exit.remove(),
    )
    .attr('fill', (d) => d.fill)
    .transition()
    .duration(TRANSITION_TIME)
    .attr('d', (d) => d.pathGen(d.data));

  wrapper.selectAll<any, any>('.line')
    .data([
      { data: aData, id: 'line-A', stroke: colors.a, pathGen: lineA },
      { data: bData, id: 'line-B', stroke: colors.b, pathGen: lineB },
    ])
    .join(
      (enter) => enter.append('path')
        .attr('id', (d) => d.id)
        .attr('class', 'line')
        .attr('fill', 'transparent')
        .attr('stroke-width', 2.5),
      (update) => update,
      (exit) => exit.remove(),
    )
    .attr('stroke', (d) => d.stroke)
    .transition()
    .duration(TRANSITION_TIME)
    .attr('d', (d) => d.pathGen(d.data));

  const [minX, maxX] = x.domain();
  const numDays = timeDays(minX, maxX).length;

  container.selectAll('#x-axis')
    .data(ARBITRARY_SINGLE_DATUM)
    .join((enter) => enter.append('g')
      .attr('id', 'x-axis')
      .classed('minor-ticks', numDays >= 8)
      .attr('transform', `translate(0,${y.range()[0]})`))
    .call(axisBottom(x)
      .ticks(Math.min(numDays, 8))
      .tickSize(8)
      .tickPadding(8)
      .tickFormat((v) => chartShortDate(v as Date)))
    .call((g) => g.select('.domain').remove());

  container.selectAll('#y-axis')
    .data(ARBITRARY_SINGLE_DATUM)
    .join((enter) => enter.append('g').attr('id', 'y-axis'))
    .call((g) => g.selectAll('.unit-label')
      .data([ARBITRARY_SINGLE_DATUM])
      .join((e) => e.append('text'))
      .text(getYAxisUnits(additionalFormatting, currency))
      .attr('class', 'unit-label')
      .attr('dx', -5)
      .attr('dy', -10))
    .transition()
    // @ts-ignore
    .call(axisLeft(y)
      .ticks(4)
      .tickSize(8)
      .tickPadding(4)
      .tickFormat((d) => numeral(d).format(selectedMetricFormat)))
    .call((g) => g.select('.domain').remove());

  container.on('mousemove touchmove', () => {
    container.selectAll('#hover-group').remove();

    const date = x.invert((event as MouseEvent).clientX - (container.node() as SVGSVGElement).getBoundingClientRect().left - MARGINS.left);

    // TODO: replace this with our own custom bisect function so it always the closest data point, right or left
    // @ts-ignore
    const closestDateIdx = bisectLeft(sortedDates, date);

    const closestDate = sortedDates[closestDateIdx];
    const matchingKPIsByVariantWithDataA = _.find(aData, (d) => _.isEqual(createDate(d.date), closestDate));
    const matchingKPIsByVariantWithDataB = _.find(bData, (d) => _.isEqual(createDate(d.date), closestDate));

    const hoverGroup = container
      .append('g')
      .attr('id', 'hover-group')
      .attr('transform', `translate(${x(closestDate)},0)`);

    hoverGroup.append('line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', y.range()[0]);

    hoverGroup.append('text')
      .attr('id', 'date')
      .attr('transform', `translate(0,${y.range()[0]})`)
      .text(chartShortDate(closestDate))
      .attr('dy', 24);

    const valueA = _.get(matchingKPIsByVariantWithDataA, selectedMetricValue);
    const valueB = _.get(matchingKPIsByVariantWithDataB, selectedMetricValue);

    const markers = hoverGroup.selectAll('.marker')
      .data(_.filter([
        { metric: valueA, fill: colors.a, greater: valueA >= valueB },
        { metric: valueB, fill: colors.b, greater: valueA < valueB },
      ], (d) => !_.isNil(d.metric)))
      .enter()
      .append('g')
      .attr('class', 'marker')
      .attr('transform', (d) => `translate(0,${y(d.metric)})`);
    markers.append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 5)
      .attr('fill', (d) => d.fill);
    markers.append('text')
      .text((d) => numeral(d.metric).format(selectedMetricFormat))
      .attr('dx', 6)
      .attr('dy', (d) => 10 * (d.greater ? -1 : 1))
      .attr('dominant-baseline', (d) => (d.greater ? 'baseline' : 'hanging'))
      .attr('fill', (d) => d.fill);
  }).on('mouseleave touchleave', () => {
    container.selectAll('#hover-group').remove();
  });
};

type Props = {
  vizId: string
  data: KPIsByVariantWithDate
  KPIOptions: KPIOptionsType
  legendData: KPIsByVariantWithVariant
  currency: string
  tooltipContent: string
  selectedKPI: Metric & GoalType
  setSelectedKPI: Function
  selectedMetricType: MetricType
  setSelectedMetricType: Function
};

const TimeSeriesChart = ({
  vizId,
  data,
  KPIOptions,
  legendData,
  currency,
  tooltipContent,
  selectedKPI,
  setSelectedKPI,
  selectedMetricType,
  setSelectedMetricType,
}: Props) => {
  const [colors] = useContext<ABColorContext>(ColorContext);
  useEffect(() => {
    buildTimeSeries(vizId, data, selectedKPI, selectedMetricType, colors, currency);
  }, [vizId, data, selectedKPI, selectedMetricType, colors, currency]);
  return (
    <div className="main-visualization">
      <VizHeader
        title="Performance over Time"
        tooltipContent={tooltipContent}
      />
      <div className="controls">
        <ObjectDropdown
          // @ts-ignore passthrough props
          id="AB-report-metric-type-selector"
          className="new-color-palette"
          options={METRIC_TYPES}
          keyFn={(d) => _.capitalize(d)}
          selection
          compact
          onChange={setSelectedMetricType}
          value={selectedMetricType}
        />
        <ObjectDropdown
          // @ts-ignore passthrough props
          id="AB-report-metric-selector"
          className="new-color-palette"
          options={KPIOptions}
          keyFn={(d) => d.text}
          selection
          compact
          onChange={setSelectedKPI}
          value={selectedKPI}
        />
      </div>
      <svg>
        <g id="container">
          <g id="timeseries-wrapper" />
          <rect id="hitbox" />
        </g>
      </svg>
      <Legend
        id="timeseries-legend"
        data={legendData}
        type="line"
      />
    </div>
  );
};

export default TimeSeriesChart;
