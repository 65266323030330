import React, { CSSProperties } from 'react';
import { Popup, Icon } from 'buildingBlocks';

type WarningPopupProps = {
  text: string,
  style?: CSSProperties,
};

export const WarningPopup = (props: WarningPopupProps) => (
  <Popup
    style={{ borderTopWidth: '6px', borderTopColor: 'orange' }}
    content={props.text}
    position="right center"
    trigger={<Icon style={props.style} name="exclamation circle" color="orange" />}
  />
);
