import React from 'react';
import { WppTypography, WppObjectSpinner } from 'buildingBlocks';

type SpinnerProps = {
  label?: string
  size?: 'l' | 'm' | 's'
  color?: string
};

const Spinner: React.FC<SpinnerProps> = (props: SpinnerProps): React.ReactElement => {
  const { label, ...rest } = props;
  return (
    <>
      <WppObjectSpinner {...rest} />
      {label && <WppTypography type="s-body" tag="p">{label}</WppTypography>}
    </>
  );
};
export default Spinner;
