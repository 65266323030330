/**
 * Wrapper for semantic Table
 */
import React from 'react';
import _ from 'lodash';
import {
  Table as SemanticTable,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  TableBody,
  TableProps,
} from 'semantic-ui-react';

/**
 * Table is a thin wrapper around the Semantic UI `<table />`, but omits certain
 * props to keep styling consistent.
 */
const Table = (props: TableProps) => (
  <SemanticTable {..._.omit(props, ['color', 'inverted'])} />);

Table.Header = TableHeader;
Table.Row = TableRow;
Table.HeaderCell = TableHeaderCell;
Table.Body = TableBody;
Table.Cell = TableCell;

export default Table;
