import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { OrderedMap } from 'immutable';
import { Dimmer, WppGrid, WppInput, WppSpinner } from 'buildingBlocks';
import { ColDef, InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import SelectedOptionsList from './SelectedOptionsList';
import OptionsList from './OptionsList';

export type DataProviderResponse = {
  elements?: Array<unknown>
  count?: number
};

export type Input = {
  onChange: Function
  onBlur: Function
  value: Array<unknown>
  name: string
};

export type DataProviderArgs = { search: string };
export type DataProviderPaginatedArgs = DataProviderArgs & { limit: number, skip: number };

export interface DataProviderInterface {
  queryWithPagination(x: DataProviderPaginatedArgs): Promise<DataProviderResponse>
}

export type MultiSelectProps = {
  selectedGridHeaderData: (disabled: boolean, displayFn: (x: unknown) => string, onDeselect: Function) => Array<ColDef>
  dataProvider: DataProviderInterface
  pluralize: (x: number) => string
  keyFn: (x: unknown) => string | number
  displayFn: (x: unknown) => string
  sortFn?: ((x: unknown) => string) | null
  value?: Array<any>
  defaultValue?: Array<any>
  loading?: boolean
  loadingMsg?: string
  onChange?: Function
  selectionOptionsListHeader?: JSX.Element
  searchPlaceholder?: string
  disableOptions?: boolean
  disableSelected?: boolean
};

export type MultiSelectState = {
  items: Array<any>
  selectedItems: OrderedMap<unknown, unknown>
  filteredItems: Array<any>
  search: string
  loading: boolean
  value: Array<unknown>
  page: number
};

const extractValueFromMap = <T extends any>(immutableMap: OrderedMap<unknown, T>): Array<T> => (
  Array.from(immutableMap.values())
);

const MultiSelect: React.FC<MultiSelectProps> = (props: MultiSelectProps): React.ReactElement => {
  const {
    sortFn, keyFn, value, defaultValue, searchPlaceholder, dataProvider, loadingMsg, onChange, displayFn, pluralize,
    selectionOptionsListHeader, selectedGridHeaderData,
    disableOptions, disableSelected,
  } = props;
  const sortedMap = OrderedMap((defaultValue || value).map((item) => [keyFn(item), item]));

  const [state, setState] = useState<MultiSelectState>({
    items: [],
    selectedItems: sortFn ? sortedMap.sortBy(sortFn) : sortedMap,
    filteredItems: [],
    search: '',
    loading: false,
    value,
    page: 1,
  });

  // Get selected list data based on search and other query parameters using data provider
  const getData = (args?: { search: string, page: number, isSearchQuery?: boolean, limit?: number, skip?: number }) => {
    const search = _.get(args, 'search', '');
    const limit = _.get(args, 'limit', 50);
    const skip = _.get(args, 'skip', 0);

    setState((prevState: MultiSelectState) => ({ ...prevState, loading: true }));
    dataProvider.queryWithPagination({
      search, skip, limit, ...args,
    }).then((res) => {
      setState((prevState: MultiSelectState) => ({
        ...prevState,
        loading: false,
        items: args.isSearchQuery ? res.elements : [...prevState.items, ...res.elements],
        filteredItems: args.isSearchQuery ? res.elements : [...prevState.filteredItems, ...res.elements],
        page: args.page,
      }));
    });
  };

  useEffect(() => {
    const { search, page } = state;
    getData({ search, page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEqual(value, state.value)) {
      const orderedMap = OrderedMap(_.keyBy(value, keyFn));
      setState((prevState: MultiSelectState) => ({
        ...prevState, value, selectedItems: sortFn ? orderedMap.sortBy(sortFn) : orderedMap,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onItemClicked = (valueParam: any) : void => {
    const key = keyFn(valueParam);
    let selectedItems;
    if (state.selectedItems.has(key)) {
      selectedItems = state.selectedItems.remove(key);
    } else {
      selectedItems = state.selectedItems.set(key, valueParam);
    }
    const sortedSelectedItems = sortFn ? selectedItems.sortBy(sortFn) : selectedItems;
    setState((prevState: MultiSelectState) => ({ ...prevState, selectedItems: sortedSelectedItems }));
    if (onChange) {
      onChange(extractValueFromMap(sortFn ? selectedItems.sortBy(sortFn) : selectedItems));
    }
  };

  const onSearchChange = useCallback((searchQuery: string): void => {
    setState((prevState: MultiSelectState) => ({ ...prevState, search: searchQuery }));
    getData({ search: searchQuery, isSearchQuery: true, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function used for infinite scroll, this function will be called when scroll touch to the bottom of element
  const fetchData = (): void => {
    const { page, search } = state;
    const itemsPerPage = 50;
    const end = ((page + 1) * itemsPerPage);
    const start = end - itemsPerPage;
    getData({ limit: end - start, search, skip: start, page: page + 1 });
  };

  const renderTopHeader = () => (
    <WppInput
      className="search"
      size="s"
      type="search"
      disabled={disableOptions}
      placeholder={searchPlaceholder || 'Search'}
      onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => onSearchChange(event.detail.value)}
    />
  );

  const { filteredItems, selectedItems } = state;
  const loading = props.loading || state.loading;

  return (
    <WppGrid container fullWidth>
      <WppGrid
        item
        all={5}
        style={{ position: 'relative' }}
      >
        <Dimmer active={loading}>
          <WppSpinner label={loadingMsg || ''} />
        </Dimmer>
        <OptionsList
          items={filteredItems}
          selected={selectedItems}
          onSelected={onItemClicked}
          keyFn={keyFn}
          displayFn={displayFn}
          pluralize={pluralize}
          renderTopHeader={renderTopHeader}
          customHeader={selectionOptionsListHeader}
          disabled={disableOptions}
          fetchData={fetchData}
        />
      </WppGrid>
      <WppGrid
        item
        all={19}
      >
        <SelectedOptionsList
          items={extractValueFromMap(selectedItems)}
          onSelected={onItemClicked}
          displayFn={displayFn}
          defaultValue={defaultValue}
          disabled={disableSelected}
          selectedGridHeaderData={selectedGridHeaderData}
        />
      </WppGrid>
    </WppGrid>
  );
};

export default MultiSelect;
