import * as _ from 'lodash';
import * as d3 from 'd3';
import { FEATURES_ALIASES, METRIC_FORMATS } from 'charts/HeliosDataViz/Helios/constants';
import {
  INNER_RADIUS, MIN_BUBBLE_RADIUS, MAX_BUBBLE_RADIUS, MIN_STROKE_WIDTH,
  MAX_STROKE_WIDTH, MIN_STROKE_BRIGHT, MAX_STROKE_BRIGHT, NODATA_COLOR, NODATA_STROKE_COLOR,
} from './constants';
/* eslint-disable no-param-reassign */

export const getMetric = (
  metricsLookup: { [key: string]: any },
  leafName: string,
  metric: string,
  defaultValue = null,
) => _.get(metricsLookup, `${leafName}.${metric}`, defaultValue);

export const jitterNodes = (nodes, radiusScale, metricsLookup, sizeAttr) => nodes.map((n, i) => {
  const sizeMetric = getMetric(metricsLookup, n.data.name, sizeAttr, null);
  const jitterOffset = radiusScale(sizeMetric) * (i % 2 === 0 ? -1 : 1);
  return ({
    ...n,
    y: n.y + jitterOffset,
  });
});

// TODO: this function modifies the tree, but it needs to ... for now ( because we need to maintain
// the methods on the Node class)
export const setRadialLinksRadii = (data) => {
  data.y = 60 * (data.depth - 1) + (2 * INNER_RADIUS);
  data.children = _.map(data.children, setRadialLinksRadii);
  return data;
};

const radialAngle = (data) => {
  const numBranches = data.children?.length || 0;
  const angle = Math.min(numBranches * 0.2, 2);
  return Math.PI * angle;
};

export const tree = (data, width) => (
  d3
    .tree()
    .size([radialAngle(data), width / 2])
    .separation((a) => 1 / a.depth)(
      d3.hierarchy(data),
    )
);

export const getStrokeColor = (value, scale, theme) => {
  const themeOffset = theme === 'dark' ? 0 : 100;
  return (value ? `hsla(192, 99%, ${Math.abs(themeOffset - scale(value))}%, 1)` : NODATA_STROKE_COLOR);
};

export const getBubbleRadius = (value, scale) => (value ? scale(value) : MIN_BUBBLE_RADIUS);

/*
Accepts domain (list of color)
Returns a function that accepts an array of colors and returns a exponential color scale
*/
export const createColorScale = (domain) => (colors: Array<string>) => {
  const expScale = d3.scalePow()
    .exponent(0.5)
    .domain(domain)
    .range([0, 1]);

  const colorScale = d3.interpolateRgbBasis(colors);

  const colorScaleExp = d3.scaleSequential((d) => {
    if (!d) {
      return NODATA_COLOR;
    }
    return colorScale(expScale(d));
  });
  return colorScaleExp;
};

export const createRadiusScale = (domain) => (
  d3
    .scaleLinear()
    .domain(domain)
    .range([MIN_BUBBLE_RADIUS, MAX_BUBBLE_RADIUS])
);

export const createBrightScale = (domain) => (
  d3
    .scalePow()
    .exponent(0.5)
    .domain(domain)
    .range([MIN_STROKE_BRIGHT, MAX_STROKE_BRIGHT])
);

export const createStrokeScale = (domain) => (
  d3
    .scalePow()
    .exponent(0.5)
    .domain(domain)
    .range([MIN_STROKE_WIDTH, MAX_STROKE_WIDTH])
);

export const generateScales = (domains) => ({
  colorScale: createColorScale(domains.color),
  radiusScale: createRadiusScale(domains.size),
  brightScale: createBrightScale(domains.stroke),
  strokeScale: createStrokeScale(domains.stroke),
});

export const getFeaturesList = (data) => {
  const features = [];
  let node = data;
  while (node) {
    features.push({
      feature: node.data.feature,
      featureValue: node.data.featureValue,
    });
    if (node.data.features) {
      return features.concat(_.cloneDeep(node.data.features));
    }
    node = node.parent;
  }
  return features;
};

export const getBaseNodes = (nodes, chartCenter) => (
  nodes.filter((d) => d.depth === 1).map((d) => {
    const coords = d3.pointRadial(d.x, d.y);
    return ({
      ...d,
      isBaseNode: true,
      fx: coords[0] + chartCenter.x,
      fy: coords[1] + chartCenter.y,
    });
  })
);

export const getFeatureValue = (feature, featureValue) => {
  if (feature === 'segment') {
    return featureValue.name;
  }
  if (Array.isArray(featureValue) && featureValue.length > 1) {
    return featureValue.join(', ');
  }
  return _.get(FEATURES_ALIASES, `${feature}.values.${featureValue}`)
    || (METRIC_FORMATS[feature] ? METRIC_FORMATS[feature](featureValue) : featureValue);
};

export const noData = (data) => _.size(_.get(data, 'clusterData.children'))
  + _.size(_.get(data, 'radialData.children')) === 0;

const hasNoMetrics = (child): boolean => _.isEmpty(child.totalMetrics) && _.isEmpty(child.weeklyMetrics);

export const isDataSansMetrics = (data) => _.isNil(data.clusterData) || _.isNil(data.radialData)
  || (_.every(data.clusterData.children, hasNoMetrics)
    && _.every(data.radialData.children, hasNoMetrics));

export const isBonsaiNode = (node) => (!node.data.isLeaf && !node.data.wasLeaf);

export const findNodeByName = (nodeName, chartCenter, radialNodes, clusterNodes) => {
  const radial = _.find(radialNodes, (n) => n.data.name === nodeName);
  if (radial) {
    const coords = d3.pointRadial(radial.x, radial.y);
    return {
      node: radial,
      isRadial: true,
      x: chartCenter.x + coords[0],
      y: chartCenter.y + coords[1],
    };
  }
  const cluster = _.find(clusterNodes, (n) => n.data.name === nodeName);
  return cluster ? {
    node: cluster,
    x: cluster.x,
    y: cluster.y,
  } : null;
};
