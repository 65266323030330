import React, { useState, useEffect, useMemo, useCallback } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { METRIC_COLORS } from 'charts/HeliosDataViz/Helios/constants';
import { getStrokeColor, getMetric, getBubbleRadius, getBaseNodes, isBonsaiNode, findNodeByName } from './utils';
import { PACK_STYLES, TWO_PI, MIN_STROKE_WIDTH } from './constants';
import {
  HeliosTreeChartProps, Layers, Scales, RadialNodes,
  RadialLinks, ZoomTransform, ChartCenter, ShowPopup, ClusterNodes,
} from './types';
import { displayPopup, hidePopup, initCanvasHover, initZoom, buildRadialNodes, buildClusterTree } from './helpers';

const reColorChart = (myLayers: Layers, scales: Scales, metricsLookup, radialNodes: RadialNodes, colorKPI: string) => {
  myLayers.svg
    .selectAll('circle.bubble')
    .data(radialNodes)
    .join('circle')
    .attr('class', 'bubble')
    .attr('fill', (d) => {
      const colorMetric = getMetric(metricsLookup, d.data.name, colorKPI);
      return scales.colorScale(METRIC_COLORS[colorKPI])(colorMetric);
    });
};

const buildLayers = (width: number, height: number, onSvgClick: React.MouseEventHandler): Layers => {
  const ch = d3.select('#helios-tree-chart')
    .style('height', `${height}px`);

  const canvasContext = ch.append('canvas')
    .attr('class', 'drawLayer')
    .attr('width', width)
    .attr('height', height)
    .styles(PACK_STYLES)
    .node()
    .getContext('2d');

  const svg = ch.append('svg')
    .attr('width', width)
    .attr('height', height)
    .styles(PACK_STYLES)
    .on('click', onSvgClick);

  svg.append('rect')
    .attr('class', 'zoomHitbox')
    .attr('width', width)
    .attr('height', height)
    .attr('fill', 'transparent');

  const drawLayer = svg.append('g')
    .attr('class', 'transform-layer')
    .append('g')
    .attr('class', 'draw-layer');

  return {
    svg,
    drawLayer,
    canvasContext,
  };
};

const drawRadialTree = (
  drawLayer: Layers['drawLayer'],
  scales: Scales,
  radialNodes: RadialNodes,
  radialLinks: RadialLinks,
  chartCenter: ChartCenter,
  metricsLookup,
  strokeKPI: string,
  colorKPI: string,
  sizeKPI: string,
  showPopup: ShowPopup,
  userSelectedTheme: string,
) => {
  const { brightScale, strokeScale, colorScale, radiusScale } = scales;
  const radialLayer = drawLayer
    .selectAll('g.radial-layer')
    .data([null])
    .join('g')
    .attr('class', 'radial-layer')
    .attr('transform', `translate(${chartCenter.x}, ${chartCenter.y})`);

  const linkLayer = radialLayer
    .selectAll('g.link-layer')
    .data([null])
    .join('g')
    .attr('class', 'link-layer');

  linkLayer
    .selectAll('path')
    .data(radialLinks)
    .join('path')
    .attr('fill', 'none')
    .attr('stroke-linecap', (d) => (d.target.children.length > 0 ? 'round' : 'none'))
    .attr('stroke-linejoin', 'round')
    .attr('stroke', (d) => {
      const metricNode = isBonsaiNode(d.target) ? d.target.children[0] : d.target;
      const strokeMetric = getMetric(metricsLookup, metricNode.data.name, strokeKPI);
      return getStrokeColor(strokeMetric, brightScale, userSelectedTheme);
    })
    .attr('stroke-width', (d) => {
      const metricNode = isBonsaiNode(d.target) ? d.target.children[0] : d.target;
      const strokeMetric = getMetric(metricsLookup, metricNode.data.name, strokeKPI, 0);
      return strokeScale(strokeMetric) || MIN_STROKE_WIDTH;
    })
    .attr('d', (d) => {
      const source = d3.pointRadial(d.source.x, d.source.y);
      const target = d3.pointRadial(d.target.x, d.target.y);
      const dist = Math.abs(d.source.y - d.target.y);
      const splitAt = dist * (4 / 7);
      const splitPosition = d3.pointRadial(d.source.x, d.source.y + splitAt);
      return `M ${source[0]} ${source[1]} L ${splitPosition[0]} ${splitPosition[1]} L ${target[0]} ${target[1]}`;
    });

  const bubbleLayer = radialLayer
    .selectAll('g.bubble-layer')
    .data([null])
    .join('g')
    .attr('class', 'bubble-layer');

  const bubbles = bubbleLayer
    .selectAll('g')
    .data(radialNodes)
    .join('g')
    .attr('class', 'node')
    .attr('transform', (d) => `translate(${d3.pointRadial(d.x, d.y)})`);

  bubbles
    .selectAll('circle.bubble')
    .data((d) => [d])
    .join('circle')
    .attr('class', 'bubble')
    .attr('fill', (d) => {
      const colorMetric = getMetric(metricsLookup, d.data.name, colorKPI);
      return colorScale(METRIC_COLORS[colorKPI])(colorMetric);
    })
    .attr('r', (d) => {
      const sizeMetric = getMetric(metricsLookup, d.data.name, sizeKPI);
      return radiusScale(sizeMetric) || 1.5;
    });

  bubbles
    .selectAll('circle.hitbox')
    .data((d) => [d])
    .join('circle')
    .attr('class', 'hitbox')
    .attr('r', 8)
    .attr('fill', 'transparent')
    .on('mouseenter', (d) => showPopup(d, true))
    .on('mouseout', hidePopup);
};

const drawCanvas = (
  layers: Layers,
  clusterNodes,
  width: number,
  height: number,
  zoomTransform: ZoomTransform,
  metricsLookup,
  chartCenter: ChartCenter,
  strokeKPI: string,
  sizeKPI: string,
  colorKPI: string,
  scales: Scales,
  showPopup: Function,
  doneLoading: any,
  userSelectedTheme: string,
) => {
  const { svg, canvasContext } = layers;
  const nodes = clusterNodes;
  canvasContext.clearRect(0, 0, width, height);
  canvasContext.save();
  canvasContext.translate(zoomTransform.x, zoomTransform.y);
  canvasContext.scale(zoomTransform.k, zoomTransform.k);

  /*
    Rendering the lines in the asteroid belt
  */
  _.forEach(nodes, (d) => {
    const strokeMetric = getMetric(metricsLookup, d.data.name, strokeKPI);

    if (strokeMetric) {
      const strokeWidth = scales.strokeScale(strokeMetric);
      canvasContext.beginPath();
      canvasContext.moveTo(chartCenter.x, chartCenter.y);
      canvasContext.lineTo(d.x, d.y);
      canvasContext.lineWidth = strokeWidth;
      canvasContext.lineCap = 'round';
      canvasContext.strokeStyle = getStrokeColor(strokeMetric, scales.brightScale, userSelectedTheme);
      canvasContext.stroke();
    }
  });

  /*
    Rendering the nodes in the asteroid belt
  */
  _.forEach(nodes, (d) => {
    const sizeMetric = getMetric(metricsLookup, d.data.name, sizeKPI);
    const radius = getBubbleRadius(sizeMetric, scales.radiusScale);

    const colorMetric = getMetric(metricsLookup, d.data.name, colorKPI);
    const myColor = scales.colorScale(METRIC_COLORS[colorKPI])(colorMetric);
    canvasContext.beginPath();
    canvasContext.arc(d.x, d.y, Math.abs(radius), 0, TWO_PI);
    canvasContext.fillStyle = d.data.name === 'root' ? 'transparent' : myColor;
    canvasContext.fill();
  });

  canvasContext.restore();

  svg.call(initCanvasHover, nodes, width, height, zoomTransform, showPopup);

  hidePopup();
  doneLoading();
};

const D3Container = ({ data, width, height, colorKPI, sizeKPI, strokeKPI, popupMetrics, zoomValue, onSvgClick,
  doneLoading, setZoom, scales, userSelectedTheme, showNode, setShowNode }: HeliosTreeChartProps) => {
  const chartCenter = useMemo(
    () => ({ x: width / 2, y: height / 2 }),
    [width, height],
  );

  const metricsLookup = data.filteredLookupTable;

  const [layers, setLayers] = useState<Layers | null>(null);
  const [radialNodes, setRadialNodes] = useState<RadialNodes | null>(null);
  const [clusterNodes, setClusterNodes] = useState<ClusterNodes | null>(null);
  const [zoomTransform, setZoomTransform] = useState<ZoomTransform>({ x: 0, y: 0, k: zoomValue });
  const [zoomEvent, setZoomEvent] = useState<any>(null);
  const [zoomController, setZoomController] = useState<d3.ZoomBehavior<Element, any>>(null);
  const [popupNode, setPopupNode] = useState<any>(null);

  const showPopup = useCallback((node, isRadial) => {
    displayPopup(
      node,
      isRadial,
      popupMetrics,
      scales,
      width,
      chartCenter,
      metricsLookup,
      zoomTransform,
      colorKPI,
      sizeKPI,
      userSelectedTheme,
    );
  }, [scales, width, chartCenter, metricsLookup, zoomTransform, colorKPI, sizeKPI, popupMetrics, userSelectedTheme]);

  const onZoom = useCallback(() => {
    const { transform, sourceEvent } = d3.event;

    setZoomEvent(sourceEvent);
    setZoomTransform(transform);
  }, []);

  useMount(() => {
    const myLayers = buildLayers(width, height, onSvgClick);
    setLayers(myLayers);
  });
  // run forceSimulation to position cluster nodes
  useEffect(() => {
    if (layers && scales && data.radialData && data.clusterData) {
      const { radiusScale } = scales;
      const { drawLayer } = layers;

      const [nodes, links] = buildRadialNodes(radiusScale, metricsLookup, data.radialData, width, strokeKPI, sizeKPI);

      const baseRadialNodes = getBaseNodes(nodes, chartCenter);
      const [myClusterNodes, simulation] = buildClusterTree(
        data.clusterData,
        baseRadialNodes,
        metricsLookup,
        radiusScale,
        chartCenter,
        sizeKPI,
      );

      d3.timeout(() => {
        for (let i = 0,
          n = Math.ceil(Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay()));
          i < n;
          // eslint-disable-next-line no-plusplus
          ++i) {
          simulation.tick();
        }

        drawCanvas(
          layers,
          myClusterNodes,
          width,
          height,
          zoomTransform,
          metricsLookup,
          chartCenter,
          strokeKPI,
          sizeKPI,
          colorKPI,
          scales,
          showPopup,
          doneLoading,
          userSelectedTheme,
        );
        drawRadialTree(
          drawLayer,
          scales,
          nodes,
          links,
          chartCenter,
          metricsLookup,
          strokeKPI,
          colorKPI,
          sizeKPI,
          showPopup,
          userSelectedTheme,
        );
        setRadialNodes(nodes);
        setClusterNodes(myClusterNodes as ClusterNodes);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers, scales, data.clusterData, data.radialData]);

  // Initializes zoom behavior
  useEffect(() => {
    if (layers && onZoom) {
      const zoomBehavior = initZoom(layers.svg, width, height, onZoom, setZoom);
      /*
      If you pass a function to useState it gets invoked immediatly and the return value is stored.
      https://medium.com/@pshrmn/react-hook-gotchas-e6ca52f49328
      */
      setZoomController(() => zoomBehavior);
    }
  }, [layers, onZoom, height, width, setZoom]);

  useEffect(() => {
    if (layers && scales && radialNodes) {
      reColorChart(layers, scales, metricsLookup, radialNodes, colorKPI);
    }
    // we only recolor when colorKPI changes
  }, [colorKPI]); // eslint-disable-line react-hooks/exhaustive-deps

  // responds to outside zoom event via the zoom slider
  useEffect(() => {
    if (layers && zoomController && zoomTransform.k !== zoomValue) {
      layers.svg
        .transition()
        .duration(250)
        .call(zoomController.scaleTo, zoomValue);
    }
    // if we add zoomTransform.k to dependency array, we get into an infinite loop
  }, [layers, zoomController, zoomValue]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (layers && zoomController && showNode) {
      const node = findNodeByName(showNode, chartCenter, radialNodes, clusterNodes);
      if (node) {
        layers.svg
          .transition()
          .duration(500)
          .call(zoomController.translateTo, node.x, node.y, [chartCenter.x, 200])
          .on('end', () => {
            setShowNode(null);
            setPopupNode(node);
          });
      } else {
        setShowNode(null);
      }
    }
  }, [layers, zoomController, showNode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (layers) {
      drawCanvas(
        layers,
        clusterNodes,
        width,
        height,
        zoomTransform,
        metricsLookup,
        chartCenter,
        strokeKPI,
        sizeKPI,
        colorKPI,
        scales,
        showPopup,
        doneLoading,
        userSelectedTheme,
      );
      const isPanning = zoomEvent && zoomEvent.type === 'mousemove';

      d3.selectAll('.transform-layer').attr(
        'transform',
        `translate(${zoomTransform.x}, ${zoomTransform.y}), scale(${zoomTransform.k})`,
      );

      layers.svg
        .style('cursor', isPanning ? 'grabbing' : 'zoom-in');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomTransform]);
  /*
    Reregisters the mouseenter event so that the positioning of popups is correct
  */
  useEffect(() => {
    if (layers) {
      layers.drawLayer
        .selectAll('.radial-layer')
        .selectAll('circle.hitbox')
        .on('mouseenter', (d) => {
          showPopup(d, true);
        });
    }
  }, [layers, zoomTransform, showPopup]);

  useEffect(() => {
    if (popupNode) {
      showPopup(popupNode.node, popupNode.isRadial);
    }
  }, [popupNode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (<div id="helios-tree-chart" />);
};

export const HeliosTreeChart = ({ data, width, height, colorKPI, sizeKPI, strokeKPI, popupMetrics, zoomValue,
  onSvgClick, doneLoading, setZoom, scales, userSelectedTheme, showNode, setShowNode }:
HeliosTreeChartProps) => (
  <D3Container
    data={data}
    width={width}
    height={height}
    colorKPI={colorKPI}
    sizeKPI={sizeKPI}
    strokeKPI={strokeKPI}
    popupMetrics={popupMetrics}
    zoomValue={zoomValue}
    onSvgClick={onSvgClick}
    doneLoading={doneLoading}
    setZoom={setZoom}
    scales={scales}
    userSelectedTheme={userSelectedTheme}
    showNode={showNode}
    setShowNode={setShowNode}
  />
);
