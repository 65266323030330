import React from 'react';
import numeral from 'numeral';
import { ScaleLinear, scaleSqrt, select } from 'd3';
import _ from 'lodash';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { GoalType } from 'utils/types';
import { KPIsByVariantWithVariant } from 'containers/ABInsights/types';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { ABColors } from '../ABReportViz';
import Comparison from './Comparison';

type Props = {
  vizId: string
  kpisByVariantWithVariant: KPIsByVariantWithVariant
  colors: ABColors
  KPI: Metric & GoalType
  width: number
};

const buildViz = (
  id: string,
  perfA: number,
  perfB: number,
  colors: ABColors,
  KPIFormat: string,
  barScale: ScaleLinear<number, number>,
) => {
  const groups = select(`.secondary-KPI#${id} svg`)
    .selectAll('.bar')
    .data([{ fill: colors.a, perf: perfA }, { fill: colors.b, perf: perfB }])
    .join('g');

  groups
    .filter((d) => !!d.perf)
    .append('path')
    .attr('fill', (d) => d.fill)
    .attr('d', 'M0,0 h0 q5,0 5,5 v0 q0,5 -5,5 h-0 z')
    .transition()
    .duration(1000)
    .attr('d', (d) => `M0,0 h${barScale(d.perf) - 5} q5,0 5,5 v0 q0,5 -5,5 h-${barScale(d.perf) - 5} z`);

  groups.append('text')
    .attr('dx', (d) => barScale(d.perf) + 2)
    .attr('dy', 10)
    .text((d) => numeral(d.perf).format(KPIFormat));
};

export default ({ vizId, kpisByVariantWithVariant, colors, KPI, width }: Props) => {
  const { value: primaryKPIValue, format: KPIFormat, lowerIsBetter } = KPI;
  const perfA = kpisByVariantWithVariant.a[primaryKPIValue];
  const perfB = kpisByVariantWithVariant.b[primaryKPIValue];
  const maxPerf = _.max([0.000001, perfA, perfB]); // we don't want the max to be 0
  const barScale = scaleSqrt()
    .domain([0, maxPerf])
    .range([0, width - 100])
    // @ts-ignore next-line
    .unknown(0);

  const id = `${vizId}${primaryKPIValue}`;
  useMount(() => {
    buildViz(id, perfA, perfB, colors, KPIFormat, barScale);
  });

  return (
    <div id={id} className="secondary-KPI" style={{ width }}>
      <h3 className="metric-label">{KPI.insightsLabel || KPI.text}</h3>
      <svg />
      <Comparison
        perfA={perfA}
        perfB={perfB}
        lowerIsBetter={lowerIsBetter}
        colors={colors}
      />
    </div>
  );
};
