import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import DetectClickOutside from 'utils/hooks/generic/DetectClickOutside';

type Props = {
  initialColor: string
  type: 'block' | 'line'
  onColorSelect: (c: string) => void
};

export default ({ initialColor, type, onColorSelect }: Props) => {
  const [pickerVisible, setPickerVisible] = useState<boolean>(false);
  return (
    <div className="editable-color-swatch">
      {pickerVisible
        ? (
          <DetectClickOutside callback={() => setPickerVisible(false)}>
            <SketchPicker color={initialColor} onChangeComplete={(c) => onColorSelect(c.hex)} />
          </DetectClickOutside>
        )
        : (
          <div
            role="button"
            tabIndex={0}
            className={`swatch ${type}`}
            style={{ backgroundColor: initialColor }}
            onClick={() => setPickerVisible(true)}
          />
        )}
    </div>
  );
};
