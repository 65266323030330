import ObjectDropdown, {
  ObjectDropdownSearch as ObjectDropdownSearchImport,
  Props as ObjectDropdownPropsImport,
  OnSearchChange as OnSearchChangeImport,
} from './ObjectDropdown';

export type ObjectDropdownProps<T> = ObjectDropdownPropsImport<T>;
export type ObjectDropdownSearch = ObjectDropdownSearchImport;
export type OnSearchChange = OnSearchChangeImport;

export default ObjectDropdown;
