import React from 'react';
import { TrellisChart } from './TrellisChart';

type Props = {
  width: number,
  data: any,
};

export const Trellis = ({ width, data }: Props) => (
  <div className="helios-sidepanel trellis">
    <TrellisChart
      width={width}
      data={data}
    />
  </div>
);

export default Trellis;
