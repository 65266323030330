import React from 'react';
import { FlightExtIdToAlias, KPIsByVariantWithVariant as KPIsByVariantWithVariantType, VariantToFlightExtId } from 'containers/ABInsights/types';
import { ABColors } from '../ABReportViz';
import CopilotLegendIndicator from './CopilotLegendIndicator';

type Props = {
  colors: ABColors
  variantToFlightExtId: VariantToFlightExtId
  flightExtIdToAlias: FlightExtIdToAlias
  KPIsByVariantWithVariant: KPIsByVariantWithVariantType
};

export default ({ colors, flightExtIdToAlias, variantToFlightExtId, KPIsByVariantWithVariant }: Props) => (
  <div className="ab-legend">
    <div className="circle" style={{ backgroundColor: colors.a }} />
    <span style={{ color: colors.a }}>{flightExtIdToAlias[variantToFlightExtId.a]}</span>
    {KPIsByVariantWithVariant.a.isCopilot && <CopilotLegendIndicator />}
    <div className="circle" style={{ backgroundColor: colors.b }} />
    <span style={{ color: colors.b }}>{flightExtIdToAlias[variantToFlightExtId.b]}</span>
    {KPIsByVariantWithVariant.b.isCopilot && <CopilotLegendIndicator />}
  </div>
);
