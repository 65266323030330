// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wpp-table {
  width: 100%;
  border-collapse: collapse;
}
.wpp-table__header {
  height: 48px;
}
.wpp-table__header__cell {
  border-bottom: 1px solid var(--wpp-grey-color-400);
  padding: 8px 16px !important;
}
.wpp-table__header__cell:hover {
  background-color: var(--ag-header-cell-hover-background-color);
}
.wpp-table__row {
  border-bottom: var(--ag-borders-row) var(--ag-row-border-color);
}
.wpp-table__row:hover {
  background-color: var(--ag-row-hover-color);
}
.wpp-table__cell {
  border-bottom: var(--ag-borders-row) var(--ag-row-border-color);
  padding: 8px 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/buildingBlocks/WppTable/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,yBAAA;AACJ;AACI;EACE,YAAA;AACN;AAAM;EACE,kDAAA;EACA,4BAAA;AAER;AAAQ;EACE,8DAAA;AAEV;AAGI;EACE,+DAAA;AADN;AAGM;EACE,2CAAA;AADR;AAII;EACE,+DAAA;EACA,4BAAA;AAFN","sourcesContent":[".wpp-table{\n    width: 100%;\n    border-collapse: collapse;\n  \n    &__header{\n      height: 48px;\n      &__cell{\n        border-bottom: 1px solid var(--wpp-grey-color-400);\n        padding: 8px 16px !important;\n  \n        &:hover{\n          background-color: var(--ag-header-cell-hover-background-color);\n        }\n      }\n    }\n    \n    &__row{\n      border-bottom: var(--ag-borders-row) var(--ag-row-border-color);\n  \n      &:hover{\n        background-color: var(--ag-row-hover-color);\n      }\n    }\n    &__cell{\n      border-bottom: var(--ag-borders-row) var(--ag-row-border-color);\n      padding: 8px 16px !important;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
