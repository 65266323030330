import type { JSX } from '@wppopen/components-library/components';
import { StyleReactProps } from '@wppopen/components-library-react/lib/react-component-lib/interfaces';
import _ from 'lodash';
import React, { useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { WppLabel, WppInlineMessage, WppInlineEdit, WppInput } from 'buildingBlocks';
import { WppInlineEditCustomEvent, InlineEditChangeModeEventDetail, InlineEditMode } from 'utils/types';
import style from './styles';

const { titleStyle } = style;

interface ValidatedWppFieldInlineEditProps extends JSX.WppInput, Omit<React.HTMLAttributes<HTMLWppInputElement>, 'style'>, StyleReactProps, React.RefAttributes<HTMLWppInputElement> {
  title?: string
  skipIsDirtyCheck?: boolean
  field?: ControllerRenderProps<any, any>
  fieldState?: ControllerFieldState
}

const ValidatedWppFieldInlineEdit = ({ field, fieldState, skipIsDirtyCheck, title, ...rest }: ValidatedWppFieldInlineEditProps) => {
  const [inputMode, setInputMode] = useState<InlineEditMode>('read');
  const dirty = skipIsDirtyCheck ? fieldState?.error : (fieldState?.isDirty && fieldState?.error);
  const showError = (!_.isNil(field) && !_.isNil(fieldState) && dirty) && fieldState?.error.message;
  const value = rest.value ?? (field?.value ?? '');

  return (
    <div>
      {title && <WppLabel htmlFor={field.name ?? title} style={titleStyle} config={{ text: title }} />}
      <WppInlineEdit
        value={value}
        mode={inputMode}
        onWppModeChange={(event: WppInlineEditCustomEvent<InlineEditChangeModeEventDetail>) => {
          setInputMode(event.detail.mode);
          if (event.detail.mode === 'read') {
            event.detail.closePopover();
          }
        }}
      >
        <WppInput
          slot="form-element"
          value={value}
          className={showError ? ' validated-input-error' : ''}
          {...rest}
        />
      </WppInlineEdit>
      {showError && (
        <WppInlineMessage
          size="s"
          message={fieldState.error.message}
          type="error"
        />
      )}
    </div>
  );
};

export default ValidatedWppFieldInlineEdit;
