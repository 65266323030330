import React from 'react';
import _ from 'lodash';
import { Icon } from 'buildingBlocks';
import { ABColors } from '../ABReportViz';

type InsightsConfigFn = (input?: {}) => string;

export type InsightSingleConfig = {
  title: InsightsConfigFn
  statisticValue: InsightsConfigFn
  statisticSuffix: InsightsConfigFn
  description: InsightsConfigFn
  variant: 'standard'
};
type SingleInsightsPanelProps = {
  config: InsightSingleConfig
  data: {}
};
export const SingleInsight = ({ config, data }: SingleInsightsPanelProps) => {
  const { title, statisticValue, statisticSuffix, description } = config;
  const titleText = title(data);
  return (
    <div key={titleText}>
      <span className="num"><Icon name="lightbulb" />{titleText}</span>
      <div>
        <span className="stat">{statisticValue(data)}</span>
        <span className="unit">{statisticSuffix(data)}</span>
      </div>
      <hr />
      <p>{description(data)}</p>
    </div>
  );
};

export type ABSummaryInsightSingleConfig = {
  winnerText: InsightsConfigFn
  winnerVariant: InsightsConfigFn
  loserText: InsightsConfigFn
  loserVariant: InsightsConfigFn
  perfDiff: InsightsConfigFn
  primaryKPIText: InsightsConfigFn
  projectedSuccessMetrics: InsightsConfigFn
  variant: 'overview-summary'
};
type ABSummaryInsightBoxProps = {
  abColors: ABColors
  config: ABSummaryInsightSingleConfig
  data: {}
};
export const ABSummaryInsight = ({ config, data, abColors }: ABSummaryInsightBoxProps) => {
  const { winnerText, loserText, perfDiff, primaryKPIText, winnerVariant, loserVariant, projectedSuccessMetrics } = config;
  return (
    <div className="overview-summary">
      <p>
        <span style={{ color: abColors[winnerVariant(data)] }}>{winnerText(data)} </span>
        had <span>{perfDiff(data)}</span> than
        <span style={{ color: abColors[loserVariant(data)] }}> {loserText(data)}</span>.
        <br />
        If <span style={{ color: abColors[loserVariant(data)] }}>{_.upperCase(loserVariant(data))} </span>
        had the same <span>{primaryKPIText(data)}</span> as
        <span style={{ color: abColors[winnerVariant(data)] }}> {_.upperCase(winnerVariant(data))}</span>,
        <span style={{ color: abColors[loserVariant(data)] }}> {_.upperCase(loserVariant(data))} </span>
        would have <span>{projectedSuccessMetrics(data)}</span>.
      </p>
    </div>
  );
};

export type ABInsightsSingleConfig = {
  title: InsightsConfigFn
  statistics: Array<{
    statisticValue: InsightsConfigFn
    statisticSuffix: InsightsConfigFn
    statisticWinner: () => 'a' | 'b',
    statisticWinnerText: InsightsConfigFn,
  }>
  description: InsightsConfigFn
  variant: 'ab'
};
type DoubleABInsightsProps = {
  abColors: ABColors
  config: ABInsightsSingleConfig
  data: {}
};
export const DoubleABInsight = ({ config, data, abColors }: DoubleABInsightsProps) => {
  const { title, statistics, description } = config;
  const titleText = title(data);
  return (
    <div key={titleText} className="ab">
      <span className="num"><Icon name="lightbulb" />{titleText}</span>
      {
        _.map(statistics, ({ statisticValue, statisticSuffix, statisticWinner, statisticWinnerText }, i) => (
          <div key={i}>
            <div className={`${statisticWinner()}-stat`}>
              <span className="stat">{statisticValue(data)}</span>
              <span className="unit">{statisticSuffix(data)}</span>
            </div>
            <p className={`${statisticWinner()}-winner statistic-winner`} style={{ color: (i === 0) ? abColors.a : abColors.b }}>
              {statisticWinnerText()}
              <Icon name="check circle" style={{ color: (i === 0) ? abColors.a : abColors.b }} />
            </p>
          </div>
        ))
      }
      <hr />
      <p>{description(data)}</p>
    </div>
  );
};

const getInsightsComponent = (config, idx, data, abColors) => {
  switch (config.variant) {
    case 'ab':
      return <DoubleABInsight key={`insight-page-${idx}`} config={config} data={data} abColors={abColors} />;
    case 'overview-summary':
      return <ABSummaryInsight key="overview-summary" config={config} data={data} abColors={abColors} />;
    case 'standard':
    default:
      return <SingleInsight key={`insight-page-${idx}`} config={config} data={data} />;
  }
};

type InsightsPanelProps = {
  abColors: ABColors
  insights: {
    config: Array<InsightSingleConfig | ABInsightsSingleConfig | ABSummaryInsightSingleConfig>
    data: {}
  }
};

export default (props: InsightsPanelProps) => {
  const { insights, abColors } = props;
  const { config, data } = insights;
  return (
    <div className="insights-panel">
      {
        _.map(config, (c, i) => getInsightsComponent(c, i, data, abColors))
      }
    </div>
  );
};
