import React from 'react';
import { WppTableProps } from './types';
import './style.scss';

export const WppTable: React.FC<WppTableProps> = ({ children, style, className }: WppTableProps): React.ReactElement => (
  <table className={`ag-theme-wpp wpp-table ${className}`} style={style}>
    {children}
  </table>
);

export default WppTable;
