import React, { useContext } from 'react';
import _ from 'lodash';
import { Moment } from 'moment';
import { FlightExtIdToAlias, KPIsByVariantWithVariant as KPIsByVariantWithVariantType, VariantToFlightExtId } from 'containers/ABInsights/types';
import { ABColorContext, ColorContext } from 'charts/ABReportViz/ABReportViz';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { GoalType } from 'utils/types';
import CopilotLegendIndicator from './Components/CopilotLegendIndicator';

type Props = {
  reportTitle: string
  startDate: Moment
  endDate: Moment
  variantToFlightExtId: VariantToFlightExtId
  flightExtIdToAlias: FlightExtIdToAlias
  KPIs: Array<Metric & GoalType>
  KPIsByVariantWithVariant: KPIsByVariantWithVariantType
};

export default ({ reportTitle, startDate, endDate, flightExtIdToAlias, variantToFlightExtId, KPIs, KPIsByVariantWithVariant }: Props) => {
  const dateFormat = 'M/D/YY';
  const [colors] = useContext<ABColorContext>(ColorContext);
  return (
    <div id="summary">
      <h1>{reportTitle}</h1>
      <div className="flex-container">
        <div>
          <h5>Configuration</h5>
          <p>
            <span className="label">Start Date: </span><span>{startDate.format(dateFormat)}</span>
            <span className="label">End Date: </span><span>{endDate.format(dateFormat)}</span>
          </p>
          <p><span className="label">Primary Goal: </span>{_.get(_.first(KPIs), 'text')}</p>
          <p><span className="label">Status: </span>Completed</p>
        </div>
        <div>
          <h5>Control</h5>
          <div className="variant" style={{ color: colors.a }}>
            A: {flightExtIdToAlias[variantToFlightExtId.a]}
            {KPIsByVariantWithVariant.a.isCopilot && <CopilotLegendIndicator />}
          </div>
        </div>
        <div>
          <h5>Test</h5>
          <div className="variant" style={{ color: colors.b }}>
            B: {flightExtIdToAlias[variantToFlightExtId.b]}
            {KPIsByVariantWithVariant.b.isCopilot && <CopilotLegendIndicator />}
          </div>
        </div>
      </div>
    </div>
  );
};
