/**
 * Wrapper for WppLabel
 */
import React, { CSSProperties } from 'react';
import _ from 'lodash';
import { WppObjectDivider } from 'buildingBlocks';
import './style.scss';

type Props = {
  style?: CSSProperties
  alignment?: 'vertical' | 'horizontal'
};

/**
 * Wrapper around the WppDivider.
 */
const Divider: React.FC<Props> = ({ style, alignment }: Props): React.ReactElement => (
  _.isEqual(alignment, 'vertical')
    ? <div style={{ ...style }} className="wppVerticalDivider" />
    : <WppObjectDivider style={style} />);

export default Divider;
