import * as d3 from 'd3';
import {
  TEXT_COLOR,
  TEXT_HIGHLIGHT_COLOR,
} from 'charts/HeliosDataViz/constants';
import { COPILOT_COLORS } from 'globalStyles';

export const BAR_HEIGHT = 6;
export const BLOCK_HEIGHT = 48;

export const MARGINS = {
  top: 8,
  right: 16,
  bottom: 8,
  left: 48,
};

export const LABEL_STYLES = {
  'font-size': '12px',
  color: TEXT_COLOR,
  'padding-top': '8px',
  width: '100%',
  height: '28px',
  overflow: 'hidden',
};

export const LABEL_HOVER_STYLES = {
  color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE,
};

export const METRIC_P_STYLES = {
  'font-size': '14px',
  color: TEXT_HIGHLIGHT_COLOR,
  position: 'absolute',
  left: '-48px',
  top: '-2px',
  'text-align': 'right',
  'text-transform': 'uppercase',
  width: '40px',
};

export const METRIC_S_STYLES = {
  'font-size': '11px',
  color: TEXT_COLOR,
  position: 'absolute',
  left: '-48px',
  top: '17px',
  'text-align': 'right',
  width: '40px',
};

export const METRIC_FORMATS = {
  impressions: {
    default: d3.format('.3s'),
    1000: (n) => n,
  },
  revenue: {
    default: d3.format('.3s'),
    10000: d3.format(',.0f'),
  },
};
