import React from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import { formatDate, parseTime } from 'charts/utils';
import { ZOOM_CONFIG, METRIC_COLORS } from 'charts/HeliosDataViz/Helios/constants';
import { FilteredLookupTable, TreeLikeData } from 'charts/HeliosDataViz/Helios/types';
import {
  getMetric, getFeatureValue, getFeaturesList, tree, setRadialLinksRadii,
  jitterNodes, getBubbleRadius,
} from './utils';
import { POPUP_ORIENTATIONS, POPUP_MARGINS, BUBBLE_PADDING, INNER_RADIUS } from './constants';
import {
  Layers, ZoomTransform, Scales, ChartCenter, ShowPopup, ClusterNodes, RadialNode,
  ClusterNode, HeliosTreeChartProps, RadialNodes,
} from './types';

export const getHoverCoords = (
  node: RadialNode | ClusterNode,
  isRadial: boolean,
  chartCenter: ChartCenter,
  zoomTransform: ZoomTransform,
  width: number,
  bboxWidth: number,
) => {
  const coords = isRadial ? d3.pointRadial(node.x, node.y) : [node.x, node.y];
  const offsetX = (width - bboxWidth) / 2;
  const zoomParent = zoomTransform || { x: 0, y: 0, k: 1 };
  const centerX = chartCenter.x * zoomParent.k + zoomParent.x;

  return {
    x: (coords[0] + (isRadial ? chartCenter.x : 0)) * zoomParent.k + zoomParent.x,
    y: (coords[1] + (isRadial ? chartCenter.y : 0)) * zoomParent.k + zoomParent.y,
    centerX,
    offsetX,
    zoomParent,
  };
};

export const getQuadTree = (data: ClusterNodes, width: number, height: number, transform: ZoomTransform) => (
  d3
    .quadtree<ClusterNode>()
    .extent([[-1, -1], [width + 1, height + 1]])
    .x((d) => (d.x) * transform.k + transform.x)
    .y((d) => (d.y) * transform.k + transform.y)
    .addAll(data)
);

const zoomEnd = (
  event: d3.D3ZoomEvent<SVGSVGElement, void>,
  setZoom: React.Dispatch<React.SetStateAction<number>>,
  layer: Layers['svg'],
) => {
  const { transform } = event;
  setZoom(transform.k);
  layer.style('cursor', 'grab');
};

export const initZoom = (
  layer: Layers['svg'],
  width: number,
  height: number,
  onZoom: () => void,
  setZoom: React.Dispatch<React.SetStateAction<number>>,
) => {
  const zoom = d3
    .zoom()
    .extent([[0, 0], [width, height]])
    .scaleExtent(d3.extent(ZOOM_CONFIG.domain, (d) => d))
    .on('zoom', onZoom)
    .on('start', () => layer.style('cursor', 'grabbing'))
    .on('end', () => zoomEnd(d3.event, setZoom, layer));

  layer
    .call(zoom);
  return zoom;
};

const renderInfoRow = (row, { feature, featureValue, featureLabel }) => {
  if (featureValue || featureValue === 0) {
    row.append('div')
      .text(`${featureLabel || feature}:`);
    row.append('div')
      .text(() => getFeatureValue(feature, featureValue));
  }
};

export const hidePopup = () => {
  d3.select('.helios-body')
    .selectAll('div.popup')
    .remove();
};

const positionPopup = (elm, { parentX, parentY, orientLeft }) => {
  const bounding = elm.node().getBoundingClientRect();
  const popupWidth = bounding.width;
  let x = orientLeft ? -(POPUP_MARGINS.left + popupWidth) : POPUP_MARGINS.left;
  let y = POPUP_MARGINS.top;
  let orientation = orientLeft ? POPUP_ORIENTATIONS.left : POPUP_ORIENTATIONS.right;

  if (parentX + x < 0) { // Left overflow
    x = POPUP_MARGINS.left;
    orientation = POPUP_ORIENTATIONS.right;
  }

  if (parentX + x + bounding.width > (window.innerWidth || document.documentElement.clientWidth)) { // Right overflow
    x = -(POPUP_MARGINS.left + popupWidth);
    orientation = POPUP_ORIENTATIONS.left;
  }

  if (parentY + y < 0) { // Top overflow
    y = 0;
    orientation = orientation.type === 'left' ? POPUP_ORIENTATIONS.leftDown : POPUP_ORIENTATIONS.rightDown;
  }

  // Bottom overflow
  if (parentY + y + bounding.height > (window.innerHeight || document.documentElement.clientHeight)) {
    y = (window.innerHeight || document.documentElement.clientHeight)
      - (parentY + bounding.height + POPUP_MARGINS.bottom);
    orientation = orientation.type === 'left' ? POPUP_ORIENTATIONS.leftUp : POPUP_ORIENTATIONS.rightUp;
  }

  elm.style('left', `${x}px`)
    .style('top', `${y}px`)
    .style('transform-origin', orientation.origin);

  d3.select('.helios .diagonal')
    .style('transform', `rotate(${orientation.rotation})`);
};

export const displayPopup = (
  node: RadialNode | ClusterNode,
  isRadial: boolean,
  popupMetrics: HeliosTreeChartProps['popupMetrics'],
  scales: Scales,
  width: number,
  chartCenter: ChartCenter,
  metricsLookup: FilteredLookupTable,
  zoomTransform: ZoomTransform,
  colorKPI: string,
  sizeKPI: string,
  userSelectedTheme: string,
) => {
  const chartWrapper = d3.select('.helios-body');
  const { width: bboxWidth } = (chartWrapper.node() as Element).getBoundingClientRect();
  const coordsProps = getHoverCoords(node, isRadial, chartCenter, zoomTransform, width, bboxWidth);

  const sizeMetric = getMetric(metricsLookup, node.data.name, sizeKPI);
  const radius = (scales.radiusScale(sizeMetric) || 1.5) * coordsProps.zoomParent.k;

  const colorMetric = getMetric(metricsLookup, node.data.name, colorKPI);
  const nodeColor = scales.colorScale(METRIC_COLORS[colorKPI])(colorMetric);

  hidePopup();

  const popup = chartWrapper
    .selectAll('div.popup')
    .data([null])
    .join('div')
    .attr('class', 'popup')
    .classed(userSelectedTheme, true)
    .classed('radial', isRadial)
    .style('transform', `translate(${coordsProps.x - (coordsProps.offsetX)}px, ${coordsProps.y}px)`);

  popup
    .selectAll('div.diagonal')
    .data([null])
    .join('div')
    .attr('class', 'diagonal')
    .style('position', 'absolute');

  popup
    .selectAll('div.hl-bubble')
    .data([null])
    .join('div')
    .attr('class', 'hl-bubble')
    .style('position', 'absolute')
    .style('width', `${Math.ceil(radius * 2)}px`)
    .style('height', `${Math.ceil(radius * 2)}px`)
    .style('left', `-${radius + 1}px`)
    .style('top', `-${radius + 1}px`)
    .style('border-radius', '100%')
    .style('background', nodeColor);

  const info = popup
    .selectAll('div.info')
    .data([null])
    .join('div')
    .attr('class', 'info');

  info.append('div')
    .attr('class', 'name')
    .text(node.data.name)
    .style('text-transform', 'uppercase');

  if (node.children && node.children.length > 0) {
    const child = node.children[0];

    info.append('div')
      .attr('class', 'metric-row')
      .call(renderInfoRow, { feature: 'Split Date', featureValue: formatDate(parseTime(child.data.createdAt)) });
    info.append('div')
      .attr('class', 'metric-row')
      .call(renderInfoRow, { feature: 'Split On', featureValue: child.data.feature });

    info.append('div')
      .attr('class', 'title')
      .text('AGGREGATE METRICS');
  }

  const displayMetrics = Object.keys(popupMetrics);

  const features = getFeaturesList(node);

  displayMetrics.forEach((k) => {
    const metric = getMetric(metricsLookup, node.data.name, k);
    info.append('div')
      .attr('class', 'metric-row')
      .call(renderInfoRow, { feature: k, featureValue: metric, featureLabel: popupMetrics[k] });
  });

  if (!node.data.wasLeaf) {
    info.append('div')
      .attr('class', 'metric-row')
      .call(renderInfoRow, { feature: 'Birth Date', featureValue: formatDate(parseTime(node.data.createdAt)) });
  }

  if (_.some(features, (d) => !_.isNil(d.feature))) {
    info.append('div')
      .attr('class', 'title')
      .text('FEATURES');
  }

  features.forEach((d) => (
    info.append('div')
      .attr('class', 'metric-row')
      .call(renderInfoRow, d)
  ));

  const popupPosProps = {
    parentX: coordsProps.x - coordsProps.offsetX,
    parentY: coordsProps.y,
    orientLeft: coordsProps.x < coordsProps.centerX,
  };

  info.call(positionPopup, popupPosProps);
};

export const initCanvasHover = (
  svg: Layers['svg'],

  data: ClusterNodes,
  width: number,
  height: number,
  transform: ZoomTransform,
  showPopup: ShowPopup,
) => {
  const quadtree = getQuadTree(data, width, height, transform);
  svg
    // using function keyword because 'this' context is important
    .on('mousemove', function () { //  eslint-disable-line func-names
      const coords = (d3.mouse(this));
      const closest = quadtree.find(coords[0], coords[1], 8);
      const popup = d3.select('.helios-body').select('div.popup');

      if (closest) {
        if (closest.data.name === 'root') { return; }
        showPopup(closest, false);
        svg.style('cursor', 'pointer');
      } else if (popup.node() && !popup.classed('radial')) {
        hidePopup();
        svg.style('cursor', 'grab');
      }
    });
};

/*
  Creates radial hierarchy nodes & links
*/
export const buildRadialNodes = (
  radiusScale: Scales['radiusScale'],
  metricsLookup: FilteredLookupTable,
  radialData: HeliosTreeChartProps['data']['radialData'],
  width: number,
  strokeKPI: string,
  sizeKPI: string,
) => {
  const root = setRadialLinksRadii(tree(radialData, width));
  const nodes = jitterNodes(root.descendants(), radiusScale, metricsLookup, sizeKPI);
  const links = root.links();
  const nonRootLinks = root.children ? _.slice(links, root.children.length) : links;

  // sort ascending
  const sortedLinks = _.sortBy(nonRootLinks, (link) => getMetric(metricsLookup, link.target.data.name, strokeKPI, 0));

  const allButRootNodes = _.tail(nodes);

  // sort descending
  const sortedNodes = _.reverse(_.sortBy(
    allButRootNodes,
    (node: d3.HierarchyNode<TreeLikeData>) => getMetric(metricsLookup, node.data.name, sizeKPI, 0),
  ));

  const wasOrIsLeafNodes = _.filter(
    sortedNodes,
    (n: d3.HierarchyNode<TreeLikeData>) => n.data.isLeaf || n.data.wasLeaf,
  );
  return [wasOrIsLeafNodes, sortedLinks];
};

/*
  Run the d3 forceSimulation to cluster the nodes in the center "asteroid belt"
*/
export const buildClusterTree = (
  clusterData,
  baseRadialNodes: RadialNodes,
  metricsLookup: FilteredLookupTable,
  radiusScale: Scales['radiusScale'],
  chartCenter: ChartCenter,
  sizeKPI: string,
): [ClusterNodes, d3.Simulation<ClusterNode, any>] => {
  const root = d3.hierarchy<TreeLikeData>(clusterData) as ClusterNode;
  const nodes = root.descendants().concat(baseRadialNodes) as ClusterNodes;

  root.fx = chartCenter.x;
  root.fy = chartCenter.y;

  const simulation = d3
    .forceSimulation<ClusterNode, any>(nodes)
    .force('charge', d3.forceManyBody().strength(1))
    .force('center', d3.forceCenter(chartCenter.x, chartCenter.y))
    .force(
      'collision',
      d3.forceCollide().radius((d: ClusterNode) => {
        const radialMetric = getMetric(metricsLookup, d.data.name, sizeKPI);
        const radius = getBubbleRadius(radialMetric, radiusScale);
        return d.data.name === 'root' ? INNER_RADIUS : radius + BUBBLE_PADDING;
      }),
    );
  /*
    Run the simulation through to completion. When it's finished, setClusterNodes with their new position.
    Note this alternative:
      simulation.on('end', () => {
        setClusterNodes({ ...nodes });
      });
  */
  return [nodes, simulation];
};
