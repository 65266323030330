import _ from 'lodash';
import React, { Component, FocusEventHandler, MouseEventHandler, CSSProperties } from 'react';
import { HtmlInputrops } from 'semantic-ui-react';
import { ObjectDropdown } from 'buildingBlocks';
import { ObjectDropdownSearch } from 'buildingBlocks/ObjectDropdown/ObjectDropdown';

export type Props<T> = {
  /** Function to call when dropdown selection changes. */
  onChange?: Function,
  /** Array of options for the dropdown. */
  options: Array<T>,
  /** The Form input synced with and managed by this dropdown. */
  input: HtmlInputrops,
  name: string,
  /** The current value of the Dropdown. */
  value?: Array<T>,
  keyFn?: (obj: T) => string,
  search?: ObjectDropdownSearch,
  error?: boolean,
  disabled?: boolean,
  selection?: boolean,
  fluid?: boolean,
  scrolling?: boolean,
  disableRemoveItem?: boolean,
  text?: string,
  placeholder?: string,
  renderLabel?: Function,
  loading?: boolean,
  selectOnBlur?: boolean,
  /** Limit the number of selections a user can make */
  selectionLimit?: number,
  header?: React.ReactNode,
  closeOnChange?: boolean,
  open?: boolean,
  onClick?: MouseEventHandler<HTMLTextAreaElement>,
  onBlur?: FocusEventHandler<HTMLTextAreaElement>,
  customstyle?: CSSProperties,
};

type State<T> = {
  name: string,
  value: Array<T>,
};

class ObjectMultiDropdown<T> extends Component<Props<T>, State<T>> {
  static createState<T>({ input, name, value }: Props<T>): State<T> {
    let valueProps: Array<T>;
    if (value) {
      valueProps = value;
    } else if (input) {
      // Check if the value is from Redux Form as an array.
      if (Array.isArray(input.value)) {
        valueProps = input.value;
      } else {
        // When you provide only a single element in the Initial state, redux Form transforms to a non-array.
        // HACK: storing input.value onto a var seems to fix flow typing here. I have no idea what is going on....
        const v = input.value;
        valueProps = ((!_.isEmpty(v) || _.isFinite(v)) && [v]) || [];
      }
    } else {
      valueProps = [];
    }
    return {
      name: name || (input && input.name),
      value: valueProps,
    };
  }

  constructor(props: Props<T>) {
    super(props);
    this.state = ObjectMultiDropdown.createState(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props<T>) {
    this.setState(ObjectMultiDropdown.createState(nextProps));
  }

  onChange(value: Array<T>) {
    this.setState({
      value: value.slice(),
    });
    // need to call onBlur in order to get redux-form Field validation to work properly
    this.props.input?.onBlur && this.props.input.onBlur(); // eslint-disable-line
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(value);
    }
  }

  render() {
    const { options, selectionLimit, ...rest } = this.props;
    return (
      <ObjectDropdown
        {..._.omit(rest, ['input', 'onChange'])}
        multiple
        name={this.state.name}
        value={this.state.value}
        onChange={(value) => {
          if (selectionLimit) {
            if (_.size(value) <= selectionLimit) {
              this.onChange(value);
            }
          } else {
            this.onChange(value);
          }
        }}
        options={_.union(this.state.value, options)}
        style={{ ...this.props.customstyle, fontWeight: 'normal' }}
      />
    );
  }
}

export default ObjectMultiDropdown;
