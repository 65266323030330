import React from 'react';
import Selector from 'charts/Components/Selector';
import { formatMonthDayYear, parseDate } from 'charts/utils';

type Props = {
  data: any,
  onChange: Function,
  dateIndex: number,
  svgClick: {
    isSvgClick: boolean,
    resetSvgClick: Function,
  }
};

const labelFormat = (d) => `${d.timeInterval}${d.startDate ? ` ${formatMonthDayYear(parseDate(d.startDate))}` : ''}`;

export const DateSelector = ({ data, onChange, dateIndex, svgClick }: Props) => (
  <div className="date-selector">
    <Selector
      id="date-selector"
      options={data}
      selectedIndex={dateIndex}
      onChange={onChange}
      valueLabel={labelFormat}
      svgClick={svgClick}
      icon="chevron down"
    />
  </div>
);

export default DateSelector;
