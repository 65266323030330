import React from 'react';
import { WppTableBodyCellProps } from '../types';
import '../style.scss';

const WppTableBodyCell: React.FC<WppTableBodyCellProps> = ({ children, style, className, colSpan }: WppTableBodyCellProps): React.ReactElement => (
  <td className={`wpp-table__cell ${className}`} colSpan={colSpan} style={style}>
    {children}
  </td>
);

export default WppTableBodyCell;
