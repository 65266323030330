export const TEXT_COLOR = '#6C6C6C';
export const TEXT_HIGHLIGHT_COLOR = '#0097BE';

export const BAR_HEIGHT = 12;

export const COLOR_RAMP_GREEN_RED = [
  '#22ba46',
  '#52bb39',
  '#82bc2c',
  '#b2bc1e',
  '#e2bd11',
  '#f8ad0e',
  '#f18c14',
  '#e96a1b',
  '#e24921',
  '#db2828',
];
