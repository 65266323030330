import React, { useState, useEffect, useCallback } from 'react';
import * as d3 from 'd3';
import numeral from 'numeral';
import _ from 'lodash';
import { MetricsFormattingConstants } from 'constantsBase';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { BAR_HEIGHT } from 'charts/HeliosDataViz/constants';
import { ColorLegendData } from 'charts/Components/ColorLegend';

export const DEFAULT_MARGINS = {
  top: 4,
  right: 0,
  bottom: 4,
  left: 0,
};

type ContainerProps = {
  id: string
  data: ColorLegendData
  width: number
  barWidth: number
  margins?: {
    top: number,
    left: number,
    bottom: number,
    right: number,
  }
};

const D3Container = ({
  id,
  data,
  width,
  barWidth,
  margins,
}: ContainerProps) => {
  const [drawLayer, setDrawLayer] = useState(null);

  const drawChart = useCallback((myDrawLayer) => {
    const canvas = myDrawLayer.selectAll('canvas')
      .data([null])
      .join('canvas')
      .attr('width', barWidth)
      .attr('height', BAR_HEIGHT)
      .style('margin', `${margins.top}px ${margins.right}px ${margins.bottom}px ${margins.left}px`)
      .node();

    const ctx = canvas.getContext('2d');

    // fill rect with color blocks
    _.forEach(data.colorRange, (color, i: number) => {
      ctx.fillStyle = color;
      ctx.fillRect(i * (barWidth / data.colorRange.length), 0, barWidth, BAR_HEIGHT);
    });
  }, [data, margins, barWidth]);

  useMount(() => {
    const ch = d3.select(`#${id}`).style('width', `${width}px`);

    const myDrawLayer = ch.append('div')
      .attr('class', 'drawLayer')
      .style('width', `${width}px`)
      .style('height', `${BAR_HEIGHT + margins.bottom + margins.top}px`)
      .style('position', 'relative');

    setDrawLayer(myDrawLayer);
    drawChart(myDrawLayer);
  });

  useEffect(() => {
    if (drawLayer) {
      drawChart(drawLayer);
    }
  }, [data, drawLayer, drawChart]);

  return (<div id={id} />);
};

type ColorLegendProps = Omit<ContainerProps, 'barWidth'> & {
  label: string
  lowerIsBetter: boolean
};

const ValueLabels = ({ valueLabels, colorRange }: {
  valueLabels: Array<string>, colorRange: Array<string>
}) => (
  <div className="val-labels-container">
    {_.map(valueLabels, (v, i) => {
      const colors = [_.first(colorRange), 'unset', _.last(colorRange)];
      return (
        <div
          key={`${v}${i}`}
          className="val-label center"
          style={{ color: colors[i] }}
        >
          <span>{v}</span>
        </div>
      );
    })}
  </div>
);

const ColorLegend = (props: ColorLegendProps) => {
  const {
    id,
    width,
    data,
    label,
    lowerIsBetter,
    margins = DEFAULT_MARGINS,
  } = props;

  const arrow = lowerIsBetter ? '↓' : '↑';

  const valueLabels = [
    // first val in domain is negative so we need to make it positive for display
    `A ${arrow}${numeral(_.first(data.domain) * -1).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)}`,
    'A=B',
    `B ${arrow}${numeral(_.last(data.domain)).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)}`,
  ];

  const BAR_WIDTH = width - (margins.left + margins.right);

  return (
    <div className="legend-color" style={{ width }}>
      <div className="legend-color-label">
        <span>{label}</span>
      </div>
      <D3Container
        id={id}
        data={data}
        width={width}
        barWidth={BAR_WIDTH}
        margins={margins}
      />
      <ValueLabels
        valueLabels={valueLabels}
        colorRange={data.colorRange}
      />
    </div>
  );
};

export default ColorLegend;
