import _ from 'lodash';
import numeral from 'numeral';
import { MetricsFormattingConstants } from 'constantsBase';
import { KPIsByVariantWithVariant } from 'containers/ABInsights/types';
import { metrics } from 'containers/StrategyAnalytics/constants/metricsConstants';

export const getPercentDiff = (dividend: number, divisor: number): number => _.divide(dividend, divisor);

type Variant = 'a' | 'b';
export const getSizes = (
  diff: number,
  greaterVariant: Variant,
  lowerVariant: Variant,
  winner: Variant,
) => {
  if (diff === 0) {
    return {
      a: 'equal',
      b: 'equal',
    };
  }
  const loser = (greaterVariant !== winner ? greaterVariant : lowerVariant) as Variant;
  return {
    [winner]: 'larger',
    [loser]: 'smaller',
  };
};

export const getComparisons = (dataByVariant: KPIsByVariantWithVariant, metric: string) => {
  const metricConfig = metrics.ratePercentage[metric] ?? metrics.aggregator[metric];
  const data = [dataByVariant.a, dataByVariant.b];
  const greater = _.maxBy(data, metric);
  const lower = _.minBy(data, metric);
  const diff = (metricConfig.lowerIsBetter) ? _.subtract(lower[metric], greater[metric]) : _.subtract(greater[metric], lower[metric]);
  const mean = _.meanBy(data, metric);
  const percentDiff = getPercentDiff(diff, mean);
  let text;
  let winner;
  if (diff === 0) {
    text = `${data[0].label} equals ${data[1].label}`;
    winner = 'neither';
  } else if (metricConfig.lowerIsBetter) {
    text = `${lower.label} is lower by ${numeral(Math.abs(percentDiff)).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)}`;
    winner = lower.variant;
  } else {
    text = `${greater.label} is higher by ${numeral(Math.abs(percentDiff)).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)}`;
    winner = greater.variant;
  }
  return {
    sizes: getSizes(diff, greater.variant, lower.variant, winner),
    diff: numeral(diff).format(metricConfig.format),
    percentDiff: numeral(percentDiff).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS_WITH_SIGN),
    text,
    winner,
  };
};
