/* eslint-disable max-classes-per-file */
import _ from 'lodash';
import { ServiceT } from 'utils/copilotAPI';
import {
  DataProviderPaginatedArgs,
  DataProviderArgs,
  QueryResponse,
  DataProviderInterface,
} from './TwoListMultiSelect';

interface SmartServiceAPIInterface {
  get: ServiceT,
  count: ServiceT,
}

export class DBDataProvider implements DataProviderInterface {
  api: SmartServiceAPIInterface;

  searchFieldName: string | Array<string>;

  /**
   * @param  {copilotAPI} api             [copilotAPI class (example Advertiser)]
   * @param  {string} searchFieldName [field name(s) that we are searching the database]
   */
  constructor(api: SmartServiceAPIInterface, searchFieldName: string | Array<string>) {
    this.api = api;
    this.searchFieldName = searchFieldName;
  }

  async queryWithPagination({ search, limit, skip }: DataProviderPaginatedArgs) {
    // passing limit and skip to query all, will append it to the query automatically through rest
    return this.queryAll({ limit, skip, search });
  }

  async queryAll({ search, limit = 999999, ...rest }: DataProviderPaginatedArgs) {
    const searchQuery = _.isString(this.searchFieldName)
      ? { [this.searchFieldName]: { contains: search } }
      : { or: _.map(this.searchFieldName, (fieldName) => ({ [fieldName]: { contains: search } })) };
    const elementRequest = this.api.get({ ...searchQuery, limit, ...rest });
    const countRequest = this.api.count({ ...searchQuery, ...rest });
    const [elementRes, countRes] = await Promise.all([elementRequest, countRequest]);
    return {
      elements: elementRes.data,
      count: countRes.data.count,
    };
  }
}

type CurriedSearchFunction = (search: string) => ((item: unknown) => boolean);

const defaultArrayDataProviderSearch = (search: string): ((item: { text: string }) => boolean) => {
  const regex = new RegExp(search, 'i');
  return (item) => Boolean(item.text.match(regex));
};

export class ArrayDataProvider<T> implements DataProviderInterface {
  list: Array<T>;

  filterFn: CurriedSearchFunction;

  /**
   * @param  {array} list     [list of items]
   * @param  {function} filterFn [search -> item -> bool]
   */
  constructor(list: Array<T>, filterFn: CurriedSearchFunction = defaultArrayDataProviderSearch) {
    this.list = list.slice();
    this.filterFn = filterFn;
  }

  getFilteredList(search: string): Array<T> {
    if (search) {
      return this.list.filter(this.filterFn(search));
    }
    return this.list.slice();
  }

  async queryWithPagination({ search, limit = 10, skip = 0 }: DataProviderPaginatedArgs): QueryResponse<T> {
    const filtered = this.getFilteredList(search);
    // making data look like it is returned from API call
    return {
      elements: filtered.slice(skip, limit + skip),
      count: filtered.length,
    };
  }

  async queryAll({ search }: DataProviderArgs): QueryResponse<T> {
    const filtered = this.getFilteredList(search);
    // making data look like it is returned from API call
    return {
      elements: filtered.slice(),
      count: filtered.length,
    };
  }
}
