import React, { useContext, useMemo, useState } from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import { max, mean, min, scaleThreshold } from 'd3';
import {
  FlightExtIdToAlias,
  KPIOptions as KPIOptionsType,
  KPIsByFlightDomain,
  VariantToFlightExtId,
  Devices,
  KPIsByDeviceFlight,
  KPIsWithFlightAndDevice,
  KPIsByVariantWithVariant,
} from 'containers/ABInsights/types';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { getColorScaleDomainBuilder } from 'charts/utils';
import { COLORS_PINK_TO_GREEN } from 'charts/constants';
import VizHeader from 'charts/InsightsViz/Components/VizHeader';
import SlideTitle from 'charts/InsightsViz/Components/SlideTitle';
import 'charts/styles/ab-report-new.sass';
import { ObjectDropdown } from 'buildingBlocks';
import { GoalType } from 'utils/types';
import MessageContainer from 'charts/Components/MessageContainer';
import { ABColorContext, BudgetType, BudgetTypeOptions, ColorContext } from '../ABReportViz';
import InsightsPanel from '../Components/InsightsPanel';
import { customColorScaleBuilder, getColorRange, getDataSansOutliers, getDiffConfig, getInsightsByVizId, Greater } from '../utils';
import SingleDevice from './SingleDevice';
import KPILegend from '../KPILegend';
import List, { Datum } from '../List';
import { EXPORT_PREFIX, SlideIcons, VizId } from '../constants';
import CopilotLegendIndicator from '../Components/CopilotLegendIndicator';
import { renderStatbox } from '../StatBoxes/utils';

type Props = {
  vizId: string
  data: KPIsByFlightDomain
  flatData: Array<KPIsWithFlightAndDevice>
  KPIOptions: KPIOptionsType
  flightExtIdToAlias: FlightExtIdToAlias
  variantToFlightExtId: VariantToFlightExtId
  currency: string
  kpisByVariantWithVariant: KPIsByVariantWithVariant
  tooltipContent: string
  budgetTypeOptions: BudgetTypeOptions
  statBoxData
  selectedBudgetType: BudgetType
  setSelectedBudgetType: Function
  selectedKPI: Metric & GoalType
  setSelectedKPI: Function
};

const DeviceVisualization = ({
  vizId,
  data,
  flatData,
  KPIOptions,
  flightExtIdToAlias,
  variantToFlightExtId,
  currency,
  kpisByVariantWithVariant,
  tooltipContent,
  budgetTypeOptions,
  statBoxData,
  selectedBudgetType,
  setSelectedBudgetType,
  selectedKPI,
  setSelectedKPI,
}: Props) => {
  const [hoveredDevice, setHoveredDevice] = useState<Devices>(null);
  const [winner, setWinner] = useState<Greater>(null);

  const [colors] = useContext<ABColorContext>(ColorContext);

  const { value: budgetType } = selectedBudgetType;
  const { a, b } = variantToFlightExtId;
  const { value: selectedKPIValue, lowerIsBetter, format: KPIFormat } = selectedKPI;
  const avgMetricValue = mean([kpisByVariantWithVariant.a[selectedKPIValue], kpisByVariantWithVariant.b[selectedKPIValue]]);

  const memoData = useMemo(() => {
    const creativeDataWithNoOutliers = getDataSansOutliers(flatData, selectedKPIValue);

    const buildColorScaleDomain = getColorScaleDomainBuilder(avgMetricValue);
    const colorScaleDomain = buildColorScaleDomain(min(creativeDataWithNoOutliers, (d) => d[selectedKPIValue]), max(creativeDataWithNoOutliers, (d) => d[selectedKPIValue]));
    const colorRange = getColorRange(selectedKPI, COLORS_PINK_TO_GREEN);

    const colorScale = scaleThreshold<number, string>()
      .range(colorRange);
    colorScale.domain(colorScaleDomain);

    const customColorScale = customColorScaleBuilder(colorScale, selectedKPIValue);

    const dataWithBothAAndB = _.pickBy(data, (abData) => _.size(abData) === 2);
    const topData = _
      .chain(dataWithBothAAndB)
      .orderBy((abData) => _.sumBy(_.values(abData), budgetType), 'desc')
      .take(10)
      .map((d: Array<KPIsByDeviceFlight>) => ({
        a: d[a],
        b: d[b],
        device: d[a].device,
      }))
      .value();

    return { colorScale, topData, customColorScale, dataWithBothAAndB };
  }, [budgetType, data, selectedKPIValue, selectedKPI, flatData, avgMetricValue, a, b]);

  const onDeviceHover = (device: Devices) => {
    setHoveredDevice(device);
    const perfA = _.get(data, `${device}.${a}.${selectedKPIValue}`, null);
    const perfB = _.get(data, `${device}.${b}.${selectedKPIValue}`, null);

    const { greater } = getDiffConfig(perfA, perfB, lowerIsBetter);

    setWinner(greater);
  };

  const onDeviceUnHover = () => {
    setHoveredDevice(null);
    setWinner(null);
  };
  if (_.isEmpty(memoData.topData)) {
    return (
      <MessageContainer
        message="There is currently not enough data available for comparison"
        additionalClasses={[VizId.device, 'ab-viz-placeholder']}
      />
    );
  }
  return (
    <>
      {
        vizId.includes(EXPORT_PREFIX) && (
          <SlideTitle
            section="Device"
            icon={SlideIcons.device}
          />
        )
}
      <div className="grid-container">
        <div className="main-visualization">
          <VizHeader
            title="Which Devices Drive Performance"
            tooltipContent={tooltipContent}
          />
          <div className="controls">
            <div>
              <label htmlFor="budget-select">Budget Type</label>
              <ObjectDropdown
                // @ts-ignore passthrough props
                id="budget-select"
                className="new-color-palette"
                keyFn={(metric: { text: string }) => metric.text}
                options={_.values(budgetTypeOptions)}
                selection
                compact
                onChange={setSelectedBudgetType}
                value={selectedBudgetType}
              />
            </div>
            <div>
              <label htmlFor="kpi-select">Goal</label>
              <ObjectDropdown
                // @ts-ignore passthrough props
                id="kpi-select"
                className="new-color-palette"
                keyFn={(metric: { text: string }) => metric.text}
                options={KPIOptions}
                selection
                compact
                onChange={setSelectedKPI}
                value={selectedKPI}
              />
            </div>
          </div>
          <div id="chart">
            <hr />
            <span className="legend" style={{ color: colors.a }}>
              A: {flightExtIdToAlias[a]}
              {kpisByVariantWithVariant.a.isCopilot && <CopilotLegendIndicator />}
            </span>
            <span className="legend" style={{ color: colors.b }}>
              B: {flightExtIdToAlias[b]}
              {kpisByVariantWithVariant.b.isCopilot && <CopilotLegendIndicator />}
            </span>
            {memoData.dataWithBothAAndB[Devices.PHONE] && (
              <SingleDevice
                type={Devices.PHONE}
                data={memoData.dataWithBothAAndB[Devices.PHONE]}
                variantToFlightExtId={variantToFlightExtId}
                budgetType={budgetType}
                colorScale={memoData.customColorScale}
                onMouseEnter={() => onDeviceHover(Devices.PHONE)}
                onMouseLeave={onDeviceUnHover}
                isInActive={!_.isNull(hoveredDevice) && (hoveredDevice !== Devices.PHONE)}
                isFirst
                winner={winner}
                colors={colors}
                budgetTypeOptions={budgetTypeOptions}
              />
            )}
            {memoData.dataWithBothAAndB[Devices.TABLET] && (
              <SingleDevice
                type={Devices.TABLET}
                data={memoData.dataWithBothAAndB[Devices.TABLET]}
                variantToFlightExtId={variantToFlightExtId}
                budgetType={budgetType}
                colorScale={memoData.customColorScale}
                onMouseEnter={() => onDeviceHover(Devices.TABLET)}
                onMouseLeave={onDeviceUnHover}
                isInActive={!_.isNull(hoveredDevice) && (hoveredDevice !== Devices.TABLET)}
                isFirst={_.isNull(memoData.dataWithBothAAndB[Devices.PHONE])}
                winner={winner}
                colors={colors}
                budgetTypeOptions={budgetTypeOptions}
              />
            )}
            {memoData.dataWithBothAAndB[Devices.LAPTOP_OR_PC] && (
              <SingleDevice
                type={Devices.LAPTOP_OR_PC}
                data={memoData.dataWithBothAAndB[Devices.LAPTOP_OR_PC]}
                variantToFlightExtId={variantToFlightExtId}
                budgetType={budgetType}
                colorScale={memoData.customColorScale}
                onMouseEnter={() => onDeviceHover(Devices.LAPTOP_OR_PC)}
                onMouseLeave={onDeviceUnHover}
                isInActive={!_.isNull(hoveredDevice) && (hoveredDevice !== Devices.LAPTOP_OR_PC)}
                isFirst={
                  _.isNull(memoData.dataWithBothAAndB[Devices.PHONE])
                  && _.isNull(memoData.dataWithBothAAndB[Devices.TABLET])
                }
                winner={winner}
                colors={colors}
                budgetTypeOptions={budgetTypeOptions}
              />
            )}
            {memoData.dataWithBothAAndB[Devices.CTV] && (
              <SingleDevice
                type={Devices.CTV}
                data={memoData.dataWithBothAAndB[Devices.CTV]}
                variantToFlightExtId={variantToFlightExtId}
                budgetType={budgetType}
                colorScale={memoData.customColorScale}
                onMouseEnter={() => onDeviceHover(Devices.CTV)}
                onMouseLeave={onDeviceUnHover}
                isInActive={!_.isNull(hoveredDevice) && (hoveredDevice !== Devices.CTV)}
                isFirst={
                  _.isNull(memoData.dataWithBothAAndB[Devices.PHONE])
                  && _.isNull(memoData.dataWithBothAAndB[Devices.TABLET])
                  && _.isNull(memoData.dataWithBothAAndB[Devices.LAPTOP_OR_PC])
                }
                winner={winner}
                colors={colors}
                budgetTypeOptions={budgetTypeOptions}
              />
            )}
          </div>
        </div>
        <KPILegend
          id={`${vizId}-updated-color-legend`}
          data={{
            colorRange: memoData.colorScale.range(),
            domain: memoData.colorScale.domain(),
            format: (d) => numeral(d).format(KPIFormat),
          }}
          width={196}
          showMeanValue
          meanValue={avgMetricValue}
          selectedMetric={selectedKPI}
          currencyCode={currency}
          showGoalValue={false}
        />
        <List
          title="Device Summary"
          budgetType={selectedBudgetType}
          kpi={selectedKPI}
          accessor="device"
          data={memoData.topData as unknown as Array<Datum>}
          colorScale={memoData.customColorScale}
          budgetTypeOptions={budgetTypeOptions}
        />
      </div>
      {renderStatbox(selectedKPIValue) && (
        <InsightsPanel
          abColors={colors}
          insights={getInsightsByVizId(VizId.device, statBoxData, selectedKPI, selectedBudgetType)}
        />
      )}
    </>
  );
};

export default DeviceVisualization;
