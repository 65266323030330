import React, { useContext, Dispatch, SetStateAction } from 'react';
import { KPIsByVariantWithVariant } from 'containers/ABInsights/types';
import EditableColorSwatch from '../Components/EditableColorSwatch';
import { ColorContext, ABColorContext, ABColors } from './ABReportViz';
import CopilotLegendIndicator from './Components/CopilotLegendIndicator';

type SwatchWithLabelProps = {
  variant: 'a' | 'b'
  label: string
  isCopilot: boolean
  colors: ABColors
  type: 'block' | 'line'
  setColors: Dispatch<SetStateAction<ABColors>>
};

const SwatchWithLabel = ({ variant, isCopilot, colors, type, label, setColors }: SwatchWithLabelProps) => (
  <div key={variant}>
    <EditableColorSwatch
      initialColor={colors[variant]}
      type={type}
      onColorSelect={(color) => setColors({ ...colors, [variant]: color })}
    />
    <span>{label}</span>
    {isCopilot && <CopilotLegendIndicator />}
  </div>
);

type Props = {
  id: string
  data: KPIsByVariantWithVariant
  type: 'block' | 'line'
};

export default ({ id, data: { a, b }, type }: Props) => {
  const [colors, setColors] = useContext<ABColorContext>(ColorContext);
  return (
    <div
      id={id}
      className="ab-legend"
    >
      <SwatchWithLabel
        variant="a"
        colors={colors}
        isCopilot={a.isCopilot}
        type={type}
        label={a.label || 'Line A'}
        setColors={setColors}
      />
      <SwatchWithLabel
        variant="b"
        colors={colors}
        isCopilot={b.isCopilot}
        type={type}
        label={b.label || 'Line B'}
        setColors={setColors}
      />
    </div>
  );
};
