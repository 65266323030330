import React, { Component } from 'react';
import { Input } from 'buildingBlocks';

/*
 * Custom input using semantic styling instead of the original datepicker one
 */

type DatePickerInputProps = { id?: string, label: string, value?: string, onClick?: Function };

// Datepicker uses refs which requires custom input component to be a class (react throws warning).
// eslint-disable-next-line react/prefer-stateless-function
export default class DatePickerInput extends Component<DatePickerInputProps> {
  render() {
    return <Input id={this.props.id} label={this.props.label} value={this.props.value} onClick={this.props.onClick} />;
  }
}
