import React from 'react';
import { metrics } from 'containers/StrategyAnalytics/constants/metricsConstants';
import Legend from 'charts/HeliosDataViz/Legend/Legend';
import ColorLegend from 'charts/Components/ColorLegend';
import { METRIC_ALIASES } from 'charts/HeliosDataViz/Helios/constants';
import { BottomPanelProps } from './types';
import ZoomSlider from './ZoomSlider';

export const BottomPanel = ({ width, kpi, goal, zoomProps, colorData, colorLabel }: BottomPanelProps) => {
  const metric = metrics.ratePercentage[goal];
  return (
    <div className="bottom-panel">
      <ZoomSlider
        width={width}
        domainValues={zoomProps.domainValues}
        zoomValue={zoomProps.zoomValue}
        onChange={zoomProps.onChange}
      />
      <ColorLegend
        id="helios-legend-continuous"
        data={colorData}
        width={width}
        label={colorLabel}
        type="continuous"
        margins={{ top: 0, right: 32, bottom: 12, left: 32 }}
        showMeanValue={false}
        showGoalValue={false}
        lowerIsBetter={metric.lowerIsBetter}
      />
      <Legend
        categories={{
          nodata: 'No KPI',
          stroke: METRIC_ALIASES[kpi],
        }}
      />
    </div>
  );
};

export default BottomPanel;
