import React from 'react';
import * as _ from 'lodash';
import { COLOR_GRADIENT } from 'charts/HeliosDataViz/Helios/constants';
import { COPILOT_COLORS } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: { BLUES } } = COPILOT_COLORS;

type Props = {
  categories: object,
};

const LegendKey = (d) => (
  <svg className="legend-key">
    {d === 'nodata' && (<circle fill="gray" r={4} cx={12} cy={12} />)}
    {d === 'size' && (
      <g>
        <mask id="legend-mask" x="0" y="0" width="24" height="24">
          <circle fill="white" r={2} cx={12} cy={12} />
          <circle stroke="white" fill="transparent" r={5} cx={12} cy={12} />
          <circle stroke="white" fill="transparent" r={9} cx={12} cy={12} />
        </mask>
        <linearGradient id="legend-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={COLOR_GRADIENT[0]} />
          <stop offset="50%" stopColor={COLOR_GRADIENT[1]} />
          <stop offset="100%" stopColor={COLOR_GRADIENT[2]} />
        </linearGradient>
        <rect x={0} y={0} width={24} height={24} style={{ mask: 'url(#legend-mask)', fill: 'url(#legend-gradient)' }} />
      </g>
    )}
    {d === 'stroke' && (
      <g>
        <rect y={4} x={6} height={1} width={12} fill={BLUES.B500_WAVE} />
        <rect y={8} x={6} height={3} width={12} fill={BLUES.B500_WAVE} />
        <rect y={14} x={6} height={5} width={12} fill={BLUES.B500_WAVE} />
      </g>
    )}
  </svg>
);

export const Legend = ({ categories }: Props) => (
  <div className="legend">
    {_.map(Object.keys(categories), (d, i) => (
      <div key={i} className="legend-item">
        {LegendKey(d)}
        {categories[d]}
      </div>
    ))}
  </div>
);

export default Legend;
