/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, Dispatch, SetStateAction, useMemo } from 'react';
import _ from 'lodash';
import { Moment } from 'moment';
import qs from 'qs';
import { select } from 'd3';
import { useLocation, useNavigate } from 'react-router-dom';
import { COPILOT_COLORS } from 'globalStyles';
import { GoalType } from 'utils/types';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { ReportType } from 'charts/utils';
import { ABInsightsData, ABInsightsDataDSPSubtype } from 'containers/ABInsights/interfaces';
import { KPIsByVariantWithDate, KPIsByVariantWithVariant } from 'containers/ABInsights/types';
import 'charts/styles/ab-report-new.sass';
import ExportButton from 'charts/Components/ExportButton';
import { Icon, Menu, Tab } from 'buildingBlocks';
import Summary from './Summary';
import Overview from './Overview/index';
import Device from './Device/index';
import CreativeSize from './CreativeSize';
import { VISUALIZATIONS_CONFIG, VizId, EXPORT_PREFIX, IconType, SlideIcons } from './constants';
import Domain, { SortBy } from './Domain';
import Map from './Map';
import SlideWrapper from './SlideWrapper';
import TimeSeriesChart, { MetricType } from './TimeSeries';

type Props = {
  dataAdapter: ABInsightsDataDSPSubtype
  KPIs: Array<Metric & GoalType>
  reportTitle: string
  startDate: Moment
  endDate: Moment
  currentTab: VizId
  updateCurrentTab: any
};

export type BudgetType = {
  text: string
  value: string
  abbreviation: string
};

export type BudgetTypeOptions = { [key: string]: BudgetType };

export const getBudgetTypeOptions = (currency: string): BudgetTypeOptions => ({
  impressions: {
    text: 'Impressions',
    value: 'impressions',
    abbreviation: 'imp.',
  },
  advRevenue: {
    text: `Spend (${currency})`,
    value: 'advRevenue',
    abbreviation: 'spend',
  },
});

export const getInitialColors = (kpisByVariantWithVariant: KPIsByVariantWithVariant) => {
  const bothCopilot = _.every(kpisByVariantWithVariant, (config) => config.isCopilot);
  const neitherCopilot = _.every(kpisByVariantWithVariant, (config) => !config.isCopilot);
  if (bothCopilot || neitherCopilot) {
    return { a: COPILOT_COLORS.NEW_DESIGN_SYSTEM.orange, b: COPILOT_COLORS.NEW_DESIGN_SYSTEM.purple };
  }
  return kpisByVariantWithVariant.a.isCopilot
    ? { a: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE, b: COPILOT_COLORS.NEW_DESIGN_SYSTEM.orange }
    : { a: COPILOT_COLORS.NEW_DESIGN_SYSTEM.orange, b: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE };
};

export type ABColors = { a: string, b: string };
export type ABColorContext = [ABColors, Dispatch<SetStateAction<ABColors>>];
export const ColorContext = createContext<ABColorContext>(null);

export const METRIC_TYPES: Array<MetricType> = ['cumulative', 'daily'];

const visualizationIdToTabIdx = {
  [VizId.overview]: 0,
  [VizId.domain]: 1,
  [VizId.geography]: 2,
  [VizId.device]: 3,
  [VizId.creativeSize]: 4,
};

export type UserSelectionsState = { [key in Exclude<VizId, 'overview'>]: {
  budgetType?: BudgetType
  metricType?: MetricType
  KPI: (Metric & GoalType)
  sortBy?: SortBy
  sizeByDelivery?: boolean
} };

export const ABReportingVisualization = ({
  dataAdapter,
  KPIs: KPIOptions,
  reportTitle,
  startDate,
  endDate,
  currentTab,
  updateCurrentTab,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const augmentedStatBoxData = dataAdapter.getAugmentedStatBoxData.call(dataAdapter);
  const kpisByVariantWithVariant: KPIsByVariantWithVariant = useMemo(dataAdapter.getKpisByVariantWithVariant.bind(dataAdapter), [dataAdapter]);
  const [colors, setColors] = useState<ABColors>(getInitialColors(kpisByVariantWithVariant));
  const kpisByVariantWithDate: KPIsByVariantWithDate = useMemo(dataAdapter.getKpisByVariantWithDate.bind(dataAdapter), [dataAdapter]);
  const KPIsByDeviceFlight = dataAdapter.getKPIsByDeviceFlight(dataAdapter.deviceCodeToText);
  const flatDeviceData = ABInsightsData.getFlatData(dataAdapter.deviceCodeToText, dataAdapter.data.KPIsByFlightDevice);
  const flatCreativeSizeData = ABInsightsData.getFlatCreativeSizeFlightData(dataAdapter.data.KPIsBySizeFlight);
  const KPIsBySizeFlightWithSizeFlight = ABInsightsData.getKPIsBySizeFlightWithSizeFlight(dataAdapter.data.KPIsBySizeFlight);
  const budgetTypeOptions = getBudgetTypeOptions(dataAdapter.currency);
  const notEnoughData = _.size(_.keys(dataAdapter.data.KPIsByFlight)) < 2;

  // keep track of what user has selected in the dropdowns
  const userSelectionsInitialState: UserSelectionsState = {
    [VizId.performance]: {
      metricType: METRIC_TYPES[0],
      KPI: KPIOptions[0],
    },
    [VizId.domain]: {
      budgetType: budgetTypeOptions.impressions,
      KPI: KPIOptions[0],
      sortBy: SortBy.totalDelivery,
      sizeByDelivery: false,
    },
    [VizId.geography]: {
      budgetType: budgetTypeOptions.impressions,
      KPI: KPIOptions[0],
    },
    [VizId.device]: {
      budgetType: budgetTypeOptions.impressions,
      KPI: KPIOptions[0],
    },
    [VizId.creativeSize]: {
      budgetType: budgetTypeOptions.impressions,
      KPI: KPIOptions[0],
    },
  };

  const [userSelectionsState, setUserSelectionsState] = useState<UserSelectionsState>(userSelectionsInitialState);

  const customSetUserSelectionsState = (
    vizId: VizId,
    key: 'budgetType' | 'metricType' | 'KPI' | 'sortBy' | 'sizeByDelivery',
    val: BudgetType | (GoalType & Metric) | SortBy | boolean,
  ) => (
    setUserSelectionsState((prevState) => ({
      ...prevState,
      [vizId]: {
        ...prevState[vizId],
        [key]: val,
      },
    }))
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomMenuItem = ({ content, currentTab: currentTabItem, icon, key, ...rest }) => (
    <Menu.Item
      {...rest}
      key={key}
      onClick={() => {
        const searchParams = qs.parse(location.search);
        const newSearchParams = { ...searchParams, currentTab: currentTabItem };
        navigate({ pathname: location.pathname, search: qs.stringify(newSearchParams) });
        updateCurrentTab(currentTabItem);
      }}
    >
      {
        icon.kind === IconType.fontAwesome
          ? <Icon name={icon.name} />
          : <img src={`/img/icons/insights/${icon.name}.svg`} alt={icon.name} />
      }
      {content}

    </Menu.Item>
  );
  const createVisualizationContent = (idPrefix: string = '') => ({
    overviewAndPerformance: (
      <SlideWrapper
        ids={notEnoughData ? [`${idPrefix}${VizId.overview}`] : [`${idPrefix}${VizId.overview}`, `${idPrefix}${VizId.performance}`]}
        key={notEnoughData ? `${idPrefix}${VizId.overview}` : `${idPrefix}${VizId.overview}+${idPrefix}${VizId.performance}`}
      >
        <Overview
          KPIOptions={KPIOptions}
          variantToFlightExtId={dataAdapter.variantToFlightExtId}
          flightExtIdToAlias={dataAdapter.flightExtIdToAlias}
          kpisByVariantWithVariant={kpisByVariantWithVariant}
          tooltipContent={VISUALIZATIONS_CONFIG[VizId.overview].tooltipContent}
          showPlaceholder={notEnoughData}
          budgetTypeOptions={budgetTypeOptions}
          vizId={`${idPrefix}${VizId.overview}`}
          statBoxData={augmentedStatBoxData}
        />
        {!notEnoughData && (
          <TimeSeriesChart
            data={kpisByVariantWithDate}
            legendData={kpisByVariantWithVariant}
            KPIOptions={KPIOptions}
            currency={dataAdapter.currency}
            tooltipContent={VISUALIZATIONS_CONFIG[VizId.performance].tooltipContent}
            vizId={`${idPrefix}${VizId.performance}`}
            selectedKPI={userSelectionsState[VizId.performance].KPI}
            setSelectedKPI={_.partial(customSetUserSelectionsState, VizId.performance, 'KPI')}
            selectedMetricType={userSelectionsState[VizId.performance].metricType}
            setSelectedMetricType={_.partial(customSetUserSelectionsState, VizId.performance, 'metricType')}
          />
        )}
      </SlideWrapper>
    ),
    [VizId.domain]: (
      <SlideWrapper ids={[`${idPrefix}${VizId.domain}`]} key={`${idPrefix}${VizId.domain}`}>
        <Domain
          data={dataAdapter.data.KPIsByFlightDomain}
          KPIOptions={KPIOptions}
          flightExtIdToAlias={dataAdapter.flightExtIdToAlias}
          variantToFlightExtId={dataAdapter.variantToFlightExtId}
          currency={dataAdapter.currency}
          tooltipContent={VISUALIZATIONS_CONFIG[VizId.domain].tooltipContent}
          budgetTypeOptions={budgetTypeOptions}
          vizId={`${idPrefix}${VizId.domain}`}
          KPIsByVariantWithVariant={kpisByVariantWithVariant}
          statBoxData={augmentedStatBoxData}
          selectedBudgetType={userSelectionsState[VizId.domain].budgetType}
          setSelectedBudgetType={_.partial(customSetUserSelectionsState, VizId.domain, 'budgetType')}
          selectedKPI={userSelectionsState[VizId.domain].KPI}
          setSelectedKPI={_.partial(customSetUserSelectionsState, VizId.domain, 'KPI')}
          sortBy={userSelectionsState[VizId.domain].sortBy}
          setSortBy={_.partial(customSetUserSelectionsState, VizId.domain, 'sortBy')}
          sizeByDelivery={userSelectionsState[VizId.domain].sizeByDelivery}
          setSizeByDelivery={_.partial(customSetUserSelectionsState, VizId.domain, 'sizeByDelivery')}
        />
      </SlideWrapper>
    ),
    [VizId.geography]: (
      <SlideWrapper ids={[`${idPrefix}${VizId.geography}`]} key={`${idPrefix}${VizId.geography}`}>
        <Map
          data={dataAdapter.data.GeoInsightsData}
          KPIOptions={KPIOptions}
          variantToFlightExtId={dataAdapter.variantToFlightExtId}
          currency={dataAdapter.currency}
          tooltipContent={VISUALIZATIONS_CONFIG[VizId.geography].tooltipContent}
          budgetTypeOptions={budgetTypeOptions}
          vizId={`${idPrefix}${VizId.geography}`}
          statBoxData={augmentedStatBoxData}
          selectedBudgetType={userSelectionsState[VizId.geography].budgetType}
          setSelectedBudgetType={_.partial(customSetUserSelectionsState, VizId.geography, 'budgetType')}
          selectedKPI={userSelectionsState[VizId.geography].KPI}
          setSelectedKPI={_.partial(customSetUserSelectionsState, VizId.geography, 'KPI')}
        />
      </SlideWrapper>
    ),
    [VizId.device]: (
      <SlideWrapper ids={[`${idPrefix}${VizId.device}`]} key={`${idPrefix}${VizId.device}`}>
        <Device
          data={KPIsByDeviceFlight}
          flatData={flatDeviceData}
          KPIOptions={KPIOptions}
          variantToFlightExtId={dataAdapter.variantToFlightExtId}
          flightExtIdToAlias={dataAdapter.flightExtIdToAlias}
          currency={dataAdapter.currency}
          kpisByVariantWithVariant={kpisByVariantWithVariant}
          tooltipContent={VISUALIZATIONS_CONFIG[VizId.device].tooltipContent}
          budgetTypeOptions={budgetTypeOptions}
          vizId={`${idPrefix}${VizId.device}`}
          statBoxData={augmentedStatBoxData}
          selectedBudgetType={userSelectionsState[VizId.device].budgetType}
          setSelectedBudgetType={_.partial(customSetUserSelectionsState, VizId.device, 'budgetType')}
          selectedKPI={userSelectionsState[VizId.device].KPI}
          setSelectedKPI={_.partial(customSetUserSelectionsState, VizId.device, 'KPI')}
        />
      </SlideWrapper>
    ),
    [VizId.creativeSize]: (
      <SlideWrapper ids={[`${idPrefix}${VizId.creativeSize}`]} key={`${idPrefix}${VizId.creativeSize}`}>
        <CreativeSize
          data={KPIsBySizeFlightWithSizeFlight}
          flatData={flatCreativeSizeData}
          KPIOptions={KPIOptions}
          variantToFlightExtId={dataAdapter.variantToFlightExtId}
          flightExtIdToAlias={dataAdapter.flightExtIdToAlias}
          kpisByVariantWithVariant={kpisByVariantWithVariant}
          currency={dataAdapter.currency}
          tooltipContent={VISUALIZATIONS_CONFIG[VizId.creativeSize].tooltipContent}
          budgetTypeOptions={budgetTypeOptions}
          vizId={`${idPrefix}${VizId.creativeSize}`}
          statBoxData={augmentedStatBoxData}
          selectedBudgetType={userSelectionsState[VizId.creativeSize].budgetType}
          setSelectedBudgetType={_.partial(customSetUserSelectionsState, VizId.creativeSize, 'budgetType')}
          selectedKPI={userSelectionsState[VizId.creativeSize].KPI}
          setSelectedKPI={_.partial(customSetUserSelectionsState, VizId.creativeSize, 'KPI')}
        />
      </SlideWrapper>
    ),
  });
  const visualizationsForTabs = createVisualizationContent();
  const visualizations = [
    {
      menuItem: <CustomMenuItem content={_.startCase(VizId.overview)} currentTab={VizId.overview} icon={SlideIcons.overview} key={VizId.overview} />,
      render: () => visualizationsForTabs.overviewAndPerformance,
    },
    {
      menuItem: <CustomMenuItem content={_.startCase(VizId.domain)} currentTab={VizId.domain} icon={SlideIcons.domain} key={VizId.domain} />,
      render: () => visualizationsForTabs.domain,
    },
    {
      menuItem: <CustomMenuItem content={_.startCase(VizId.geography)} currentTab={VizId.geography} icon={SlideIcons.geography} key={VizId.geography} />,
      render: () => visualizationsForTabs.geography,

    },
    {
      menuItem: <CustomMenuItem content={_.startCase(VizId.device)} currentTab={VizId.device} icon={SlideIcons.device} key={VizId.device} />,
      render: () => visualizationsForTabs.device,

    },
    {
      menuItem: <CustomMenuItem content={_.startCase(VizId.creativeSize)} currentTab={VizId.creativeSize} icon={SlideIcons.creativeSize} key={VizId.creativeSize} />,
      render: () => visualizationsForTabs.creativeSize,

    },
  ];

  const displayAllVisualizations = () => {
    // display visualizations that are all absolutely positioned off screen
    select('#off-screen')
      .classed('display', true);
  };

  const onlyDisplayCurrentVisualization = () => {
    // hide the off-screen visualizations again
    select('#off-screen')
      .classed('display', false);
  };

  const tabsToShow = notEnoughData ? [visualizations[0]] : visualizations;

  const visualizationsForExport = createVisualizationContent(EXPORT_PREFIX);
  const visualizationsForExportToShow = notEnoughData ? visualizationsForExport[0] : visualizationsForExport;
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ColorContext.Provider value={[colors, setColors]}>
      <div id="vizContainer">
        <Summary
          reportTitle={reportTitle}
          startDate={startDate}
          endDate={endDate}
          variantToFlightExtId={dataAdapter.variantToFlightExtId}
          flightExtIdToAlias={dataAdapter.flightExtIdToAlias}
          KPIs={KPIOptions}
          KPIsByVariantWithVariant={kpisByVariantWithVariant}
        />
        {!notEnoughData && (
          <ExportButton
            containerType="header"
            chartIdsToExport={[
              `${EXPORT_PREFIX}${VizId.overview}`,
              `${EXPORT_PREFIX}${VizId.performance}`,
              `${EXPORT_PREFIX}${VizId.domain}`,
              `${EXPORT_PREFIX}${VizId.geography}`,
              `${EXPORT_PREFIX}${VizId.device}`,
              `${EXPORT_PREFIX}${VizId.creativeSize}`,
            ]}
            preExportCallbacks={[displayAllVisualizations]}
            postExportCallbacks={[onlyDisplayCurrentVisualization]}
            missingSlides={false}
            loading={false}
            flightAId={dataAdapter.variantToFlightExtId.a}
            flightBId={dataAdapter.variantToFlightExtId.b}
            reportTitle={reportTitle}
            startDate={startDate}
            endDate={endDate}
            tooltipContent="All content will be converted to images and then saved as a .pptx file. Content from tooltips will be saved as speaker's notes."
            speakerNotes={_.mapValues(VISUALIZATIONS_CONFIG, (c) => [c.tooltipContent])}
            reportType={ReportType.ABINSIGHTS}
          />
        )}
        <Tab
          panes={tabsToShow}
          menu={{ secondary: true, pointing: true }}
          activeIndex={visualizationIdToTabIdx[currentTab]}
          renderActiveOnly
        />
        {/* We need to render these visualizations outside of the Tabs setup so they can be exported all at once when user clicks ExportButton */}
        <div id="off-screen">
          {_.map(visualizationsForExportToShow, _.identity)}
        </div>
      </div>
    </ColorContext.Provider>
  );
};

export default ABReportingVisualization;
