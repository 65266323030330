import React, { useContext, useEffect, useMemo, useState } from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import { max, mean, min, scaleThreshold, select } from 'd3';
import { MetricsFormattingConstants } from 'constantsBase';
import {
  FlightExtIdToAlias,
  KPIOptions as KPIOptionsType,
  VariantToFlightExtId,
  KPIsByDeviceFlight,
  KPIsByVariantWithVariant,
  KPIsBySizeFlight,
  KPIsWithSizeFlight,
  AugmentedStatBoxData,
} from 'containers/ABInsights/types';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { getColorScaleDomainBuilder } from 'charts/utils';
import { COLORS_PINK_TO_GREEN } from 'charts/constants';
import VizHeader from 'charts/InsightsViz/Components/VizHeader';
import SlideTitle from 'charts/InsightsViz/Components/SlideTitle';
import 'charts/styles/ab-report-new.sass';
import { Icon, ObjectDropdown } from 'buildingBlocks';
import { GoalType } from 'utils/types';
import MessageContainer from 'charts/Components/MessageContainer';
import { ABColorContext, BudgetType, BudgetTypeOptions, ColorContext } from './ABReportViz';
import InsightsPanel from './Components/InsightsPanel';
import { customColorScaleBuilder, getColorRange, getCreativeDimensionsPixelRatio, getDataSansOutliers, getDiffConfig, getInsightsByVizId, Greater } from './utils';
import KPILegend from './KPILegend';
import List, { Datum } from './List';
import { EXPORT_PREFIX, SlideIcons, VizId } from './constants';
import CopilotLegendIndicator from './Components/CopilotLegendIndicator';
import { renderStatbox } from './StatBoxes/utils';

const SVG_MARGINS = { top: 13, bottom: 13, left: 0, right: 0 };
const SVG_WIDTH = 614;
const SVG_HEIGHT = 457;
const ARBITRARY_SINGLE_DATUM = ['single-datum'];

export type CombinedDatum = {
  a: KPIsWithSizeFlight,
  b: KPIsWithSizeFlight,
  dimensions: string,
  width: number,
  height: number
};

type Props = {
  vizId: string
  data: KPIsBySizeFlight
  flatData: any
  KPIOptions: KPIOptionsType
  flightExtIdToAlias: FlightExtIdToAlias
  variantToFlightExtId: VariantToFlightExtId
  currency: string
  kpisByVariantWithVariant: KPIsByVariantWithVariant
  tooltipContent: string
  budgetTypeOptions: BudgetTypeOptions
  statBoxData: AugmentedStatBoxData
  selectedBudgetType: BudgetType
  setSelectedBudgetType: Function
  selectedKPI: Metric & GoalType
  setSelectedKPI: Function
};

const CreativeSizeVisualization = ({
  vizId,
  data,
  flatData,
  KPIOptions,
  flightExtIdToAlias,
  variantToFlightExtId,
  currency,
  kpisByVariantWithVariant,
  tooltipContent,
  budgetTypeOptions,
  statBoxData,
  selectedBudgetType,
  setSelectedBudgetType,
  selectedKPI,
  setSelectedKPI,
}: Props) => {
  const [hoveredCreativeSize, setHoveredCreativeSize] = useState<string>(null);
  const [winner, setWinner] = useState<Greater>(null);
  const [colors] = useContext<ABColorContext>(ColorContext);

  const { value: budgetType } = selectedBudgetType;

  const { a, b } = variantToFlightExtId;

  const { value: selectedKPIValue, lowerIsBetter, format: KPIFormat } = selectedKPI;
  const avgMetricValue = mean([
    kpisByVariantWithVariant.a[selectedKPIValue],
    kpisByVariantWithVariant.b[selectedKPIValue],
  ]);

  const onCreativeHover = (creativeSize: string) => {
    setHoveredCreativeSize(creativeSize);
    const perfA = data[creativeSize][a][selectedKPIValue];
    const perfB = data[creativeSize][b][selectedKPIValue];
    const { greater } = getDiffConfig(perfA, perfB, lowerIsBetter);
    setWinner(greater);
  };

  const onCreativeUnHover = () => {
    setHoveredCreativeSize(null);
    setWinner(null);
  };

  const memoData = useMemo(() => {
    const creativeDataWithNoOutliers = getDataSansOutliers(flatData, selectedKPIValue);
    const dataWhereBothABExist = _.omitBy(data, (KPIsByFlightExtId) => (_.size((KPIsByFlightExtId)) !== 2));

    const buildColorScaleDomain = getColorScaleDomainBuilder(avgMetricValue);
    const colorScaleDomain = buildColorScaleDomain(min(creativeDataWithNoOutliers, (d) => d[selectedKPIValue]), max(creativeDataWithNoOutliers, (d) => d[selectedKPIValue]));
    const colorRange = getColorRange(selectedKPI, COLORS_PINK_TO_GREEN);

    const colorScale = scaleThreshold<number, string>().range(colorRange);
    colorScale.domain(colorScaleDomain);

    const customColorScale = customColorScaleBuilder(colorScale, selectedKPIValue);
    const getDims = (dimensions: string): Array<number> => {
      if (dimensions === 'Unknown') {
        return [100, 100];
      }
      return _.map(_.split(dimensions, 'x'), _.toInteger);
    };

    const topData = _
      .chain(dataWhereBothABExist)
      .orderBy((abData) => _.sumBy(_.values(abData), budgetType), 'desc')
      .take(10)
      .map((d: Array<KPIsByDeviceFlight>): CombinedDatum => {
        const { dimensions } = d[a];
        const [width, height] = getDims(dimensions);
        return {
          a: d[a],
          b: d[b],
          dimensions,
          width,
          height,
        };
      })
      .value() as unknown as Array<CombinedDatum>;
    const transposePixelRatio = getCreativeDimensionsPixelRatio(topData, dataWhereBothABExist, SVG_MARGINS, SVG_WIDTH, SVG_HEIGHT);
    return { colorScale, topData, customColorScale, dataWhereBothABExist, transposePixelRatio };
  }, [budgetType, data, selectedKPIValue, selectedKPI, flatData, avgMetricValue, a, b]);

  useEffect(() => {
    const container = select(`#${vizId} #chart svg`)
      .attr('width', SVG_WIDTH)
      .attr('height', SVG_HEIGHT);

    let offsetX = 0;
    container.selectAll('.creative')
      .data(_.keys(memoData.dataWhereBothABExist))
      .join((enter) => enter
        .append('g')
        .attr('class', 'creative'))
      .style('opacity', (d: string) => {
        const isActive = _.isNull(hoveredCreativeSize) || (hoveredCreativeSize === d);
        return isActive ? 1 : 0.15;
      })
      .each(function addRectsLabelsLine(d, i) {
        const group = select(this)
          .attr('transform', `translate(${offsetX}, 0)`);

        const relevantItem = _.find(memoData.topData, { dimensions: d }) as unknown as CombinedDatum;
        const { width, height, a: aDatum, b: bDatum, dimensions } = relevantItem;

        const midpointX = ((width * memoData.transposePixelRatio) / 2);
        const midpointY = (SVG_HEIGHT - SVG_MARGINS.top - SVG_MARGINS.bottom) / 2;

        group.selectAll('line')
          .data(ARBITRARY_SINGLE_DATUM)
          .join('line')
          .attr('x1', midpointX)
          .attr('x2', midpointX)
          .attr('y1', SVG_MARGINS.top + (height * memoData.transposePixelRatio))
          .attr('y2', (SVG_HEIGHT - SVG_MARGINS.bottom - (height * memoData.transposePixelRatio)));

        group.selectAll('rect.creative-shape')
          .data([{ datum: aDatum, label: 'a' }, { datum: bDatum, label: 'b' }])
          .join('rect')
          .attr('class', 'creative-shape')
          .attr('transform', ({ label }) => `translate(${0},${label === 'a' ? SVG_MARGINS.top : (SVG_MARGINS.top + 431 - (height * memoData.transposePixelRatio))})`)
          .attr('width', width * memoData.transposePixelRatio)
          .attr('height', height * memoData.transposePixelRatio)
          .attr('stroke', ({ datum }) => memoData.customColorScale(datum as unknown as { [key: string]: number }))
          .attr('fill', ({ datum }) => memoData.customColorScale(datum as unknown as { [key: string]: number }));

        // adds question mark to indicate unknown creative dimensions
        if (dimensions === 'Unknown') {
          group.selectAll('text.unknown')
            .data([{ datum: aDatum, label: 'a' }, { datum: bDatum, label: 'b' }])
            .join('text')
            .attr('class', 'unknown')
            .attr('transform', ({ label }) => `translate(
                ${(width * memoData.transposePixelRatio) / 2}
                ,${label === 'a'
    ? SVG_MARGINS.top + (height * memoData.transposePixelRatio) / 2
    : (SVG_MARGINS.top + 431 - ((height * memoData.transposePixelRatio) / 2))})`)
            .text('?')
            .attr('stroke', 'black');
        }

        group.selectAll('.delivery-label')
          .data([{ datum: aDatum, label: 'a' }, { datum: bDatum, label: 'b' }])
          .join('text')
          .attr('class', 'delivery-label')
          .attr('transform', ({ label }) => `translate(0, ${label === 'a' ? (SVG_MARGINS.top / 2) : (SVG_HEIGHT - SVG_MARGINS.bottom / 2 + 2)})`)
          .text(({ datum }) => {
            const budgetTypeAbbr = (i === 0) ? `${_.toUpper(budgetTypeOptions[budgetType].abbreviation)}: ` : '';
            return `${budgetTypeAbbr} ${numeral(datum[budgetType]).format(MetricsFormattingConstants.ROUNDED_NO_DECIMALS)}`;
          });

        group.selectAll('.dimensions-label')
          .data(ARBITRARY_SINGLE_DATUM)
          .join('text')
          .text(d)
          .attr('class', 'dimensions-label')
          .attr('dx', midpointX)
          .attr('dy', midpointY + 4)
          .style('transform-origin', `${midpointX}px ${midpointY}px`);

        // adding a transparent rect hitbox so we can trigger hover behavior from mouse activity
        // anywhere within the relevant zone
        group
          .selectAll('.hitbox')
          .data([d])
          .join('rect')
          .attr('class', 'hitbox')
          .attr('width', width * memoData.transposePixelRatio)
          .attr('height', SVG_HEIGHT)
          .attr('fill', 'transparent')
          .on('mouseenter', onCreativeHover)
          .on('mouseleave', onCreativeUnHover);

        offsetX += (width * memoData.transposePixelRatio + 10);
      });
  }, [budgetType, selectedKPIValue, a, b, hoveredCreativeSize]); // eslint-disable-line react-hooks/exhaustive-deps

  if (_.isEmpty(memoData.dataWhereBothABExist)) {
    return (
      <MessageContainer
        message="There is currently not enough data available for comparison"
        additionalClasses={[VizId.creativeSize, 'ab-viz-placeholder']}
      />
    );
  }
  return (
    <>
      {
        vizId.includes(EXPORT_PREFIX) && (
          <SlideTitle
            section="Creative Size"
            icon={SlideIcons.creativeSize}
          />
        )
      }
      <div className="grid-container">
        <div style={{ display: 'flex' }}>
          <div className="main-visualization">
            <VizHeader
              title="How do Creative Sizes Compare"
              tooltipContent={tooltipContent}
            />
            <div className="controls">
              <div>
                <label htmlFor="budget-select">Budget Type</label>
                <ObjectDropdown
                  // @ts-ignore passthrough props
                  id="budget-select"
                  className="new-color-palette"
                  keyFn={(metric: { text: string, value: string }) => metric.text}
                  options={_.values(budgetTypeOptions)}
                  selection
                  compact
                  onChange={setSelectedBudgetType}
                  value={selectedBudgetType}
                />
              </div>
              <div>
                <label htmlFor="kpi-select">Goal</label>
                <ObjectDropdown
                  // @ts-ignore passthrough props
                  id="kpi-select"
                  className="new-color-palette"
                  keyFn={(metric: { text: string, value: string }) => metric.text}
                  options={KPIOptions}
                  selection
                  compact
                  onChange={setSelectedKPI}
                  value={selectedKPI}
                />
              </div>
            </div>
            <div id="chart">
              <hr style={{ top: ((SVG_HEIGHT - SVG_MARGINS.top - SVG_MARGINS.bottom) / 2) + SVG_MARGINS.top }} />
              <svg />
              <span className="legend" style={{ color: colors.a }}>
                A: {flightExtIdToAlias[a]}
                {kpisByVariantWithVariant.a.isCopilot && <CopilotLegendIndicator />}
              </span>
              <span className="legend" style={{ color: colors.b }}>
                B: {flightExtIdToAlias[b]}
                {kpisByVariantWithVariant.b.isCopilot && <CopilotLegendIndicator />}
              </span>
              {!_.isNull(winner) && (winner !== 'equal') && (
                <Icon
                  className="winner-check"
                  name="check circle"
                  style={{ color: colors[winner], position: 'absolute', left: 100, bottom: winner === 'a' ? 300 : 100 }}
                  data-winner={winner}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }} />
        <KPILegend
          id={`${vizId}-updated-color-legend`}
          data={{
            colorRange: memoData.colorScale.range(),
            domain: memoData.colorScale.domain(),
            format: (d) => numeral(d).format(KPIFormat),
          }}
          width={196}
          showMeanValue
          meanValue={avgMetricValue}
          selectedMetric={selectedKPI}
          currencyCode={currency}
          showGoalValue={false}
        />
        <List
          title="Creative Size Summary"
          budgetType={selectedBudgetType}
          kpi={selectedKPI}
          accessor="dimensions"
          data={memoData.topData as unknown as Array<Datum>}
          colorScale={memoData.customColorScale}
          budgetTypeOptions={budgetTypeOptions}
        />
      </div>
      {renderStatbox(selectedKPIValue) && (
        <InsightsPanel
          abColors={colors}
          insights={getInsightsByVizId(VizId.creativeSize, statBoxData, selectedKPI, selectedBudgetType)}
        />
      )}
    </>
  );
};

export default CreativeSizeVisualization;
