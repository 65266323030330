import * as _ from 'lodash';
import * as d3 from 'd3';
import { MAX_CLUSTER_NODES, MAX_FIRSTLEVEL_LEAVES } from 'charts/HeliosDataViz/Helios/HeliosTree/constants';
import { parseDate, parseTime, deterministicShuffle } from 'charts/utils';
import { KPI_TO_SUCCESS_EVENTS } from 'charts/constants';
import {
  HEADER_METRICS,
  METRIC_COLORS,
  POPUP_CONFIG,
  RANKLIST_CONFIG,
  METRIC_ALIASES,
  METRIC_FORMATS,
  TRELLIS_CONFIG,
  TRELLIS_TICK_FORMATS,
  LOCALIZE_METRIC_MAPPINGS,
} from './constants';
import { deepFreeze } from './utils';
import { LookupTable, TreeLikeData, DateRange, HeliosData } from './types';

const getDatesAsc = (data: TreeLikeData, key: string) => (
  Object.keys(data[key]).sort((a, b) => parseDate(a).getTime() - parseDate(b).getTime())
);

export const filterDailyDates = (dates: Array<string>, dateRange: DateRange) => {
  if (!dateRange.startDate) {
    return dates;
  }
  return _.filter(
    dates,
    (d) => parseDate(d) >= parseDate(dateRange.startDate) && parseDate(d) <= parseDate(dateRange.endDate),
  );
};

export const getTimeseriesValues = (data: TreeLikeData, metric, dateRange) => {
  const dailyDates = getDatesAsc(data, 'dailyMetrics');
  const filteredDates = filterDailyDates(dailyDates, dateRange);

  // this will always select the last day in the entire range *not the filtered range*
  // is this right?
  const currentDateTimestamp = parseDate(_.last(dailyDates));
  return _.map(filteredDates, (d) => ({
    date: d,
    currentDateTimestamp, // should get renamed to something like lastDateInRange
    value: data.dailyMetrics[d][metric],
  }));
};

export const getTrellisData = (data: TreeLikeData, dateRange: DateRange, viewType, goal) => {
  const metricsList = TRELLIS_CONFIG[viewType].concat([goal, KPI_TO_SUCCESS_EVENTS[goal]]);
  const trellisData = _.map(metricsList, (metric) => (
    {
      key: metric,
      label: METRIC_ALIASES[metric],
      metricFormat: METRIC_FORMATS[metric],
      tickFormat: TRELLIS_TICK_FORMATS[metric],
      values: getTimeseriesValues(data, metric, dateRange),
    }
  ));
  return _.some(trellisData, (d) => !!d.values.length) ? trellisData : null;
};

const localizeMetric = (goal, viewType) => LOCALIZE_METRIC_MAPPINGS[viewType][goal] || goal;

export const getLegendData = (data: HeliosData, options, colorAttr, viewType) => {
  const localColorAttr = localizeMetric(colorAttr, viewType);
  return {
    label: METRIC_ALIASES[localColorAttr],
    domain: data.domains.color,
    colorRange: METRIC_COLORS[localColorAttr],
    format: METRIC_FORMATS[localColorAttr],
    options,
  };
};

export const getClusterData = (
  leaves: Array<TreeLikeData>,
  dateKey: string,
  kpi: string,
): HeliosData['clusterData'] => {
  // combine getClusterData & getRadialData so we gather nodes with children in one
  // key and nodes without children in another
  // PRECONDITION: getClusterData expects leaves to already be filtered by date key
  const result = _.cloneDeep(leaves).filter((d) => (d.children === undefined) || (d.children.length === 0));

  return {
    children: result.sort((a, b) => {
      const key = `${dateKey}.${kpi}`;
      const bVal = _.get(b, key, 0);
      const aVal = _.get(a, key, 0);
      return bVal - aVal;
    }).slice(0, MAX_CLUSTER_NODES),
    name: 'root',
  };
};

export const getRadialData = (leaves: Array<TreeLikeData>, dateKey: string): HeliosData['radialData'] => {
  const leavesWithChildren = leaves.filter((d) => d.children && d.children.length > 0);

  const truncatedResults = (leavesWithChildren.length > MAX_FIRSTLEVEL_LEAVES)
    ? _.takeRight<TreeLikeData>(
      _.sortBy<TreeLikeData>(leavesWithChildren, (r) => {
        const key = `${dateKey}.impressions`;
        return _.get(r, key, 0);
      }),
      MAX_FIRSTLEVEL_LEAVES,
    )
    : leavesWithChildren;

  return {
    children: deterministicShuffle(truncatedResults),
    name: 'root',
  };
};

// recursive
export const filterByDateRange = (data: TreeLikeData, dateRange: DateRange) => {
  try {
    if (!dateRange.endDate) { return data; }
    const result = data;
    if (result.children) {
      result.children = result.children
        .filter((c) => d3.timeDay.offset(parseTime(c.createdAt), 1) <= parseDate(dateRange.endDate));
      result.children.forEach((c) => filterByDateRange(c, dateRange));
    }
    return result;
  } catch {
    return data;
  }
};

export const getDateRanges = (data: TreeLikeData) => {
  if (_.isEmpty(data)) {
    return [];
  }
  const weeklyDates = getDatesAsc(data, 'weeklyMetrics');
  const dateRanges = _.map(weeklyDates, (d) => ({
    timeInterval: 'Week of',
    startDate: d,
    endDate: data.weeklyMetrics[d].weekEnd,
  }));

  return dateRanges.concat([{
    timeInterval: 'Last 60 Days',
    startDate: null,
    endDate: null,
  }]).reverse();
};

const getRanklistOptions = (data) => {
  const keys = Object.keys(data[RANKLIST_CONFIG.topFeaturesKey]);
  return _.map(keys, (k) => ({
    key: k,
    ...RANKLIST_CONFIG.topFeatures[k],
  }));
};

const getRanklist = (obj, selectedOption, kpi) => {
  const featureKey = selectedOption.key;
  const kpiKey = _.get(RANKLIST_CONFIG, `sortbyKey.${kpi}`);
  return _.get(obj, `${RANKLIST_CONFIG.topFeaturesKey}.${featureKey}.${kpiKey}`, []);
};

const filterRanklistData = (data, dateRange) => {
  if (!dateRange.startDate) {
    return data;
  }

  return data[dateRange.startDate] || Object.values(data).pop();
};

export const getRanklistData = (data, dateRange, index, kpi) => {
  try {
    const filteredData = filterRanklistData(data, dateRange);
    const options = getRanklistOptions(filteredData);
    const list = getRanklist(filteredData, options[index], kpi);

    return {
      options,
      list: _.map(list, (d) => ({
        key: options[index].childKey,
        name: _.get(d, `${options[index].childKey}`),
        metricPrimary: {
          key: kpi,
          label: METRIC_ALIASES[kpi],
          value: d[kpi],
        },
        metricSecondary: null,
      })),
    };
  } catch {
    return null;
  }
};

export const getDomain = (data: Array<TreeLikeData>, key: string) => d3.extent(data, (d) => d[key]);

export const getPopupMetrics = (viewType, goal) => {
  const metricsList = _.cloneDeep(POPUP_CONFIG[viewType]);
  metricsList.splice(metricsList.length - 2, 0, localizeMetric(goal, viewType), KPI_TO_SUCCESS_EVENTS[goal]);

  return metricsList.reduce((obj, val) => {
    // eslint-disable-next-line no-param-reassign
    obj[val] = METRIC_ALIASES[val];
    return obj;
  }, {});
};

// LookupTable still needs to be more defined.
const getLeavesMetrics = (lookupTable: LookupTable, dateRange: DateRange): Array<TreeLikeData> => {
  const data = dateRange.startDate ? lookupTable.weeklyMetrics[dateRange.startDate] : lookupTable.totalMetrics;
  // @ts-ignore
  return Object.values(data).filter((d) => d.isLeaf || d.wasLeaf);
};

const getDateRangeKey = (dateRange: DateRange) => (
  dateRange.startDate ? `weeklyMetrics.${dateRange.startDate}` : 'totalMetrics'
);

const filterLookup = (lookupTable, dateRange: DateRange) => (
  dateRange.startDate ? lookupTable.weeklyMetrics[dateRange.startDate] : lookupTable.totalMetrics
);

export const prepTreeData = (
  data: TreeLikeData,
  lookupTable,
  dateRange: DateRange,
  colorAttr,
  kpi,
  viewType,
  goal,
): HeliosData => {
  deepFreeze(data);

  const filteredTreeData = filterByDateRange(_.cloneDeep(data), dateRange);
  const leavesMetricsData = getLeavesMetrics(lookupTable, dateRange);
  const filteredLookupTable = filterLookup(lookupTable, dateRange);
  const dateRangeKey = getDateRangeKey(dateRange);

  return {
    clusterData: getClusterData(filteredTreeData.children, dateRangeKey, kpi),
    radialData: getRadialData(filteredTreeData.children, dateRangeKey),
    dateRange,
    filteredLookupTable,
    attributes: { size: kpi, stroke: kpi, color: localizeMetric(colorAttr, viewType) },
    domains: {
      color: getDomain(leavesMetricsData, localizeMetric(colorAttr, viewType)),
      stroke: getDomain(leavesMetricsData, kpi),
      size: getDomain(leavesMetricsData, kpi),
    },
    popupMetrics: getPopupMetrics(viewType, goal),
  };
};

const calcLift = (metrics) => ((metrics.advRevenue - metrics.advCost) / metrics.advRevenue);

export const getMetricsData = (filteredLookupTable, leafCount, viewType, goal, rootName) => {
  const rootMetrics = filteredLookupTable[rootName];
  const headerMetrics = _.cloneDeep(HEADER_METRICS[viewType]);

  headerMetrics.splice(1, 0, localizeMetric(goal, viewType), KPI_TO_SUCCESS_EVENTS[goal]);

  const metricsToShow = _.map(headerMetrics, (k) => ({
    key: k,
    value: rootMetrics[k],
  }));

  const additionMetrics = [{
    key: 'leafCount',
    value: leafCount,
  }];

  if (viewType === 'trader') {
    additionMetrics.unshift({
      key: 'lift',
      value: calcLift(rootMetrics),
    });
  }

  return metricsToShow.concat(additionMetrics);
};

export const getLeafCount = (data, dateRange) => (dateRange.startDate ? data.weeks[dateRange.startDate] : data.total);
