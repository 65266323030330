/* eslint-disable react/no-unused-prop-types */
import { pie, arc, interpolate, selectAll } from 'd3';
import _ from 'lodash';
import numeral from 'numeral';
import React, { useContext } from 'react';
import { MetricsFormattingConstants } from 'constantsBase';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { KPIsByVariantWithVariant, KPIs } from 'containers/ABInsights/types';
import { getComparisons } from 'charts/ABReportViz/helpers';
import { ColorContext, ABColorContext, ABColors } from 'charts/ABReportViz/ABReportViz';
import { createId } from './utils';

type BuildVizProps = {
  label: string
  data: KPIsByVariantWithVariant
  accessor: 'impressions' | 'advRevenue'
  outerRadius?: number
  innerRadius?: number
};

const MARGINS = { top: 30, bottom: 60, left: 13, right: 13 };

const buildViz = (props: BuildVizProps, colors: ABColors) => {
  const { innerRadius = 44, outerRadius = 52, label, data, accessor } = props;
  const KPIFormat = MetricsFormattingConstants.NO_DECIMALS;
  const comparisons = getComparisons(data, accessor);
  const winner = comparisons.winner;

  const pieGen = pie<KPIs & { label: string, variant: 'a' | 'b' }>()
    .value((d) => d[accessor])
    .sort(({ variant }) => ((variant === 'b') ? -1 : 1))([data.a, data.b]);
  const arcGen = arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .padAngle(0.03);

  const arcTweenLeft = (d) => {
    const i = interpolate(d.startAngle + 0.1, d.endAngle);
    return (t) => {
      d.endAngle = i(t); // eslint-disable-line no-param-reassign
      return arcGen(d);
    };
  };

  const arcTweenRight = (d) => {
    const i = interpolate(d.endAngle - 0.1, d.startAngle);
    return (t) => {
      d.startAngle = i(t); // eslint-disable-line no-param-reassign
      return arcGen(d);
    };
  };

  const arcTextPlacement = (d) => {
    const aPos = [-70, outerRadius / 2];
    const bPos = [70, outerRadius / 2];
    const position = d.data.variant === 'a' ? aPos : bPos;
    return `translate(${position[0]}, ${position[1]})`;
  };

  const container = selectAll(`.ab-arc.${createId(label)}`)
    .attr('width', 366)
    .attr('height', 137.5);

  const arcsContainer = container.append('g')
    .attr('transform', `translate(${366 / 2}, ${(outerRadius + MARGINS.top)})`);

  const arcs = arcsContainer.selectAll('.arc')
    .data(pieGen)
    .enter()
    .append('g')
    .attr('class', 'arc')
    .attr('fill', (d) => colors[d.data.variant]);

  arcs.append('path')
    .transition()
    .delay(200)
    .duration(1000)
    .attrTween('d', (d) => (d.data.variant === 'a' ? arcTweenRight(d) : arcTweenLeft(d)));
  // .attr('d', arcGen);

  arcs.append('text')
    // Removed text-fadein class because it wasn't showing up on PPT
    .attr('transform', (d) => arcTextPlacement(d))
    .text((d) => `${_.upperCase(d.data.variant)}: ${numeral(d.data[accessor]).format(KPIFormat)}`)
    .style('text-anchor', (_d, i) => ((i === 0) ? 'end' : 'start'))
    .attr('dy', -10.5)
    .attr('data-size', (d) => comparisons.sizes[d.data.variant])
    .style('dominant-baseline', 'ideographic')
    .style('font-weight', 'bold')
    .style('font-size', 16);

  arcs
    .filter((d) => d.data.variant === winner)
    .append('text')
    .attr('class', 'diff') // Removed text-fadein because it wasn't showing up on PPT
    .attr('transform', (d) => arcTextPlacement(d))
    .text(comparisons.percentDiff)
    .style('text-anchor', 'start')
    .attr('dx', (d) => ((d.data.variant === 'a') ? -20 : 20))
    .attr('dy', 6)
    .style('font-size', 12);

  container.append('text')
    .attr('class', 'metric-label')
    .text(label)
    .attr('transform', `translate(0,${MARGINS.top / 2})`);
};

const Arc = (props: BuildVizProps) => {
  const [colors] = useContext<ABColorContext>(ColorContext);
  useMount(() => {
    buildViz(props, colors);
  });

  return <svg className={`${createId(props.label)} ab-arc`} />;
};

export default Arc;
