import _ from 'lodash';
import numeral from 'numeral';
import { deviation, mean, ScaleLinear, ScaleThreshold, Selection } from 'd3';
import { MetricsFormattingConstants } from 'constantsBase';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { COLORS, KPI_TO_SUCCESS_EVENTS } from 'charts/constants';
import { KPIsBySizeFlight, KPIsByFlightDomain, AugmentedStatBoxData } from 'containers/ABInsights/types';
import { SortBy, sortByAscOrDesc } from './Domain';
import { CombinedDatum as CombinedCreativeSizeDatum } from './CreativeSize';
import { statBoxesConfig } from './StatBoxes/config';
import { processABInsightsDataForDisplay } from './StatBoxes/utils';
import { VizId } from './constants';
import { BudgetType } from './ABReportViz';

export const getColorRange = (metric: Metric, colors: Array<string>) => (metric.lowerIsBetter ? _.reverse(_.cloneDeep(colors)) : colors);

export const getZScore = (observedValue: number | null, meanVal: number, standardDev: number): (null | number) => {
  if (_.isNull(observedValue)) {
    return null;
  }
  return (observedValue - meanVal) / standardDev;
};

const getDataWithZScores = (data: Array<any>, performanceMetricValue: string) => {
  const standardDev = deviation(data, (d) => _.get(d, performanceMetricValue));
  const meanVal = mean(data, (d) => _.get(d, performanceMetricValue));
  return _.map(data, (d) => ({
    ...d,
    zScore: getZScore(_.get(d, performanceMetricValue), meanVal, standardDev),
  }));
};

export const getDataSansOutliers = (
  data: Array<any>,
  performanceMetricValue: string,
  outlierZScore: number = 2,
) => {
  const dataWithZScores = getDataWithZScores(data, performanceMetricValue);
  return _.reject(dataWithZScores, (d) => Math.abs(d.zScore) >= outlierZScore);
};

export type Greater = 'a' | 'b' | 'equal' | null;
// greater can mean more or better, depending on if we are passing delivery or performance values
const getGreaterDatum = (valA: number, valB: number, lowerIsBetter: boolean): Greater => {
  const rawDiff = valA - valB;
  if (_.isNil(valA) || _.isNil(valB)) {
    return null;
  }
  if (rawDiff === 0) {
    return 'equal';
  }
  if ((lowerIsBetter && (rawDiff < 0)) || (!lowerIsBetter && (rawDiff > 0))) {
    return 'a';
  }
  return 'b';
};

type DiffConfig = {
  text: string
  greater: Greater
  diffRatio: number
};

export const getDiffConfig = (perfA: number, perfB: number, lowerIsBetter: boolean, version: 'short' | 'long' = 'short'): DiffConfig => {
  const greaterDatum = getGreaterDatum(perfA, perfB, lowerIsBetter);

  let diffText: string;
  let diffRatio: number;
  if (_.isNull(greaterDatum)) {
    diffText = (version === 'short') ? '--' : null;
  } else if (greaterDatum === 'equal') {
    diffText = (version === 'short') ? '=' : 'A is equal to B';
  } else {
    diffRatio = _.divide(Math.abs(perfA - perfB), ((perfA + perfB) / 2));
    const arrow = lowerIsBetter ? '↓' : '↑';
    const compareWord = lowerIsBetter ? 'Lower' : 'Higher';
    const diffTextShort = `${_.upperCase(greaterDatum)} ${arrow}${numeral(diffRatio).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)}`;
    const diffTextLong = `${_.upperCase(greaterDatum)} is ${compareWord} by ${numeral(diffRatio).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)}`;
    diffText = (version === 'short') ? diffTextShort : diffTextLong;
  }
  return {
    text: diffText,
    greater: greaterDatum,
    diffRatio,
  };
};

// removes spaces to create valid id attribute string
// guaranteed to start with a valid character (a)
export const createId = (domain: string) => `a_${(_.replace(_.lowerCase(domain), / /g, ''))}`;

export type CustomColorScaleBuilder = (colorScale: ScaleThreshold<number, string>, kpi: string) => (kpis: { [key: string]: number }) => string;

// returns a function that acts as a colorScale that takes into account no success events
export const customColorScaleBuilder: CustomColorScaleBuilder = (colorScale, kpi) => (kpis) => {
  const successEvents = KPI_TO_SUCCESS_EVENTS[kpi];
  if (kpis[successEvents] === 0) {
    return COLORS.GREYS.NO_DATA;
  }
  return colorScale(kpis[kpi]);
};

export type TooltipConfig = {
  featureLabel: string // e.g. domain
  position: [number, number]
  delivery: {
    a: number
    b: number
    more: Greater
    diffRatio: number
  }
  perf: {
    a: number
    b: number
    better: Greater
    diffRatio: number
  }
};

export const getTooltipConfigDomainViz = (
  currentRow: Selection<any, any, any, any>,
  domainData: KPIsByFlightDomain,
  domain: string,
  a: string,
  b: string,
  selectedBudgetTypeValue: string,
  selectedMetric: Metric,
  xScaleWithCustomClamp,
): TooltipConfig => {
  const yTranslate = +_.last(currentRow.attr('transform').match(/\d+/g));

  const aDatum = domainData[a][domain];
  const bDatum = domainData[b][domain];

  const deliveryA = aDatum[selectedBudgetTypeValue];
  const deliveryB = bDatum[selectedBudgetTypeValue];

  const perfA = aDatum[selectedMetric.value];
  const perfB = bDatum[selectedMetric.value];

  const xA = xScaleWithCustomClamp(perfA);
  const xB = xScaleWithCustomClamp(perfB);

  const perfDiff = getDiffConfig(perfA, perfB, selectedMetric.lowerIsBetter);
  // greater is "more" with delivery so pass false to lowerIsBetter param
  const deliveryDiff = getDiffConfig(deliveryA, deliveryB, false);

  return {
    featureLabel: domain,
    position: [((xA + xB) / 2), yTranslate],
    delivery: {
      a: deliveryA,
      b: deliveryB,
      more: deliveryDiff.greater,
      diffRatio: deliveryDiff.diffRatio,
    },
    perf: {
      a: perfA,
      b: perfB,
      better: perfDiff.greater,
      diffRatio: perfDiff.diffRatio,
    },
  };
};

export const getKPIUpliftLabel = (
  isFirst: boolean,
  isLast: boolean,
  thereAreLeftOutliers: boolean,
  thereAreRightOutliers: boolean,
  lowerIsBetter: boolean,
  secondTickVal: number,
  secondToLastTickVal: number,
  val: number,
  metricFormat: string,
) => {
  const f = (v) => numeral(v).format(metricFormat);
  if (lowerIsBetter) {
    if (isFirst && thereAreLeftOutliers) {
      return `> ${f(secondTickVal)}`;
    }
    if (isLast && thereAreRightOutliers) {
      return `< ${f(secondToLastTickVal)}`;
    }
  } else {
    if (isFirst && thereAreLeftOutliers) {
      return `< ${f(secondTickVal)}`;
    }
    if (isLast && thereAreRightOutliers) {
      return `> ${f(secondToLastTickVal)}`;
    }
  }
  return f(val);
};

export const getXScaleWithCustomClampDomainViz = (
  xScale: ScaleLinear<number, number>,
  xTicks: Array<number>,
  thereAreLeftOutliers: boolean,
  thereAreRightOutliers: boolean,
  nullSectionWidth: number,
): (val: number
  ) => number => {
  const [xMin, xMax] = xScale.range();
  const lowerTickStep = xScale(xTicks[1]) - xScale(xTicks[0]);
  const upperTickStep = xMax - xScale(_.last(xTicks));
  return (val: number) => {
    if (_.isNull(val)) {
      return (nullSectionWidth / 2);
    }
    return _.clamp(xScale(val), (thereAreLeftOutliers ? xMin + (lowerTickStep / 2) : xMin), (thereAreRightOutliers ? xMax - (upperTickStep / 2) : xMax));
  };
};

export const getSortedDomains = (
  topNDomainsByCombinedDelivery: Array<string>,
  data: KPIsByFlightDomain,
  a: string,
  b: string,
  selectedKPIValue: string,
  budgetType: string,
  sortBy: SortBy,
) => (_.orderBy(topNDomainsByCombinedDelivery, (domain: string) => {
  const aDatum = data[a][domain];
  const bDatum = data[b][domain];
  const perfA = aDatum[selectedKPIValue];
  const perfB = bDatum[selectedKPIValue];
  const deliveryA = aDatum[budgetType];
  const deliveryB = bDatum[budgetType];
  switch (sortBy) {
    case (SortBy.a):
      return perfA;
    case (SortBy.b):
      return perfB;
    case (SortBy.totalDelivery):
      return deliveryA + deliveryB;
    case (SortBy.abDiff):
    default:
      return Math.abs(perfA - perfB);
  }
}, [sortByAscOrDesc[sortBy]])
);

export const getCreativeDimensionsPixelRatio = (
  topData: Array<CombinedCreativeSizeDatum>,
  dataWhereBothABExists: KPIsBySizeFlight,
  SVG_MARGINS: { [key: string]: number },
  SVG_WIDTH: number,
  SVG_HEIGHT: number,
): number => {
  // If all data has unknown creative size, we want that square to be 100px x 100px
  if (_.every(topData, ({ dimensions }) => (dimensions === 'Unknown'))) {
    return 1;
  }
  const maxWidthAvailable = SVG_WIDTH - SVG_MARGINS.left - SVG_MARGINS.right;
  const sumOfWidthsOfCreativeData = _.sumBy(_.values(topData), 'width');
  const sumInnerPadding = (_.size(dataWhereBothABExists) - 1) * 10;
  const widthRatio = (maxWidthAvailable - sumInnerPadding) / sumOfWidthsOfCreativeData;

  const maxHeightAvailable = (SVG_HEIGHT - SVG_MARGINS.top - SVG_MARGINS.bottom) / 2;
  const maxHeightInCreativeData = _.max(_.map(_.values(topData), 'height'));
  const heightRatio = (maxHeightAvailable - 10) / maxHeightInCreativeData;

  return _.min([widthRatio, heightRatio]);
};

// to be used for scaleThreshold. We need one fewer data points in domain than we have in range
export const getABDiffColorScaleDomain = (maxADiff: number, maxBDiff: number) => {
  const stepLo = maxADiff / 2;
  const stepHi = maxBDiff / 2;
  return [
    -maxADiff,
    -stepLo,
    0,
    stepHi,
    maxBDiff,
  ];
};

export const addStripePattern = (container) => {
  const defs = container.append('defs');

  const stripes = defs.append('pattern')
    .attr('id', 'stripes')
    .attr('width', 4)
    .attr('height', 4)
    .attr('patternUnits', 'userSpaceOnUse')
    .attr('patternTransform', 'rotate(-45 50 50)');

  stripes.append('line')
    .attr('stroke', COLORS.GREYS[500])
    .attr('stroke-width', 1)
    .attr('y2', 20);
  return 'stripes';
};

export const getInsightsByVizId = (vizId: VizId, data: AugmentedStatBoxData, selectedKPI: Metric, selectedBudgetType?: BudgetType) => {
  const processingFn = processABInsightsDataForDisplay[vizId];
  const config = statBoxesConfig[vizId];
  if (processingFn) {
    const processedData = processingFn(data, selectedKPI, selectedBudgetType);
    return { config, data: processedData };
  }
  return { config, data };
};
