import React, { Dispatch, useContext, useEffect, useMemo, useState } from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';
import { deviation, ScaleLinear, scaleLinear, scaleSqrt, select } from 'd3';
import { GOAL_TYPES, MetricsFormattingConstants } from 'constantsBase';
import { Button, ObjectDropdown, Checkbox, Icon } from 'buildingBlocks';
import {
  FlightExtIdToAlias,
  KPIOptions as KPIOptionsType,
  KPIsByFlightDomain,
  VariantToFlightExtId,
  KPIsByVariantWithVariant as KPIsByVariantWithVariantType,
  AugmentedStatBoxData,
} from 'containers/ABInsights/types';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { getValForZScore } from 'charts/utils';
import { isCostBasedGoal, isRateBasedGoal } from 'charts/InsightsViz/utils';
import VizHeader from 'charts/InsightsViz/Components/VizHeader';
import SlideTitle from 'charts/InsightsViz/Components/SlideTitle';
import { SlideIcons } from 'charts/InsightsViz/constants';
import MessageContainer from 'charts/Components/MessageContainer';
import { GoalType } from 'utils/types';
import { ABColorContext, ABColors, ColorContext, BudgetType, BudgetTypeOptions } from './ABReportViz';
import InsightsPanel from './Components/InsightsPanel';
// @ts-ignore
import arrowImg from './img/arrow.svg';
import { createId, getDiffConfig, getInsightsByVizId, getKPIUpliftLabel, getSortedDomains, getTooltipConfigDomainViz, getXScaleWithCustomClampDomainViz, TooltipConfig } from './utils';
import Tooltip from './Tooltip';
import ABLegend from './Components/ABLegend';
import { EXPORT_PREFIX, VizId } from './constants';
import { renderStatbox } from './StatBoxes/utils';

const CheckWinnerIconHtml = (color: string) => ReactDOMServer.renderToString(
  <Icon name="check circle" style={{ color }} />,
);

const ROW_HEIGHT = 22;
const SVG_WIDTH = 1163;
const SVG_HEIGHT = 488;
const NULL_SECTION_WIDTH = 36;
const CIRCLE_RADIUS = 6;
const Y_ADJUST = 4;

export enum SortBy {
  a = 'A',
  b = 'B',
  abDiff = 'ABDiff',
  totalDelivery = 'totalDelivery',
}

enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export const sortByAscOrDesc: { [key in SortBy]: SortOrder } = {
  [SortBy.a]: SortOrder.asc,
  [SortBy.b]: SortOrder.asc,
  [SortBy.abDiff]: SortOrder.desc,
  [SortBy.totalDelivery]: SortOrder.desc,
};

const positions = {
  totalDelivery: 0,
  domain: 126,
  performance: 292,
  difference: 1060,
};

const buildViz = (
  vizId: string,
  topNDomains: Array<string>,
  domainData: KPIsByFlightDomain,
  xScale: ScaleLinear<number, number>,
  deliveryBarScale: ScaleLinear<number, number>,
  circleRadiusScale: ScaleLinear<number, number>,
  selectedMetric: Metric,
  selectedBudgetType: BudgetType,
  colors: ABColors,
  sizeByDelivery: boolean,
  setTooltip: Dispatch<TooltipConfig>,
  variantToFlightExtId: VariantToFlightExtId,
  thereAreLeftOutliers: boolean,
  thereAreRightOutliers: boolean,
  xScaleWithCustomClamp,
) => {
  const { a, b } = variantToFlightExtId;
  const container = select(`#${vizId} svg`);

  const rowContainer = container.select('#rowContainer');

  // set up rows (total delivery + domain sections)
  const rows = rowContainer
    .selectAll('.row')
    .data(topNDomains)
    .join((enter) => enter
      .append('g')
      .attr('class', 'row')
      .call((g) => {
        g.append('rect').attr('class', 'hover-rect');

        g.append('g').attr('class', 'domain').append('text');

        g.append('g').attr('class', 'total-delivery')
          .call((deliveryGroup) => {
            deliveryGroup.append('path');
            deliveryGroup.append('text');
          });
        g.append('g').attr('class', 'difference').append('text');
      }))
    .attr('transform', (_d, i) => `translate(0,${(ROW_HEIGHT * i)})`)
    .on('mouseover', function setTooltipData(domain) {
      // highlight row
      const currentRow = select(this);
      currentRow.select('.hover-rect').classed('hovered', true);

      // bold domain name and delivery
      currentRow.select('.domain').select('text').classed('hovered', true);
      currentRow.select('.total-delivery').select('text').classed('hovered', true);

      const perfA = domainData[a][domain][selectedMetric.value];
      const perfB = domainData[b][domain][selectedMetric.value];

      const { greater } = getDiffConfig(perfA, perfB, selectedMetric.lowerIsBetter);
      if (_.includes(['a', 'b'], greater)) {
        currentRow.select('.difference')
          .append('foreignObject')
          .attr('height', 20).attr('width', 20)
          .attr('x', 80)
          .attr('y', -10)
          .html(CheckWinnerIconHtml(greater === 'a' ? colors.a : colors.b));
      }

      // outline circles and line between them
      const perfRow = container.select(`.perf-row#${createId(domain)}`);
      perfRow.selectAll('circle').classed('selected', true);
      perfRow.selectAll('line').classed('selected', true);

      // bring group to the front
      (perfRow.node() as HTMLElement).parentNode.appendChild(perfRow.node() as HTMLElement);

      // update tooltip
      const tooltipConfig = getTooltipConfigDomainViz(currentRow, domainData, domain, a, b, selectedBudgetType.value, selectedMetric, xScaleWithCustomClamp);
      setTooltip(tooltipConfig);
    })
    .on('mouseout', function clearTooltip(domain) {
      // unhighlight row
      const currentRow = select(this);
      currentRow.select('.hover-rect').classed('hovered', false);

      // un-bold domain name and delivery
      currentRow.select('.domain').select('text').classed('hovered', false);
      currentRow.select('.total-delivery').select('text').classed('hovered', false);

      // remove outlines on circles and line
      const perfRow = container.select(`.perf-row#${createId(domain)}`);
      perfRow.selectAll('circle').classed('selected', false);
      perfRow.selectAll('line').classed('selected', false);

      // remove winner check
      currentRow.select('.difference').select('foreignObject').remove();

      // reset tooltip
      setTooltip(null);
    });

  rows.select('.total-delivery')
    .each(function addDeliveryBarAndText(d) {
      const group = select(this);
      const deliveryA = domainData[a][d][selectedBudgetType.value];
      const deliveryB = domainData[b][d][selectedBudgetType.value];
      const sumDelivery = _.sumBy([deliveryA, deliveryB]);

      group.select('path')
        .attr('d', `M 0,2.5
          L 0,2.5 
          A 3 3 180 1 1
          0,17.5
          L 0,17.5 Z`)
        .transition()
        .duration(1000)
        .attr('d', `M 0,2.5
        L ${deliveryBarScale(sumDelivery) - 3},2.5 
        A 3 3 180 1 1
        ${deliveryBarScale(sumDelivery) - 3},17.5
        L 0,17.5 Z`);

      group
        .select('text')
        .attr('dx', 4)
        .attr('dy', ROW_HEIGHT / 2)
        .text(numeral(sumDelivery).format(MetricsFormattingConstants.ROUNDED_ONE_DECIMAL));
    });

  rows.select('.domain')
    .each(function appendRow(d) {
      const group = select(this);
      group
        .select('text')
        .attr('dy', ROW_HEIGHT / 2)
        .attr('transform', `translate(${positions.domain},0)`)
        .text(_.truncate(d, { length: 25 }));
    });

  // performance viz x tick marks
  const xTicks = xScale.ticks();
  container
    .select('#xTicks #innerTicks')
    .selectAll('.perf-tick')
    .data(xTicks)
    .join((enter) => enter.append('g')
      .attr('class', 'perf-tick')
      .each(function addTickLineAndLabel() {
        const group = select(this);

        group.append('line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', 25)
          .attr('y2', SVG_HEIGHT - 3);

        group.append('text')
          .attr('transform', `translate(${2},${SVG_HEIGHT - 3})`);
      }), (update) => update, (exit) => exit.remove())
    // update transform and text content when data (ticks) change
    .attr('transform', (d) => `translate(${xScale(d)},0)`)
    .each(function labelTick(d, i) {
      const isFirst = (i === 0);
      const secondTickVal = xTicks[1];
      const isLast = (i === _.size(xTicks) - 1);
      const secondToLastTickVal = xTicks[_.size(xTicks) - 2];

      const label = getKPIUpliftLabel(isFirst, isLast, thereAreLeftOutliers, thereAreRightOutliers, selectedMetric.lowerIsBetter, secondTickVal, secondToLastTickVal, d, selectedMetric.format);

      select(this).select('text')
        .text(label);
    });

  // add diff text (furthest right column)
  rows.select('.difference')
    .attr('transform', `translate(${positions.difference},${ROW_HEIGHT / 2})`)
    .each(function appendRow(d) {
      const group = select(this);

      const perfA = domainData[a][d][selectedMetric.value];
      const perfB = domainData[b][d][selectedMetric.value];

      const { text: diffText, greater } = getDiffConfig(perfA, perfB, selectedMetric.lowerIsBetter);
      let fillColor;
      switch (greater) {
        case 'a':
          fillColor = colors.a;
          break;
        case 'b':
          fillColor = colors.b;
          break;
        default:
          fillColor = 'unset';
          break;
      }
      group.select('text')
        .attr('dx', 8)
        .attr('fill', fillColor)
        .text(diffText);
    });

  // perf viz
  const perfVizContainer = container.select('#perfViz');

  perfVizContainer
    .selectAll('.perf-row')
    .data(topNDomains)
    .join('g')
    .attr('class', 'perf-row')
    .attr('id', createId)
    .attr('transform', (_d, i) => `translate(${positions.performance},${(ROW_HEIGHT * (i + 0.5))})`)
    .each(function buildPerfViz(d) {
      const perfA = domainData[a][d][selectedMetric.value];
      const perfB = domainData[b][d][selectedMetric.value];

      const xA = !perfA || !perfB ? 0 : xScaleWithCustomClamp(perfA);
      const xB = !perfA || !perfB ? 0 : xScaleWithCustomClamp(perfB);

      const perfViz = select(this);

      perfViz
        .selectAll('line')
        .data(['single-line'])
        .join('line')
        .attr('x1', xA)
        .attr('x2', xB)
        .attr('y1', 0)
        .attr('y2', 0)
        .attr('opacity', 0)
        .transition()
        .duration(500)
        .delay(500)
        .attr('opacity', 1);

      const deliveryA = domainData[a][d][selectedBudgetType.value];
      const deliveryB = domainData[b][d][selectedBudgetType.value];

      perfViz
        .selectAll('circle')
        .data([
          { delivery: deliveryA, variant: 'a', xPos: xA, perf: perfA },
          { delivery: deliveryB, variant: 'b', xPos: xB, perf: perfB },
        ])
        .join('circle')
        .attr('data-variant', (dd) => dd.variant)
        .attr('fill', (dd) => colors[dd.variant])
        .transition()
        .duration(1000)
        .attr('cx', (dd) => (dd.xPos))
        .attr('r', (dd) => (sizeByDelivery ? circleRadiusScale(dd.delivery) : CIRCLE_RADIUS));
    });
};

const updateSort = (vizId: string, newData: Array<string>) => {
  const container = select(`#${vizId} svg`);

  const rows = container.selectAll('.row');

  // update rows
  rows.data(newData, _.identity)
    .order()
    .transition()
    .duration(1000)
    .attr('transform', (_d, i) => `translate(0,${(ROW_HEIGHT * i)})`);

  // update perf rows
  container
    .selectAll('.perf-row')
    .data(newData, _.identity)
    .order()
    .transition()
    .duration(1000)
    .attr('transform', (_d, i) => `translate(${positions.performance},${(ROW_HEIGHT * (0.5 + i))})`);
};

const TOP_N = 20;

const getUnitsLabel = (selectedKPIValue: string, currency: string): string => {
  if (isCostBasedGoal(selectedKPIValue)) {
    return `(${currency})`;
  }
  if (isRateBasedGoal(selectedKPIValue)) {
    return '(%)';
  }
  return '';
};

type Props = {
  vizId: string
  data: KPIsByFlightDomain
  KPIOptions: KPIOptionsType
  flightExtIdToAlias: FlightExtIdToAlias
  variantToFlightExtId: VariantToFlightExtId
  currency: string
  tooltipContent: string
  budgetTypeOptions: BudgetTypeOptions
  KPIsByVariantWithVariant: KPIsByVariantWithVariantType
  statBoxData: AugmentedStatBoxData
  selectedBudgetType: BudgetType
  setSelectedBudgetType: Function
  selectedKPI: Metric & GoalType
  setSelectedKPI: Function
  sortBy: SortBy
  setSortBy: Function
  sizeByDelivery: boolean
  setSizeByDelivery: Function
};
const DomainUpdated = ({
  vizId,
  data,
  KPIOptions,
  flightExtIdToAlias,
  variantToFlightExtId,
  currency,
  tooltipContent,
  budgetTypeOptions,
  KPIsByVariantWithVariant,
  statBoxData,
  selectedBudgetType,
  setSelectedBudgetType,
  selectedKPI,
  setSelectedKPI,
  sortBy,
  setSortBy,
  sizeByDelivery,
  setSizeByDelivery,
}: Props) => {
  const [tooltip, setTooltip] = useState<TooltipConfig>(null);

  const [colors] = useContext<ABColorContext>(ColorContext);

  const { value: selectedKPIValue, lowerIsBetter } = selectedKPI;
  const kpi = _.get(GOAL_TYPES, selectedKPIValue);
  let kpiInsightsLabel;
  if (kpi) {
    kpiInsightsLabel = kpi.insightsLabel;
  } else {
    const kpiLabelMap = {
      viewableImpressions: 'Viewable Impressions',
      videoCompletes: 'Video Completes',
      cost: 'Spend',
      impressionValue: 'Impression Value',
      netVcpcv: 'Net Viewable Cost per Completed View',
      conversions: 'Conversions',
      clicks: 'Clicks',
      revenue: 'Revenue',
    };
    kpiInsightsLabel = _.get(kpiLabelMap, selectedKPIValue);
  }

  const { value: budgetType, text: budgetTypeDisplayText, abbreviation: budgetTypeAbbr } = selectedBudgetType;
  const { a, b } = variantToFlightExtId;

  const memoData = useMemo(() => {
    // only care about domains that have data for both A & B flights
    const commonDomains = _.intersection(_.keys(data[a]), _.keys(data[b]));

    const topNDomainsByCombinedDelivery = _.chain<Array<string>>(commonDomains)
      // @ts-ignore
      .orderBy((domain) => _.sumBy([a, b], (flight) => data[flight][domain][budgetType]), SortOrder.desc)
      .take(TOP_N)
      .value();

    // @ts-ignore
    const newTopNDomainsSorted = getSortedDomains(topNDomainsByCombinedDelivery, data, a, b, selectedKPIValue, budgetType, sortBy) as Array<string>;

    const combinedFlatData = _.flatMap(newTopNDomainsSorted, (domain: string) => [data[a][domain], data[b][domain]]);

    const meanKPI = _.meanBy(combinedFlatData, selectedKPIValue);
    const devKPI = deviation(combinedFlatData, (d) => d[selectedKPIValue]);

    const perfMin = _.max([0, getValForZScore(-2, meanKPI, devKPI)]);

    const meanPlusTwoZScores = getValForZScore(2, meanKPI, devKPI);
    const perfMax = isRateBasedGoal(selectedKPIValue) ? _.min([meanPlusTwoZScores, 1]) : meanPlusTwoZScores;

    const thereAreUpperOutliers = _.some(combinedFlatData, (d) => d[selectedKPIValue] > perfMax);
    const thereAreLowerOutliers = _.some(combinedFlatData, (d) => d[selectedKPIValue] < perfMin);
    const thereAreNulls = _.some(combinedFlatData, (d) => _.isNull(d[selectedKPIValue]));

    const perfDomain = lowerIsBetter ? [perfMax, perfMin] : [perfMin, perfMax];
    const xScale = scaleLinear()
      .domain(perfDomain)
      .range([(thereAreNulls ? NULL_SECTION_WIDTH : 0), positions.difference - positions.performance])
      // .clamp(true)
      .nice(); // do we actually want this?

    const thereAreRightOutliers = lowerIsBetter ? thereAreLowerOutliers : thereAreUpperOutliers;
    const thereAreLeftOutliers = lowerIsBetter ? thereAreUpperOutliers : thereAreLowerOutliers;
    const xScaleWithCustomClamp = getXScaleWithCustomClampDomainViz(xScale, xScale.ticks(), thereAreLeftOutliers, thereAreRightOutliers, NULL_SECTION_WIDTH);

    const topDeliveryDomain = _.first(topNDomainsByCombinedDelivery);

    // using array notation because the '.'s in domains messes up dot notation
    // @ts-ignore
    const topTotalDelivery = _.sum([_.get(data, [a, topDeliveryDomain, budgetType], 0), _.get(data, [b, topDeliveryDomain, budgetType], 0)]);

    const deliveryBarScale = scaleLinear()
      .domain([0, topTotalDelivery])
      .range([0, 108]);

    const circleRadiusScale = scaleSqrt()
      .domain([0, topTotalDelivery])
      .range([3, 30]);

    return {
      newTopNDomainsSorted,
      xScale,
      deliveryBarScale,
      circleRadiusScale,
      thereAreRightOutliers,
      thereAreLeftOutliers,
      thereAreNulls,
      xScaleWithCustomClamp,
    };
    // intentionally excluding sortBy here (it has its own useEffect below)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, a, b, selectedKPIValue, budgetType, lowerIsBetter]);

  useEffect(() => {
    const newTopNDomainsSorted = getSortedDomains(memoData.newTopNDomainsSorted, data, a, b, selectedKPIValue, budgetType, sortBy);
    updateSort(vizId, newTopNDomainsSorted as Array<string>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, vizId]);

  useEffect(
    () => {
      // we are computing sorted domains anew because we don't want to make sortBy a dependency of memoData -- it messes up the row animation
      const newTopNDomainsSorted = getSortedDomains(memoData.newTopNDomainsSorted, data, a, b, selectedKPIValue, budgetType, sortBy) as Array<string>;
      buildViz(
        vizId,
        newTopNDomainsSorted,
        data,
        memoData.xScale,
        memoData.deliveryBarScale,
        memoData.circleRadiusScale,
        selectedKPI,
        selectedBudgetType,
        colors,
        sizeByDelivery,
        setTooltip,
        variantToFlightExtId,
        memoData.thereAreLeftOutliers,
        memoData.thereAreRightOutliers,
        memoData.xScaleWithCustomClamp,
      );
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      memoData.newTopNDomainsSorted,
      data,
      memoData.xScale,
      memoData.deliveryBarScale,
      memoData.circleRadiusScale,
      selectedKPI,
      selectedBudgetType,
      colors,
      variantToFlightExtId,
      memoData.thereAreLeftOutliers,
      memoData.thereAreRightOutliers,
      memoData.xScaleWithCustomClamp,
      sizeByDelivery,
    ],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const deliveryCheckboxClick = () => setSizeByDelivery(!sizeByDelivery);
  const unitsText = getUnitsLabel(selectedKPIValue, currency);
  const budgetTypeAbbrText = `${budgetTypeAbbr} ${(budgetType === 'advRevenue') ? `(${currency})` : ''}`;
  const xAdjust = memoData.xScale.range()[0];

  if (_.isEmpty(memoData.newTopNDomainsSorted)) {
    return (
      <MessageContainer
        message="There is currently not enough data available for comparison"
        additionalClasses={[VizId.domain, 'ab-viz-placeholder']}
      />
    );
  }
  return (
    <>
      {
        vizId.includes(EXPORT_PREFIX) && (
          <SlideTitle
            section="Domain"
            icon={SlideIcons.contextualInsights}
          />
        )
      }
      <div className="main-visualization">
        <VizHeader
          title="How do Sites and Applications Compare"
          tooltipContent={tooltipContent}
        />
        <div className="controls">
          <div>
            <label htmlFor="budget-select">Top 20 Domains</label>
            <ObjectDropdown
              // @ts-ignore passthrough props
              id="budget-select"
              className="new-color-palette"
              keyFn={(metric: { text: string, value: string }) => `By ${metric.text}`}
              options={_.values(budgetTypeOptions)}
              selection
              compact
              onChange={setSelectedBudgetType}
              value={selectedBudgetType}
            />
          </div>
          <div>
            <label htmlFor="kpi-select">Goal</label>
            <ObjectDropdown
              // @ts-ignore passthrough props
              id="kpi-select"
              className="new-color-palette"
              keyFn={(metric: { text: string, value: string }) => metric.text}
              options={KPIOptions}
              selection
              compact
              onChange={setSelectedKPI}
              value={selectedKPI}
            />
          </div>
          <div>
            <label>Sort By</label>
            <Button.Group
              className="sort-by-buttons"
            >
              <Button
                onClick={() => setSortBy(SortBy.a)}
                active={sortBy === SortBy.a}
                className="new-color-palette"
              >
                A
              </Button>
              <Button
                onClick={() => setSortBy(SortBy.b)}
                active={sortBy === SortBy.b}
                className="new-color-palette"
              >
                B
              </Button>
              <Button
                onClick={() => setSortBy(SortBy.abDiff)}
                active={sortBy === SortBy.abDiff}
                className="new-color-palette"
              >
                A - B Difference
              </Button>
              <Button
                onClick={() => setSortBy(SortBy.totalDelivery)}
                active={sortBy === SortBy.totalDelivery}
                className="new-color-palette"
              >
                {budgetTypeDisplayText}
              </Button>
            </Button.Group>
            <Checkbox
              checked={sizeByDelivery}
              onClick={deliveryCheckboxClick}
              label="Size circles by delivery"
              type="radio"
              style={{ marginLeft: 12 }}
              className="new-color-palette"
            />
          </div>
        </div>
        <div className="chart-and-tooltip-container">
          <svg className="chart" width={SVG_WIDTH} height={SVG_HEIGHT}>
            <g id="header">
              <text id="delivery" transform={`translate(${positions.totalDelivery},${ROW_HEIGHT - Y_ADJUST})`}> a+b {budgetTypeAbbrText}</text>
              <text transform={`translate(${positions.domain},${ROW_HEIGHT - Y_ADJUST})`}>domain name</text>
              <g transform={`translate(${positions.performance + xAdjust},${ROW_HEIGHT / 2 - Y_ADJUST})`}>
                <image href={arrowImg} x="5" y="4" />
                <text className="arrow-label" dx="104" dy={ROW_HEIGHT / 2}>{lowerIsBetter ? 'high' : 'low'}</text>
                <text id="KPI-viz" dy="9" dx={(positions.difference - positions.performance - xAdjust) / 2}>{kpiInsightsLabel} {unitsText}</text>
                <text className="arrow-label" dx={positions.difference - positions.performance - xAdjust - 100} dy={ROW_HEIGHT / 2}>{lowerIsBetter ? 'low' : 'high'}</text>
                <image href={arrowImg} className="arrow" x={-(positions.difference - positions.performance - xAdjust) + 5} y="-11" />
              </g>
              <text id="difference" dx="8" transform={`translate(${positions.difference},${ROW_HEIGHT - Y_ADJUST})`}>GOAL UPLIFT</text>
              <line x1="0" x2={SVG_WIDTH} y1="24" y2="24" />
            </g>
            <g id="rowContainer" />
            <g id="xTicks" transform={`translate(${positions.performance},0)`}>
              <g id="innerTicks" />
              <g id="outerTicks">
                <line x1="0" x2="0" y1="0" y2={SVG_HEIGHT - 3} />
                {memoData.thereAreNulls && (
                  <text dx="6" y={SVG_HEIGHT - 3}>null</text>
                )}
                <line x1={positions.difference - positions.performance} x2={positions.difference - positions.performance} y1="0" y2={SVG_HEIGHT - 3} />
              </g>
            </g>
            <g id="perfViz" />
          </svg>
          {tooltip && (
            <Tooltip
              tooltip={tooltip}
              selectedKPI={selectedKPI}
              budgetTypeDisplayText={budgetTypeDisplayText}
              colors={colors}
              baseXPosition={positions.performance}
              baseYPosition={30 + (ROW_HEIGHT / 2)}
            />
          )}
          <ABLegend
            colors={colors}
            variantToFlightExtId={variantToFlightExtId}
            flightExtIdToAlias={flightExtIdToAlias}
            KPIsByVariantWithVariant={KPIsByVariantWithVariant}
          />
        </div>
      </div>
      {renderStatbox(selectedKPIValue) && (
        <InsightsPanel
          abColors={colors}
          insights={getInsightsByVizId(VizId.domain, statBoxData, selectedKPI, selectedBudgetType)}
        />
      )}
    </>
  );
};

export default DomainUpdated;
