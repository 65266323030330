import _ from 'lodash';
import { getRealValue } from 'buildingBlocks/ReactHookFormComponents/PercentageInput';

export const setNumberValue = (value: string, validatePercentage: boolean = false): string | number => {
  if (value === '' || value === '-') {
    return value;
  }

  if (value === '.') {
    return '0.';
  }

  if (_.startsWith(value, '0.') && _.every(_.last(_.split(value, '.')), (num) => _.isEqual(num, '0'))) {
    return value;
  }

  if (validatePercentage) {
    return _.toNumber(getRealValue(value, -2));
  }

  return _.toNumber(value);
};
