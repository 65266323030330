import React from 'react';
import numeral from 'numeral';
import { ScaleLinear, scaleSqrt, selectAll } from 'd3';
import _ from 'lodash';
import { GoalType } from 'utils/types';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { KPIsByVariantWithVariant } from 'containers/ABInsights/types';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { ABColors } from '../ABReportViz';
import Comparison from './Comparison';

type Props = {
  kpisByVariantWithVariant: KPIsByVariantWithVariant
  colors: ABColors
  primaryKPI: Metric & GoalType
};

const buildViz = (
  perfA: number,
  perfB: number,
  colors: ABColors,
  radiusScale: ScaleLinear<number, number>,
  KPIFormat: string,
) => {
  const enterGroups = selectAll('.primary-KPI svg')
    .selectAll('g')
    .data([
      { perf: perfA, color: colors.a },
      { perf: perfB, color: colors.b },
    ])
    .enter()
    .append('g');

  if (perfA || perfB) {
    enterGroups
      .append('circle')
      .attr('class', 'outline')
      .attr('r', radiusScale.range()[1] - 1);
  }

  enterGroups
    .append('circle')
    .attr('fill', (d) => d.color)
    .transition()
    .duration(1000)
    .attr('r', (d) => radiusScale(d.perf));

  enterGroups
    .append('text')
    .attr('dy', 10)
    .text((d) => numeral(d.perf).format(KPIFormat));
};

export default ({ kpisByVariantWithVariant, colors, primaryKPI }: Props) => {
  const { value: primaryKPIValue, format: KPIFormat, lowerIsBetter } = primaryKPI;
  const perfA = kpisByVariantWithVariant.a[primaryKPIValue];
  const perfB = kpisByVariantWithVariant.b[primaryKPIValue];
  const radiusScale = scaleSqrt()
    .domain([0, _.max([perfA, perfB, 0.000001])])
    .range([0, 87]);

  useMount(() => {
    buildViz(perfA, perfB, colors, radiusScale, KPIFormat);
  });

  return (
    <div className="primary-KPI">
      <p className="label">Primary Goal</p>
      <p className="metric-label">{primaryKPI.insightsLabel || primaryKPI.text}</p>
      <div className="svg-container">
        <svg />
      </div>
      <Comparison
        perfA={perfA}
        perfB={perfB}
        lowerIsBetter={lowerIsBetter}
        colors={colors}
      />
    </div>
  );
};
