import React from 'react';
import _ from 'lodash';

const SlideWrapper = (props: { children: Array<JSX.Element> | JSX.Element, ids: Array<string> }) => (
  <>
    {
      _.map(props.ids, (id, i) => (
        <div className="ab-viz" key={id}>
          <div className="innerVizContainer" id={id}>
            {props.children[i] || props.children}
          </div>
        </div>
      ))
    }
  </>
);

export default SlideWrapper;
