import React from 'react';

type Props = {
  fill: string
};

export default ({ fill }: Props) => (
  <svg width="32" height="66" viewBox="0 0 32 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.82422" y="8.125" width="28.45" height="50.05" fill={fill} stroke="black" strokeWidth="0.25" />
    <rect x="0.125" y="0.125" width="31.55" height="65.75" rx="3.875" stroke="black" strokeWidth="0.25" />
    <circle cx="16.25" cy="4.5" r="0.625" stroke="black" strokeWidth="0.25" />
    <circle cx="16.25" cy="62.25" r="2.25" stroke="black" strokeWidth="0.25" />
  </svg>
);
