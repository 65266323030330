import React from 'react';
import { TEXT_COLOR, BAR_HEIGHT } from 'charts/HeliosDataViz/constants';

export const MARGINS = {
  top: 48,
  right: 32,
  bottom: 16,
  left: 32,
};

export const TIME_SLIDER_STYLES = {
  height: `${BAR_HEIGHT + MARGINS.top + MARGINS.bottom}px`,
  fontSize: '12px',
  color: TEXT_COLOR,
};

export const TICK_STYLES = {
  height: `${BAR_HEIGHT + 4}px`,
  background: TEXT_COLOR,
  width: '1px',
  pointerEvents: 'none',
  userSelect: 'none',
} as React.CSSProperties;

export const SLIDER_MARGINS = {
  top: 32,
  right: 32,
  bottom: 16,
  left: 32,
};

export const ZOOM_SLIDER_STYLES = {
  height: `${BAR_HEIGHT + SLIDER_MARGINS.top + SLIDER_MARGINS.bottom}px`,
  fontSize: '10px',
  color: TEXT_COLOR,
};
