import React, { useRef, useState, CSSProperties, forwardRef, useCallback, useEffect } from 'react';
import _ from 'lodash';
import 'ag-grid-community/styles/ag-grid.css';
import '@wppopen/components-library/dist/collection/ag-theme-wpp.css';
import { WppSpinner, AgGridReact } from 'buildingBlocks';
import useDeepCompareEffect from 'utils/hooks/useDeepCompareEffect';
import { GridReadyEvent, AgGridReactProps } from 'utils/types';

interface IProps extends AgGridReactProps {
  className?: string
  style?: CSSProperties
  loading?: boolean
  noRowsMessage?: string
}

const GridLoader = () => <WppSpinner />;

const AgGrid = forwardRef<AgGridReact, IProps>(({ ...props }: IProps, ref) => {
  const { columnDefs, rowData, className, style, defaultColDef, loading, noRowsMessage, ...rest } = props;
  const gridRef = useRef<AgGridReact>();
  const [state, setState] = useState({
    columnDefs: [],
    rowData: [],
  });

  const checkLoadingState = (gRef: GridReadyEvent | AgGridReact<any>) : void => {
    if (!gRef.api) return;
    if (loading) {
      gRef.api.showLoadingOverlay();
      gRef.api.deselectAll();
      return;
    }
    if (_.isEqual(gRef.api.getDisplayedRowCount(), 0)) {
      gRef.api.showNoRowsOverlay();
      return;
    }
    gRef.api.hideOverlay();
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    checkLoadingState(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useDeepCompareEffect(() => {
    setState((prevState) => ({ ...prevState, columnDefs, rowData }));
  }, rowData);

  useEffect(() => {
    if (gridRef.current) {
      checkLoadingState(gridRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, gridRef.current]);

  return (
    <div className={`ag-theme-wpp ${className}`} style={style}>
      <AgGridReact
        {...rest}
        ref={(node) => {
          gridRef.current = node;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = node;
          }
        }}
        rowData={state.rowData}
        defaultColDef={defaultColDef}
        columnDefs={state.columnDefs}
        onGridReady={onGridReady}
        domLayout="autoHeight"
        loadingOverlayComponent={GridLoader}
        overlayLoadingTemplate="Loading..."
        overlayNoRowsTemplate={noRowsMessage}
      />
    </div>
  );
});

export default AgGrid;
