import _ from 'lodash';
import React, { useState } from 'react';
import { AgGrid, WppPagination, WppTypography } from 'buildingBlocks';
import { PaginationChangeEventDetail, ColDef } from 'utils/types';

import { SELECTED_OPTIONS_STYLES } from './styles';

const { agGrid } = SELECTED_OPTIONS_STYLES;

export const BasicHeader: React.FC<{ label: string }> = ({ label }: { label: string }): React.ReactElement => (
  <WppTypography tag="p" type="s-strong">
    {label}
  </WppTypography>
);

type WppSelectedOptionsListProps = {
  items: Array<any>
  selectedGridHeaderData: (disabled: boolean, displayFn: (x: unknown) => string, onDeselect: Function) => Array<ColDef>
  displayFn: (x: unknown) => string
  onSelected: (value: any) => void
  defaultValue?: Array<any>
  disabled?: boolean
};

type WppSelectedOptionsListState = {
  itemsPerPage: number
  page: number
};

const WppSelectedOptionsList: React.FC<WppSelectedOptionsListProps> = ({
  items,
  onSelected,
  displayFn,
  defaultValue,
  disabled,
  selectedGridHeaderData,
}: WppSelectedOptionsListProps): React.ReactElement => {
  const [state, setState] = useState<WppSelectedOptionsListState>({
    itemsPerPage: 10,
    page: 1,
  });

  const currentPageItems = items.slice((state.page - 1) * state.itemsPerPage, state.page * state.itemsPerPage);

  const disabledItemInDefaultValues = (item: any) => (disabled && defaultValue && _.includes(_.map(defaultValue, 'id'), item.id));
  const onDeselect = (item: any) => {
    if (!disabled || (defaultValue && !disabledItemInDefaultValues(item))) {
      onSelected(item);
      if (!_.isEmpty(items)) {
        setState((prevState: WppSelectedOptionsListState) => ({
          ...prevState, page: _.isEqual(_.size(currentPageItems) - 1, 0) ? prevState.page - 1 : prevState.page,
        }));
      }
    }
  };

  return (
    <>
      <div style={agGrid}>
        <AgGrid
          rowSelection="multiple"
          columnDefs={selectedGridHeaderData(disabled, displayFn, onDeselect)}
          noRowsMessage="No Advertisers Selected"
          rowData={currentPageItems}
          suppressRowClickSelection
        />
      </div>
      <WppPagination
        count={_.size(items)}
        itemsPerPage={[5, 10]}
        activePageNumber={state.page}
        selectedItemPerPage={state.itemsPerPage}
        onWppChange={(event: Event) => {
          const { itemsPerPage, page } = (event as CustomEvent<
          PaginationChangeEventDetail
          >).detail;
          setState((prevState: WppSelectedOptionsListState) => ({
            ...prevState, itemsPerPage, page,
          }));
        }}
      />
    </>
  );
};

export default WppSelectedOptionsList;
