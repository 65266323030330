import React, { useState, useRef, useEffect } from 'react';
import * as _ from 'lodash';
import { Icon, SemanticICONS } from 'buildingBlocks';
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

type SelectorProps = {
  id: string,
  options: Array<any>,
  onChange: Function,
  valueLabel: Function,
  selectedIndex: number,
  svgClick: {
    isSvgClick?: boolean,
    resetSvgClick?: Function,
  }
  iconOnly?: boolean,
  icon?: SemanticICONS,
  prefix?: string,
};

const Selector = ({ id, options, selectedIndex, onChange, valueLabel, svgClick: { isSvgClick, resetSvgClick },
  iconOnly, icon, prefix }: SelectorProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const onToggle = () => {
    setShowPopup(!showPopup);
  };

  const selectOption = (index: number) => {
    onChange(index);
    setShowPopup(false);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (resetSvgClick && typeof resetSvgClick === 'function') {
      setShowPopup(false);
      resetSvgClick();
    }
  }, [isSvgClick, resetSvgClick]);

  return (
    <div id={id} className={`charts-selector ${showPopup ? 'opened' : ''}`}>
      <div className="selector-btn">
        <button type="button" onClick={onToggle}>
          {!iconOnly && `${prefix || ''}${valueLabel(options[selectedIndex])}`}
          <Icon name={icon} />
        </button>
      </div>
      {showPopup && (
        <div ref={popupRef} className="charts-selector-popup">
          <ul>
            {_.map(options, (d, i) => (
              <li
                key={i}
                className={selectedIndex === i ? 'selected' : ''}
                onClick={() => { selectOption(i); }}
              >
                {valueLabel(d)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Selector;
