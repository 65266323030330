import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import Trellis from 'charts/HeliosDataViz/Trellis/Trellis';
import HeliosTree from 'charts/HeliosDataViz/Helios/HeliosTree/HeliosTree';
import RankList from 'charts/HeliosDataViz/RankList';
import Loader from 'charts/Components/Loader';
import { EnvironmentVariables } from 'utils/types';
import BottomPanel from './BottomPanel';
import HeliosHeader from './HeliosHeader';
import { ZOOM_CONFIG, VIEW_OPTIONS } from './constants';
import { getColorOptions, createLookupTable, goalMapping } from './utils';
import {
  getTrellisData,
  prepTreeData,
  getLegendData,
  getRanklistData,
  getDateRanges,
  getMetricsData,
  getLeafCount,
} from './transforms';
import { HeliosProps } from './types';

const Helios = ({ flights, strategyGoalType, top10, trees, flightIndex,
  onSetFlight, userSelectedTheme, env }: HeliosProps) => {
  /*
    some of our goals, like engagementScore and conversionRevenue, are not yet defined, so we are using substitutes
    - KM 2-26-20
  */
  const goal = goalMapping(strategyGoalType);
  const viewOptions = VIEW_OPTIONS;
  const kpiOptions = ['impressions', 'revenue'];
  const flightDataTrees = trees;
  const flightRanklistData = top10;
  const rootName = trees.name;

  const ranklistWeeklyDataRaw = _.get(flightRanklistData, 'weekly');
  const ranklistTotalDataRaw = _.get(flightRanklistData, 'total');
  // @ts-ignore
  const lookupTable = createLookupTable(flightDataTrees);
  const dateRanges = getDateRanges(flightDataTrees);

  const [kpi, setKpi] = useState<string>(kpiOptions[0]);
  const [kpiIndex, setKpiIndex] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [colorIndex, setColorIndex] = useState<number>(0);
  const [dateRange, setDateRange] = useState(dateRanges[0]);
  const [dateIndex, setDateIndex] = useState<number>(0);
  const [svgClicked, setSvgClicked] = useState<boolean>(false);
  const [viewType, setViewType] = useState<string>(env === EnvironmentVariables.demo ? viewOptions[1].type : viewOptions[0].type);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [animIntro, setAnimIntro] = useState<boolean>(false);
  const [ranklistIndex, setRanklistIndex] = useState<number>(0);
  const [showNode, setShowNode] = useState<string>(null);

  const memoData = useMemo(
    () => {
      setIsLoading(true);
      const colorOptions = getColorOptions(viewType, goal);
      const treeData = prepTreeData(
        flightDataTrees,
        lookupTable,
        dateRange,
        colorOptions[colorIndex],
        kpi,
        viewType,
        goal,
      );
      const leafCount = getLeafCount(flightDataTrees.leafCount, dateRange);

      return {
        treeData,
        trellisData: getTrellisData(flightDataTrees, dateRange, viewType, goal),
        legendData: getLegendData(treeData, colorOptions, colorOptions[colorIndex], viewType),
        dateSelectorData: dateRanges,
        ranklistData: getRanklistData(
          dateRange.startDate ? ranklistWeeklyDataRaw : ranklistTotalDataRaw,
          dateRange,
          ranklistIndex,
          kpi,
        ),
        metricsData: getMetricsData(treeData.filteredLookupTable, leafCount, viewType, goal, rootName),
      };
    },
    // eslint-disable-next-line
    [dateRange, viewType, ranklistIndex, kpi, colorIndex],
    /*
      do not need to recompute data when color index changes
      (we will just use recolorChart function)
     */
  );

  const onSetKPI = (index: number) => {
    setKpi(kpiOptions[index]);
    setKpiIndex(index);
  };

  const onSetDate = (index: number) => {
    setDateIndex(index);
    setDateRange(dateRanges[index]);
  };

  const onSetView = (stringView: string) => {
    setViewType(stringView);
  };

  const onSvgClick = () => {
    setSvgClicked(true);
  };

  const resetSvgClick = () => {
    setSvgClicked(false);
  };

  const doneLoading = () => {
    setIsLoading(false);
    setAnimIntro(true);
  };

  const onSetList = (index: number) => {
    setRanklistIndex(index);
  };

  const zoomProps = {
    domainValues: ZOOM_CONFIG.domain,
    zoomValue: zoom,
    onChange: setZoom,
  };

  const viewProps = {
    options: viewOptions,
    selectedView: viewType,
    onChange: onSetView,
  };

  const colorProps = {
    options: memoData.legendData.options,
    index: colorIndex,
    onChange: setColorIndex,
  };

  const kpiProps = {
    options: kpiOptions,
    index: kpiIndex,
    onChange: onSetKPI,
  };

  const svgClick = useMemo(() => ({
    isSvgClick: svgClicked,
    resetSvgClick,
  }), [svgClicked]);

  return (
    <div className={`helios ${animIntro ? 'intro-animation' : ''}`}>
      <HeliosHeader
        metrics={memoData.metricsData}
        svgClick={svgClick}
        viewProps={viewProps}
        colorProps={colorProps}
        kpiProps={kpiProps}
        dateData={memoData.dateSelectorData}
        onSetDate={onSetDate}
        dateIndex={dateIndex}
        flights={flights}
        flightIndex={flightIndex}
        setFlight={onSetFlight}
      />
      <div className="helios-body">
        {isLoading && <Loader />}
        <div className="helios-overflow">
          <HeliosTree
            data={memoData.treeData}
            zoomValue={zoom}
            onSvgClick={onSvgClick}
            doneLoading={doneLoading}
            setZoom={setZoom}
            userSelectedTheme={userSelectedTheme}
            showNode={showNode}
            setShowNode={setShowNode}
          />
          <Trellis
            width={272}
            data={memoData.trellisData}
          />
          {!_.isNull(memoData.ranklistData) && (
            <RankList
              width={272}
              data={memoData.ranklistData}
              ranklistIndex={ranklistIndex}
              onChange={onSetList}
              kpi={kpi}
              setShowNode={setShowNode}
            />
          )}
          <BottomPanel
            width={360}
            kpi={kpi}
            zoomProps={zoomProps}
            colorData={memoData.legendData}
            colorLabel={memoData.legendData.label}
            goal={goal}
          />
        </div>
      </div>
    </div>
  );
};

export default Helios;
