import React from 'react';
import { WppTableProps } from '../types';
import '../style.scss';

const WppTableHeaderRow: React.FC<WppTableProps> = ({ children, style, className }: WppTableProps): React.ReactElement => (
  <tr className={className ?? ''} style={style}>
    {children}
  </tr>
);

export default WppTableHeaderRow;
