import _ from 'lodash';
import React from 'react';
import { Icon } from 'buildingBlocks';
import { ABColors } from '../ABReportViz';
import { getDiffConfig, Greater } from '../utils';
import { NODATA_COLOR } from '../constants';

type Props = {
  perfA: number
  perfB: number
  lowerIsBetter: boolean
  colors: ABColors
};

const getColor = (greater: Greater, colors: ABColors) => {
  switch (greater) {
    case 'a':
      return colors.a;
    case 'b':
      return colors.b;
    default:
      return NODATA_COLOR;
  }
};

export default ({ perfA, perfB, lowerIsBetter, colors }: Props) => {
  const { greater, text: diffText } = getDiffConfig(perfA, perfB, lowerIsBetter, 'long');
  return (
    <p className="comparison" style={{ color: getColor(greater, colors) }}>
      {!_.isNull(greater) && (greater !== 'equal') && <Icon name="check circle" />}
      {diffText}
    </p>
  );
};
